<div class="or-work-container">

    <!-- TITLE, GO BACK, STATUS-->
    <div class="go-back">
        <!-- GO BACK-->
        <app-go-back [text]="'Carga de taller'" [v]="v.cargaTaller"></app-go-back>
        <!-- TITLE -->
        <app-view-title class="tac">
            <p>TRABAJAR EN OR</p>
        </app-view-title>
        <!-- OR STATUS-->
        <p class="tac fw500" *ngIf="or">
            <span [matTooltip]="or.canEdit ? 'Puedes modificar la OR' : 'No puedes modificar esta '">
                <mat-icon class="vam material-icons">
                    {{or.canEdit ? 'lock_open' :'lock'}}</mat-icon>
                <span>{{or.getStatusName()}}</span>
            </span>
        </p>
    </div>

    <!-- FINALIZAR OR, TRABAJAR, AÑADIR TIEMO Y HISTORIAL-->
    <div class="actions">

        <!-- SKELETON TEMPLATE FOR BIG BUTTONS (FINISH OR AND WORK)-->
        <ng-template #sekeleton>
            <app-skeleton [fullW]="true" [height]="80"></app-skeleton>
            <app-skeleton [fullW]="true" [height]="80"></app-skeleton>
        </ng-template>

        <div *ngIf="or; else sekeleton">
            <div>
                <div class="close-or custom-button" [ngClass]="{'disabled ' : !or.canEdit}" (click)="closeOR()">
                    <mat-icon>check_circle</mat-icon>
                    <p>{{or.canEdit ? 'FINALIZAR OR' : 'OR FINALIZADA'}}</p>
                </div>
                <div class="df fww jcsa">
                    <p class="c_r fw500 tac fss nmb mr5"
                        [ngClass]="{'vh': !unsavedChanges, 'dn' : !unsavedChanges && isOperatorWorking()}"> * Tienes
                        cambios
                        sin guardar</p>
                    <p class="c_r fw500 tac fss nmb" [ngClass]="{'vh': !isOperatorWorking()}"> * Estás trabajando en la
                        OR
                    </p>
                </div>
            </div>

            <div (click)="startStopTime()" class="work-or custom-button"
                [ngClass]="{'disabled ' : !or.canEdit || !userIsWorker}" [matTooltipPosition]="'right'"
                [matTooltip]="!userIsWorker ? 'Solamente los operarios de Siniwin pueden trabajar en la OR' : ''">
                <ng-container *ngIf="isOperatorWorking() as time; else work">
                    <mat-icon>stop_circle</mat-icon>
                    <p>{{time.elapsed}}</p>
                </ng-container>
                <ng-template #work>
                    <mat-icon>play_circle</mat-icon>
                    <p>TRABAJAR</p>
                </ng-template>
            </div>
            <div class="manual-actions" [ngClass]="{'disabled ' : !or.canEdit}">
                <button mat-button [disabled]="!or.canEdit"
                    (click)="addTime()"><mat-icon>add_circle</mat-icon>AÑADIR</button>
                <button mat-button [disabled]="!or.canEdit"
                    (click)="timeHistory()"><mat-icon>history</mat-icon>HISTORIAL</button>
            </div>
        </div>

    </div>


    <!-- INFORMACIÓN GENERAL -->
    <app-card class="info" [contentLoaded]="or != undefined">
        <div *ngIf="or">
            <app-card-title>
                <div class="df fww aic">
                    <p class="c_p nmb">INFORMACIÓN GENERAL</p>
                    <app-or-type-label [shortM]="responsiveS.w < 990" class="ml5 fw1" [showText]="false" [tipo]="or.type"
                        *ngIf="or.type.length"></app-or-type-label>
                </div>
            </app-card-title>

            <div class="info-section">
                <p class="nmb tac half"><mat-icon class="vam mr5">event</mat-icon> {{or.schedule?.temporalFormat}}</p>
                <p *ngIf="!or.type.length" class="fw1 nmb">Tipo : Sin tipo</p>
                <div class="df jcc half">
                    <app-circular-letter [small]="true" *ngIf="or.assigned; else noWorker"
                        [user]="or.assigned"></app-circular-letter>
                    <ng-template #noWorker>
                        <p class="nmb"><mat-icon class="vam mr5">person</mat-icon> Sin operario</p>
                    </ng-template>
                </div>
            </div>

            <div class="info-section">
                <app-card class="card-info fw1">
                    <p>{{or.clientName()}}</p>
                    <p>{{or.nif}}</p>
                </app-card>

                <app-card class="card-info fw1">
                    <p>{{or.license}}</p>
                    <p> {{or.modelo}}</p>
                </app-card>
            </div>
        </div>

    </app-card>


    <!-- TAREAS -->
    <app-card class="groups" [contentLoaded]="or != undefined">
        <app-card-title [noMarginBottom]="true">
            <p class="c_p">TAREAS A REALIZAR</p>
        </app-card-title>
        <app-or-line *ngFor="let g of or?.groups" [g]="g" [canEditOr]="!or? false : or.canEdit" class="mat-elevation-z3"
            (ondelete)="onDeleteLine($event)"></app-or-line>
        <div class="w100 df jcc aic mt10" *ngIf="canAddLine">
            <button mat-button (click)="openLineTypeDialog()" class="c_p">
                <mat-icon class="c_p">add_circle</mat-icon> AÑADIR LÍNEA
            </button>
        </div>
    </app-card>


</div>