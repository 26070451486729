import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppointmentStatus, Comentario, CommentManager, ConfirmDialogService, CustomColor, CustomDate, ErrorDialogService, FormService } from '@sinigual/angular-lib';
import { HourPickerComponent } from 'src/app/components/hour-picker/hour-picker.component';
import { apiService } from 'src/app/core/api/api-service';
import { M_Cita, M_CitaInterface } from 'src/app/core/models/M_Cita';
import { M_Company } from 'src/app/core/models/M_Company';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { CitaResultClienteDialogComponent } from '../../cita-result/cita-result-cliente-dialog/cita-result-cliente-dialog.component';
import { CommentPedirCitaComponent } from '../Commentarios/comment-pedir-cita/comment-pedir-cita.component';

/** 
 * [Component] 
 * Classe para que los clientes finales puedan pedir citas
 * Para todas las empresas se utiliza la misma pantalla.
 * Para poder distinguir entre las empresas, la url espera el parametro 'comanyId'
 * Éste parámetro debe passar-se encriptado.
 * Para más infromación sobre la desencriptacion, ver la función 'getNumberOf()'.
 */
@Component({
  selector: 'app-edit-cita-content',
  templateUrl: './edit-cita-content.component.html',
  styleUrls: ['./edit-cita-content.component.css', './../../cita-client.css'],

})
export class EditCitaContentComponent implements OnInit {
  cm = CommentManager;
  cita: M_CitaInterface | undefined;
  form: UntypedFormGroup;
  company: M_Company | undefined;
  companyId = 0;
  userIp: string = "";
  showError: boolean = false;
  loaded: boolean = false;
  succesEdit: boolean = false;
  @Input() hash: string = "";
  @ViewChild(HourPickerComponent) hourpciker: HourPickerComponent | undefined;
  @ViewChildren(CommentPedirCitaComponent) comantarios!: QueryList<CommentPedirCitaComponent>;

  constructor(private formBuilder: UntypedFormBuilder,
    private apiService: apiService, private readonly changeDetectorRef: ChangeDetectorRef,
    private errorDialog: ErrorDialogService, public router: Router, private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog, private formService: FormService, private confirmDialogS: ConfirmDialogService) {
    this.form = this.formBuilder.group({
      name: '',
      matricula: '',
      marca: '',
      modelo: '',
      telf: '',
      email: [''],
      fecha_cita: '', //
      comments: '', //
      hora: ['', [Validators.required]], //La hora y el dia, se juntan en una string al pasarla al servidor
      dia: ['', Validators.required],
    });
  }

  ngOnInit(): void { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /** Crea o guarda la cita */
  makeCall() {
    if (this.formService.isOk(this.form) && this.form.value.hora) {
      this.apiService.editarCitaCliente(this.getDataCitaEdit(), this.hash).then(_response => {
        this.succesEdit = true;
        this.confirmDialogS.show({
          title: "CITA EDITADA CON ÉXITO",
          body: "Revise la bandeja de entrada de su correo para más detalles",
          disableClose: false,
          showCancel: false
        })
      })
    }
    else {
      this.errorDialog.showError("Formulario incorrecto", "Asegúrese de rellenar todos los campos")
    }
  }

  /** Esta función se llama des del componente padre editar-cita-cliente.component.ts
  * Inicializa la página de 'pedir cita', pero como se le pasa el parámetro 'isEdit' a true,
  * se muestra la pantalla de creación de cita, pero solamente se pueden editar algunos campos.
  */
  initPage(cita: M_CitaInterface, company: M_Company) {
    this.cita = cita;
    this.company = company;
    this.defineCompanyColors();
    //Pasar cambios a la pantalla para poder acceder al 'hourpicker'
    this.changeDetector.detectChanges();
    if (this.cita.estado_cita == AppointmentStatus.pendiente) {
      this.setCitaValueToForm(cita);
      this.hourpciker!.setDay(this.cita!.fecha_cita, this.cita.company_id);
      this.hourpciker!.setHour(this.cita!.fecha_cita);
      this.hourpciker?.setUpHoliDays(this.cita.company_id);
      if (this.cita.tareas) {
        this.cm.setCommentsPedirCita(this.cita.tareas, this.comantarios);
      }
    }
    this.loaded = true;
  }

  /**Rellena la pantalla de editar citas con la información que llega por parámetro */
  setCitaValueToForm(cita: M_CitaInterface) {
    this.form.setValue({
      "name": cita?.nombre_cliente,
      "matricula": cita?.matricula,
      "marca": cita?.marca,
      "modelo": cita?.modelo,
      "telf": cita?.telf,
      "email": cita?.mail,
      "fecha_cita": "",
      "comments": "", //Se ponen mas adelante
      "hora": "", // Se establece más adelante
      "dia": "", // Se establece más adelante
    });

    this.form.controls['name'].disable();
    this.form.controls['matricula'].disable();
    this.form.controls['marca'].disable();
    this.form.controls['modelo'].disable();
    this.form.controls['telf'].disable();
    this.form.controls['email'].disable();
  }




  getDataCitaEdit(): M_Cita {
    let citaValues: M_CitaInterface = {
      matricula: this.cita!.matricula,
      marca: this.cita!.marca,
      modelo: this.cita!.modelo,
      nombre_cliente: this.cita!.nombre_cliente,
      telf: this.cita!.telf,
      mail: this.cita!.mail,
      tareas: this.checkRepeatedComments(this.comantarios, this.cita!.tareas),
      control_1: this.cita!.control_1,
      control_2: window.navigator.userAgent,
      control_3: '--',
      fecha_cita: new CustomDate(this.hourpciker!.getDate()),
      company_id: this.cita!.company_id,
      hash: this.hash,
      estado_cita: AppointmentStatus.pendiente,
      cliente: this.cita!.cliente
    }
    return new M_Cita(citaValues)
  }

  goCrearCita() {
    this.router.navigate([ViewPath.pedirCita.path], { queryParams: { companyId: this.company!.id!.toString() } });
  }

  openResultDialog(cita_: M_Cita) {
    this.dialog.open(CitaResultClienteDialogComponent, {
      data: cita_,
      disableClose: true
    });
  }

  getNewColor() {
    return new CustomColor();
  }

  defineCompanyColors() {
    if (this.company) {
      document.documentElement.style.setProperty('--cc1', this.company!.getColor1().value);
      document.documentElement.style.setProperty('--cc11', this.company!.getColor1().contrastValue);
      document.documentElement.style.setProperty('--cc2', this.company!.getColor2().value);
      document.documentElement.style.setProperty('--cc22', this.company!.getColor2().contrastValue);
    }
  }


  /** Función que evita sobreescribir/borrar los comentarios de la cita al ser editada */
  public checkRepeatedComments(comentarios: QueryList<CommentPedirCitaComponent>, currentComments: string | null) {

    /** Todos los comentarios que pueden haber en la pantalla */
    var allPageComments = this.cm.getPedirCitaSection();

    /** Los comentarios que ya tenia la cita */
    var citaComments: Comentario[] = currentComments ? this.cm.addCommentsFromDatabase(currentComments) : [];


    /** Array que contiene todos los comentarios de la cita que no sean de esta pantalla */
    let cleanComments: Comentario[] = [];

    /** Quitamos aquellos comentarios de la cita que sean de esta pantalla */
    for (let i = 0; i < citaComments.length; i++) {
      let isFormThisPage = false;
      for (let j = 0; j < allPageComments.length; j++) {
        var cc = citaComments[i].text.trim().toLocaleLowerCase();
        var apc = allPageComments[j].text.trim().toLocaleLowerCase();;
        if (cc == apc) {
          isFormThisPage = true;
        }
      }
      if (!isFormThisPage) {
        /** Si el comentario no es de esta pantalla, lo guardamos */
        cleanComments.push(citaComments[i]);
      }
      else {
        /** El comentario proviene de esta pantalla.
         * Lo quitamos ya que tienen que perdurar los comentarios que introduzca el usuario por pantalla*/
        console.log(citaComments[i].text + " is from this page, removing")
      }
    }

    /** Array que contiene los comentarios que ha editado el usuario */
    var pageComments: Comentario[] = [];
    for (let i = 0; i < comentarios!.length; i++) {
      if (comentarios.get(i)?.checked) {
        pageComments.push(new Comentario(comentarios.get(i)!.text, []))
      }
    }

    /** Juntamos los comentarios actuales de la pagina y los juntamos con los comentarios que ya tenia la cita */
    var finalComments: Comentario[] = [...pageComments, ...cleanComments];
    var str = this.cm.getAllCommentsFormatted(finalComments);
    return str;
  }
}
