import { Component, OnInit } from '@angular/core';
import { apiService } from 'src/app/core/api/api-service';
import { M_EstadoTaller, M_EstadoTallerParam } from 'src/app/core/models/M_EstadoTaller';


@Component({
  selector: 'app-estado-taller',
  templateUrl: './estado-taller.component.html',
  styleUrls: ['./estado-taller.component.css']
})
export class EstadoTallerComponent implements OnInit {

  datosTaller : M_EstadoTaller | undefined;
  daySelected: M_EstadoTallerParam = M_EstadoTallerParam.sieteDieas;
  constructor(private apiService : apiService ) {}

  ngOnInit(): void {
    this.makeCall();
  }

  haveData() : boolean{
    return this.datosTaller != undefined;
  }
  
  makeCall(): void {
    this.apiService.getEstadoTaller(this.daySelected).then(response=>  this.processResponse(response));
  }

  processResponse(response : M_EstadoTaller){
    this.datosTaller = response;
  }

}
