import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_Provider } from 'src/app/core/models/M_Provider';
import { ViewPath } from '../../modules/app-routing.module';
import { ProviderAssignDialogComponent } from './provider-assign-dialog/provider-assign-dialog.component';
@Component({
  selector: 'app-provider-selector',
  templateUrl: './provider-selector.component.html',
  styleUrls: ['./provider-selector.component.css']
})
export class ProviderSelectorComponent {
  @Input() canActivate = false;
  @Output() onGeneralInfo: EventEmitter<any> = new EventEmitter();
  allViews = ViewPath;
  providers: M_Provider[] = [];
  loaded = false;
  constructor(public routerS: RouterService, private apiS: apiService, private d: MatDialog) {
    this.loadServices();
  }

  goCompanyTab(go: boolean = true) {
    if (go) {
      this.onGeneralInfo.emit();
    }
  }

  loadServices() {
    this.loaded = false;
    this.apiS.getProviders().then(res => { this.providers = res; this.loaded = true });
  }

  openSubscribeDialog(p: M_Provider) {
    this.d.open(ProviderAssignDialogComponent, { data: p, autoFocus: false }).afterClosed().subscribe(res => {
      if (res == true) {
        this.loadServices();
      }
    })
  }
}
