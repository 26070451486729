import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row-two',
  templateUrl: './row-two.component.html',
  styleUrls: ['./row-two.component.css']
})
export class RowTwoComponent implements OnInit {
  @Input() firstWrap : number = 350;
  constructor() { }
  ngOnInit(): void {
    if (this.firstWrap){
      document.querySelector('style')!.textContent +=
      "@media only screen and (max-width:"+ this.firstWrap +"px) {.r2 mat-form-field{width: calc(100% - 10px) !important} .r2 > ._customInput{width: calc(100% - 10px) !important;} .r2 > ._customInput:nth-child(3) { width: calc(100% - 10px) !important} .r2 mat-form-field:nth-child(3) {width: calc(100% - 10px) !important}}"
    } 
  }
}
