import { Component, Input, OnInit } from '@angular/core';


export class PricingCardData{
  public title :string;
  public desc :string;
  public desc2 : string;
  public price :number;
  public pros :string[];
  public destacado : boolean;
  constructor(title : string, desc : string, desc2 : string, price : number, destacado? : boolean, ...pros : string[]){
    this.title = title;
    this.desc = desc;
    this.desc2 = desc2;
    this.price = price;
    this.destacado = destacado ? destacado : false;
    this.pros = pros;
  }
}

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.css']
})
export class PricingCardComponent implements OnInit {
  @Input() data! : PricingCardData;
  
  constructor() { }

  ngOnInit(): void {
  }

}
