import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-app-contents',
  
  template: `
  <div class="contents">
    <ng-content></ng-content>
  </div>
  <app-footer></app-footer>`,

  styleUrls: ['./app-contents.component.css']
})

export class AppContentsComponent {
  @HostBinding('class') classes = 'app-contents';
  constructor() { }
}
