import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CustomDate} from '@sinigual/angular-lib';
import { M_Rider } from 'src/app/core/models/M_Rider';

export interface DatosCliente {
  nombre: string | undefined;
  numero: string | undefined;
  direccion: string | undefined;
  ciudad : string | undefined;
  email: string | undefined;
  dni : string | undefined;
}


export interface DatosVehiculo {
  maricula: string | undefined;
  bastidor: string | undefined;
  marca:string | undefined;
  modelo:string | undefined;
  fechareparacion: CustomDate | undefined;
  kmultimareparacion: string | undefined;
}

@Component({
  selector: 'app-datos-cliente',
  templateUrl: './datos-cliente.component.html',
  styleUrls: ['./datos-cliente.component.css']
})

export class DatosClienteComponent implements OnInit {
  
  @HostBinding('class') classes = 'appDatosCliente';
  /**El padre le passa el client data
   * Si no hay client data, este componente no se renderiza
  */
  @Input() clientData! : M_Rider;
  datosCliente!: DatosCliente;
  dv!: DatosVehiculo;
  @Input() isSearchResult : boolean = false;
  
  constructor() {}

  ngOnInit(): void {

    let rider = this.clientData;
    let phone = rider.TelFijoPar ? rider.TelFijoPar.replace('.','') : 
    rider.TelMovilPar ? rider.TelMovilPar.replace('.','') : ''; 

    this.datosCliente = {nombre:              this.clientData.Nombre1,
                         numero :             phone, 
                         direccion :          this.clientData.Direccion1,
                         ciudad :             this.clientData.Poblacion, 
                         email:               this.clientData.hasMail(),
                         dni:                 this.clientData.Nif};

    this.dv = {           maricula:           this.clientData!.Matricula,
                          bastidor :          this.clientData.Bastidor, 
                          marca:              this.clientData.Marca, 
                          modelo :            this.clientData.MdlTaller, 
                          fechareparacion :   this.clientData.FeUltRepa, 
                          kmultimareparacion: this.clientData.KmsUltRepa}; 
  }
}