<mat-card class="commentsParent" (click)="toggle()">
    <div class="comments">
        <img style="width: fit-content;height: fit-content;" src="../../../../../assets/img/comentarios/{{image}}.png">
        <span>{{text}}</span>
        <div *ngIf="checked" class="checkBoxDiv" [ngStyle]="color.style" [style]="'border: 1px solid var(--gray)'">
            <mat-icon #checkBox *ngIf="checked" [style]="'color:' + color.contrastValue">check</mat-icon>
        </div>
        <div *ngIf="!checked" class="checkBoxDiv" [style]="'border: 1px solid var(--gray)'"></div>
        
    </div>
</mat-card>