<div *ngIf="showBurgerMultiple">
    <div class="dropDownParent">
        <button [ngClass]="getClass() + ' ' + (class_ ? class_ : '')" mat-menu-item (click)="changeShow()">
            <mat-icon>{{show? 'keyboard_arrow_down' : 'navigate_next'}}</mat-icon>
            {{data.name}}
        </button>
    </div>
    <div [ngClass]="show ? 'dropDownContents' : 'hidden dropDownContents'">
        <div *ngFor="let d of data.child">
            <app-menu-item [mc]="parent" [burgerMenu]="parent" [data]="d"></app-menu-item>
        </div>
    </div>
</div>