<div #menu class="menu" (mouseleave)="onMouseLeaveComponent()" [ngClass]="!opened && isDesktop ? 'menu-closed' : ''">
  <div (mouseenter)="onMouseEnterComponent()">

    <!--REFATOR THIS 'SHOWING NGIF....' PLEASE-->
    <app-menu-item #d [mc]="this" [data]="dashboard"></app-menu-item>
    <app-menu-item #c [mc]="this" [data]="clients"></app-menu-item>
    <app-menu-item #u [mc]="this" [data]="users"></app-menu-item>
    <div class="m10" *ngIf="(d.isShowing || c.isShowing || u.isShowing) && (cr.isShowing || hr.isShowing)"><mat-divider></mat-divider></div>

    <app-menu-item #cr [mc]="this" [data]="crearrecepcion"></app-menu-item>
    <app-menu-item #hr [mc]="this" [data]="historialrecepcion"></app-menu-item>
    <div class="m10" *ngIf="cr.isShowing || hr.isShowing"><mat-divider></mat-divider></div>

    <app-menu-item #lc [mc]="this" [data]="listacitas"></app-menu-item>
    <app-menu-item #ca [mc]="this" [data]="createAppointment"></app-menu-item>
    <app-menu-item #w [mc]="this" [data]="workload"></app-menu-item>
    <div class="m10" *ngIf="(ca.isShowing || w.isShowing || lc.isShowing) && la.isShowing"><mat-divider></mat-divider></div>

    <app-menu-item #la [mc]="this" [data]="lanzadera"></app-menu-item>
    <app-menu-item *ngIf="featrue.comingsoon" [mc]="this" [data]="comingsoon"></app-menu-item>
    <div class="m10" *ngIf="i.isShowing && (la.isShowing)"><mat-divider></mat-divider></div>

    <app-menu-item #i [mc]="this" [data]="issues"></app-menu-item>

  </div>
</div>