<mat-toolbar class="toolbar" color="primary">
  <button #btn (click)="onclick.emit();" onclick="this.blur()" mat-icon-button class="df aic cp br5"
    [ngClass]="{'onboarading-menu' : mc.hiddenToUser}">
    <mat-icon>{{mc.lockmenu && mc.isDesktop ? 'menu_open' : 'menu'}}</mat-icon>
  </button>
  <img (click)="routerS.goTo(vp.dashboard)" class="logoToolbar cp" src="./assets/img/sinigual.png">
  <span class="example-spacer"></span>
  <button mat-icon-button (click)="onboarding.open();" onclick="this.blur()" *ngIf="showOnboarding">
    <mat-icon>help_outlined</mat-icon>
  </button>
  <div *ngIf="showNotifications">
    <app-notifications [class_]="'mr-20'" [getAll]="apiS.getNotifications.bind(apiS)" [autoUpdate]="false"
      [readAll]="apiS.readAllNotifications.bind(apiS)" [setReaded]="apiS.setNotificationReaded.bind(apiS)"
      (onClickNotification)="mc.close(); notificationAction($event)">
    </app-notifications>
  </div>

  <app-circular-letter class="topProfileImage pointer round50" *ngIf="profileService.clientData; else loadingprofile"
    [user]="profileService.clientData" [matMenuTriggerFor]="menu" [showFullName]="false"
    [showTooltip]="false"></app-circular-letter>

  <ng-template #loadingprofile>
    <app-skeleton [circle]="true" [width]="40" [height]="40"></app-skeleton>
  </ng-template>

  <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="profileService.clientData">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <app-menu-item [mc]="mc" [matmenu]="true" [data]="perfil"></app-menu-item>
    <app-menu-item [mc]="mc" [matmenu]="true" [data]="configuracion"></app-menu-item>
    <app-menu-item [mc]="mc" [matmenu]="true" [data]="cerrarSession"></app-menu-item>
  </mat-menu>

</mat-toolbar>