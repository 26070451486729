import { apiService } from "../api/api-service";

export interface M_CargaTallerInterface{
    id : number
    company_id: string,
    day: string,
    hours_available: string,
    hours_busy: string,
    total: string,
    created_at: string,
    updated_at: string,
}

/** [Respuesta a una petición GET]
 *  @description Classe para parsear la respuesta a la llamada http de la carga de taller
 *  {@link apiService.getCargaTaller()}
 */
export class M_CargaTaller implements M_CargaTallerInterface{
    id: number;
    company_id: string;
    day: string;
    hours_available: string;
    hours_busy: string;
    total: string;
    created_at: string;
    updated_at: string;

    constructor(obj : any){
        this.id =               obj.id;
        this.company_id =       obj.company_id;
        this.day =              obj.day;
        this.hours_available =  obj.hours_available;
        this.hours_busy =       obj.hours_busy;
        this.total =            obj.total;
        this.created_at =       obj.created_at;
        this.updated_at =       obj.updated_at;
    }
}