import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PlateScanComponent } from 'src/app/components/camera-preview/plate-scan.component';
import { apiService } from 'src/app/core/api/api-service';
import { M_BuscadorMatricula } from 'src/app/core/models/M_BuscarMatricula';
import { M_Rider } from 'src/app/core/models/M_Rider';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { GoCreateReceptionService } from 'src/app/services/go-create-reception.service';
import { MatriculaResultsEmptyComponent } from './matricula-results-empty/matricula-results-empty.component';
import { MatriculaResultsComponent } from './matricula-results/matricula-results.component';

/**
 * [COMPONENT]
 * 
 * Componente que muestra un texto informativo indicando que no hay resultados de la búsqueda por matrícula del usuario.
 */

@Component({
  selector: 'app-buscdor-matricula',
  templateUrl: './buscdor-matricula.component.html',
  styleUrls: ['./buscdor-matricula.component.css']
})
export class BuscdorMatriculaComponent implements OnInit {
  
  /**Panel donde se añaden los resultados de la búsqueda */
  @ViewChild('resultPanel', {static : false, read : ViewContainerRef}) resultPanel: ViewContainerRef | undefined;
  @ViewChild(PlateScanComponent, {static : false, read : PlateScanComponent}) sacnPlate: PlateScanComponent | undefined;
  matriculabuscada = "";
  public form: UntypedFormGroup;
  searching : boolean = false;
  v = ViewPath;

  constructor(private apiService : apiService, private formBuilder: UntypedFormBuilder, private goReception : GoCreateReceptionService) {
    this.form = this.formBuilder.group({
      matricula : ['', [Validators.required, Validators.minLength(1), Validators.maxLength(9)]]
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(){
    this.sacnPlate?.subject.subscribe(result =>{
      this.form.patchValue({matricula : result});
      this.search(result);
    })
  }

  /**Busqueda por matrícula*/
  search(matricula : string){
      if(this.form.valid && !this.searching) {
        this.searching = true;
        this.resultPanel?.clear();
        this.matriculabuscada = matricula;
        this.makeCall();
    }
  }

  /**Llama a la petición http de 'búsqueda por matrícula' */
  makeCall(): void {
    this.apiService.showRaider(this.matriculabuscada, true)
      .then(response => this.processResponse(response ? [response]  : []));
  }

  /**
   * Procesa la respuesta de la búsqueda por matrícula
   * Los resultados se añaden al panel {@link resultPanel}.
   * * Si existen resultados, los componentes a añadir seran del tipo {@link MatriculaResultsComponent}
   * * Si NO existen resultados, los componentes a añadir seran del tipo {@link MatriculaResultsEmptyComponent}
   */
  processResponse(response: M_Rider[] | undefined){
    if (response != undefined && response.length != 0){
      if (response.length == 1){
        this.goReception.goWithRider(response[0]);
      }
      else{
        response.forEach((rider, index) => {
          if (rider.Matricula) { 
            //Se guarda el la respuesta para poder tener los datos en otras pantallas
            let component= this.resultPanel?.createComponent(MatriculaResultsComponent);
            component!.instance.rider = rider;
            component!.instance.odd = index % 2 !== 0
            
          }
        })
      }
    }
    else{
        let component= this.resultPanel?.createComponent(MatriculaResultsEmptyComponent);
        component!.instance.searchResult = new M_BuscadorMatricula(this.matriculabuscada);
    }
    this.searching = false;
  }
}
