<app-view-title-lord-icon [lordIcon]="li" [pageTitle]="'NUEVA RECEPCIÓN'" class="df jcc">
</app-view-title-lord-icon>
<app-card class="buscadorM mtb-20 ma mw500">
    <p class="tac normal subtitle">Para crear una nueva recepción, puede encontrar el cliente introduciendo su
        matrícula.</p>
    <ng-template #li>
        <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
        <lord-icon src="https://cdn.lordicon.com/asnlqutm.json" trigger="loop" delay="9999"
            style="width:60px;height:60px">
        </lord-icon>
    </ng-template>
    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="onboarading-menu-buscar">
            <mat-label>Matrícula</mat-label>
            <input autocomplete="off" formControlName="matricula" (keyup.enter)="search(searchBtn.value)" #searchBtn
                matInput placeholder="Introduce la matrícula a buscar">
            <button (click)="search(searchBtn.value)" mat-icon-button matPrefix>
                <mat-icon >search</mat-icon>
            </button>
            <mat-error *ngIf="form.get('matricula')?.hasError('minlength')">Mínimo 1 carácter</mat-error>
            <mat-error *ngIf="form.get('matricula')?.hasError('maxlength')">Máximo 9 caracteres</mat-error>
            <!--<button [disabled]="plateScan.streaming || !plateScan.canStream" (click)="plateScan.initVideo($event)" mat-icon-button matSuffix>
                <mat-icon>filter_center_focus</mat-icon>
            </button>-->
        </mat-form-field>
    </form>
</app-card>
<div class="mb20"></div>
<!--<app-plate-scan #plateScan></app-plate-scan>-->
<mat-divider></mat-divider>

<!-- Panel donde se añaden los resultados de la búsqueda-->
<div #resultPanel class="resultPanel">
</div>