<app-section-title [title]="'ESTADO DEL TALLER'"></app-section-title>
<mat-button-toggle-group class="mb-20" value="7" [(ngModel)]="daySelected" (change)="makeCall()">
  <mat-button-toggle value="7">7 DÍAS</mat-button-toggle>
  <mat-button-toggle value="30">30 DÍAS</mat-button-toggle>
  <mat-button-toggle value="~30">MES ACTUAL</mat-button-toggle>
</mat-button-toggle-group>
<div class="flex" >
    <app-estado-taller-card icon="folder"       text="OR abriertas"         [num]="haveData() ? datosTaller!.or_opening : 0"    class_="1"></app-estado-taller-card>
    <app-estado-taller-card icon="task"         text="OR cerradas"          [num]="haveData() ? datosTaller!.or_close : 0"      class_="2"></app-estado-taller-card>
    <app-estado-taller-card icon="receipt_long" text="OR facturadas"        [num]="haveData() ? datosTaller!.or_invoice : 0"    class_="3"></app-estado-taller-card>
    <app-estado-taller-card icon="folder_copy"  text="Total OR"             [num]="haveData() ? datosTaller!.total_mo : 0"      class_="4"></app-estado-taller-card>
    <app-estado-taller-card icon="timeline"     text="Media días recepción" [num]="haveData() ? datosTaller!.med_repair_or : 0" class_="5"></app-estado-taller-card>
    <app-estado-taller-card icon="schedule"     text="Horas imputadas"      [num]="haveData() ? datosTaller!.invoice_med : 0"   class_="6"></app-estado-taller-card>
</div>