<app-section-title [title]="'CARGA DEL TALLER'"></app-section-title>
<div id="chart">
  <apx-chart id="carga-taller-chart" #cargaTallerChart
    [series]="chartOptions.series!"
    [chart]="chartOptions!.chart!"
    [dataLabels]="chartOptions!.dataLabels!"
    [plotOptions]="chartOptions!.plotOptions!"
    [responsive]="chartOptions!.responsive!"
    [xaxis]="chartOptions!.xaxis!"
    [legend]="chartOptions!.legend!"
    [fill]="chartOptions!.fill!"
  ></apx-chart>
</div>    
