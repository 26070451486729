import { Component, Inject, OnInit } from '@angular/core';
import { ClientSearcherComponent } from 'src/app/views/crear-recepcion/client-searcher/client-searcher.component';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {
  selected: boolean = true;
  constructor(@Inject(ClientSearcherComponent) public parent : ClientSearcherComponent ,) { }
  ngOnInit(): void { }
}
