<div>
    <form [formGroup]="createOrForm" style="max-width: 1450px;" class="mla mra">
        <div class="crearRecepForm">
            <!--FILA 1-->
            <div class="addInfo">
                <app-card [contentLoaded]="loaded_">
                    <div>
                        <app-card-subtitle [first]="true">CLIENTE</app-card-subtitle>
                        <app-client-searcher [form]="createOrForm" [formCName]="'license_plate'"></app-client-searcher>
                        <div class="mb10"></div>
                    </div>
                    <app-card-subtitle>AÑADIR DATOS</app-card-subtitle>
                    <div class="addInfoFlex">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de OR</mat-label>
                            <mat-select matNativeControl required formControlName="or_type">
                                <mat-option selected value="N">Normal</mat-option>
                                <mat-option value="G">Garantía</mat-option>
                                <mat-option value="C">Cargo Interno</mat-option>
                                <mat-option value="S">Siniestro</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="addInfoFlex">
                        <!--NOMBRE RECEPCIONISTA-->
                        <mat-form-field class="" appearance="outline">
                            <mat-label>Nombre recepcionista</mat-label>
                            <input autocomplete="off" matInput placeholder="Nombre recepcionista"
                                formControlName="receptionist">
                        </mat-form-field>
                    </div>

                    <!--QUILOMETROS-->
                    <div class="addInfoFlex">
                        <!--<mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Kilómetros</mat-label>
                        <input type="number" matInput placeholder="Quilómetros" formControlName="actual_kms">
                    </mat-form-field>!-->
                        <app-simple-input [disableAutocomplete]="true" [dotSeparation]="true" label="Kilómetros"
                            [form]="createOrForm" [formCName]="'actual_kms'"></app-simple-input>
                        <mat-error style="margin-left: 10px"
                            *ngIf="createOrForm.get('actual_kms')?.hasError('maxlength')">Máximo 6
                            caracteres</mat-error>
                    </div>

                    <!--CLAVE-->
                    <div class="addInfoFlex">
                        <mat-form-field class="input-full-width" appearance="outline">
                            <mat-label>Número de llave</mat-label>
                            <input autocomplete="off" matInput placeholder="Clave" formControlName="bike_keys">
                        </mat-form-field>
                    </div>

                    <!--FECHA APERTURA-->
                    <div class="addInfoFlex">
                        <mat-form-field appearance="outline">
                            <mat-label>Fecha apertura</mat-label>
                            <input matInput placeholder="Fecha apertura" formControlName="datetime_or_opening"
                                [matDatepicker]="picker" (click)="picker.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>


                    <!--FECHA ENTREGA-->
                    <div class="addInfoFlex">
                        <mat-form-field appearance="outline">
                            <mat-label>Fecha entrega</mat-label>
                            <input autocomplete="off" matInput placeholder="Fecha entrega"
                                formControlName="datetime_or_finished" matInput [matDatepicker]="picker_2"
                                (click)="picker_2.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                            <mat-datepicker #picker_2></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <!--COMBUSTIBLE-->
                    <div class="df jcc aic fww mt15 mb15">
                        <label id="example-radio-group-label" class="semi-bold tac nmb">Combustible:</label>
                        <mat-radio-group aria-label="Combustible :" value="1" formControlName="fuel_tank_status">
                            <mat-radio-button value="1">Vacío</mat-radio-button>
                            <mat-radio-button value="2">1/4 </mat-radio-button>
                            <mat-radio-button value="3">2/4</mat-radio-button>
                            <mat-radio-button value="4">3/4</mat-radio-button>
                            <mat-radio-button value="5">Lleno</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <!--COMENTARIOS-->
                    <app-card-subtitle>TAREAS</app-card-subtitle>
                    <app-comentarios-moto #moto></app-comentarios-moto>
                    <app-comentarios [maxPerComment]="29" #comentariosComponent [label]="'Comentarios / tareas'"
                        [placeHolder]="'Nuevo comentario / tarea'" [form]="createOrForm"
                        [moto]="moto"></app-comentarios>

                    <!--CHECKBOX-->
                    <div class="addInfoFlex sendMailToRider">
                        <mat-checkbox #checkbox color="primary" formControlName="mail_to_rider"
                            (change)="onSendClient($event)">
                            Enviar email al cliente
                        </mat-checkbox>
                    </div>
                    <div class="addInfoFlex" *ngIf="cs.rider && !cs.rider.hasMail() && checkbox.checked">
                        <!--EMAIL EN CASO DE QUE EL RAIDER NO TENGA-->
                        <app-email-input [form]="createOrForm" [formCName]="'email'"
                            [placeHolder]="'Introduce el email'" [label]="'Email cliente'"></app-email-input>
                    </div>
                </app-card>
            </div>
            <!--FILA 2-->
            <div style="display: flex; flex-direction: column;">
                <!--SUBIR FOTO-->
                <app-card [contentLoaded]="loaded_" class="">
                    <app-card-subtitle [first]="true">IMÁGENES</app-card-subtitle>
                    <div class="df jcc">
                        <lib-file-upload #uploadPicture [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="8">
                        </lib-file-upload>
                    </div>
                </app-card>

                <!--FIRMA DIGITAL-->
                <app-card [contentLoaded]="loaded_">
                    <app-card-subtitle [first]="true">AÑADIR FIRMA *</app-card-subtitle>
                    <!-- Si no se ha firmado -->
                    <div *ngIf="!firmaBase64" class="uploadedPanel">
                        <div>
                            <div class="add-big cp" #divOtuline (click)="openSignatureDialog()" matRipple>
                                <button type="button" class="addSignatureIcon no-hover pen" mat-icon-button>Añadir firma
                                    <mat-icon class="iconBig">add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Si se ha firmado -->
                    <div *ngIf="firmaBase64" class="df jcc">
                        <div class="df fdc">
                            <div>
                                <img (click)="openSignatureDialog()" class="firmaOutput roundBorder"
                                    src="{{firmaBase64}}">
                            </div>
                            <button type="button" (click)="openSignatureDialog()" class="scb" mat-raised-button>
                                Editar firma
                                <mat-icon style="color: white;">edit</mat-icon>
                            </button>
                        </div>

                    </div>
                </app-card>
            </div>
        </div>
        <button class="scb mtb-20 saveButton" type="submit" (click)="makeCall()" mat-raised-button>CREAR
            <mat-icon style="color: white;">save</mat-icon>
        </button>
    </form>
</div>