import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { I_CanDeactivate } from '../interfaces/I_CanDeactivate';
import { ConfirmData, ConfirmDialogService } from '@sinigual/angular-lib';


@Injectable({
  providedIn: 'root'
})

export class DeactivateGuard implements CanDeactivate<I_CanDeactivate> {

  constructor(private confirmD: ConfirmDialogService, private router : Router) { }

  canDeactivate(component: I_CanDeactivate, _currentRoute: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    
    console.log("nextState -->",nextState)
    console.log("component.changes -->", component.changes)
    console.log("component.userClickedExit -->", component.userClickedExit)
    
    if (nextState && component.changes && !component.userClickedExit) {
      let dialogData : ConfirmData = {
        title : "Tienes cambios sin guardar",
        body : "¿Seguro que quieres salir?",
        showCancel : true,
        cancelText : "Salir sin guardar",
        confirmTxt : "Continuar en la página",
        type: "info"
      }
      this.confirmD.show(dialogData).afterClosed().subscribe( res => {
        if (res == false){
          component.userClickedExit = true;
          console.log(nextState.url)
          this.router.navigateByUrl(nextState.url);
        }
      });
      return false;
    }
    return true;
  }
}