import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-line',
  templateUrl: './card-line.component.html',
  styleUrls: ['./card-line.component.css']
})
export class CardLineComponent implements OnInit {

  /**Valores necesarios */
  @Input() title! : string;
  @Input() icon! : string;

  /**Valores opcionales */
  @Input() mainColor? : string;
  @Input() titleColor?: string;
  @Input() iconBig : boolean = true;
  @Input() letterSpacingTitle : boolean = true;
  @Input() isSearchResult : boolean = false;
  @Input() cardAspect : boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
