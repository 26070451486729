import { ChangeDetectorRef, Component, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { ImageToggleComponent } from '../image-toggle.component';

@Component({
  selector: 'app-img-toggle-item',
  templateUrl: './img-toggle-item.component.html',
  styleUrls: ['./img-toggle-item.component.css']
})
export class ImgToggleItemComponent implements OnInit {
  @HostBinding('class') classes = 'toggleitem';

  /**Required inputs */
  @Input() img!: string;
  @Input() text!: string;
  @Input() startSelected: boolean = false;
  @Input() locked: boolean = false;
  index = -1;
  selectedItem: boolean = false;
  dimensions: number | undefined = undefined;
  blackWitheFilter: boolean = false;

  constructor(@Inject(ImageToggleComponent) public parent: ImageToggleComponent, private chdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.selectedItem = this.startSelected;
    this.chdRef.detectChanges();
  }

  setBlackWhite(v: boolean) {
    this.blackWitheFilter=v;
    this.chdRef.detectChanges();
  }

  setDimensions(d: number) {
    this.dimensions = d;
  }

  setIndex(i: number) {
    this.index = i;
  }

  setSelected(value: boolean) {
    if (!this.locked) {
      this.selectedItem = value;
    }
  }

  get selected() {
    return this.selectedItem;
  }

}
