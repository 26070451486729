import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit} from '@angular/core';
import { RouterService } from '@sinigual/angular-lib';
import { M_Rider } from 'src/app/core/models/M_Rider';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { GoCreateReceptionService } from 'src/app/services/go-create-reception.service';

/**
 * [COMPONENT]
 * 
 * Componente que muestra los resultados de la búsqueda por matrícula
 * 
 * Una vez se ha buscado por una matrícula, se generan tantos componentes {@link MatriculaResultsComponent} commo resultados haya.
 */

@Component({
  selector: 'app-matricula-results',
  templateUrl: './matricula-results.component.html',
  styleUrls: ['./matricula-results.component.css'],
  /**Animación de 'Fade In' de los resultados */
  animations: [
    trigger('fade', [ 
      transition('void => *', [
        style({ opacity: 0 }), 
        animate(1000, style({opacity: 1}))
      ]) 
    ])
  ]
})
export class MatriculaResultsComponent implements OnInit {
  vp = ViewPath;
  @Input() rider! : M_Rider;
  @Input() odd! : boolean;
  constructor(public routerS : RouterService, public goReception : GoCreateReceptionService) {}
  ngOnInit(): void {}

}
