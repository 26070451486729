import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Cita } from 'src/app/core/models/M_Cita';

@Component({
  selector: 'app-mecancico-descartar-cita',
  templateUrl: './mecancico-descartar-cita.component.html',
  styleUrls: ['./mecancico-descartar-cita.component.css']
})
export class MecancicoDescartarCitaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MecancicoDescartarCitaComponent>, @Inject(MAT_DIALOG_DATA) public cita: M_Cita) {}
  ngOnInit(): void {}

}
