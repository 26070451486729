import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vo-section-title',
  templateUrl: './vo-section-title.component.html',
  styleUrls: ['./vo-section-title.component.css']
})
export class VoSectionTitleComponent implements OnInit {

  @Input() ok : boolean = false;
  @Input() text : string | undefined;
  @Input() removeSpace : boolean = true;
  @Input() title! : string;
  constructor() { }

  ngOnInit(): void {
  }

}
