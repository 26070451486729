import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-phone-menu',
  templateUrl: './phone-menu.component.html',
  styleUrls: ['./phone-menu.component.css']
})
export class PhoneMenuComponent implements OnInit {

  @ViewChild('phoneMenu' , { static: true }) menu!  : MatMenu;
  @Input() telefono? : string;
  constructor() { }
  ngOnInit(): void {
    this.telefono = this.telefono?.replace('.', '')
  }

  isMobile() {
    if (this.telefono) {
      let char = this.telefono?.charAt(0);
      return  char == '6' || char == '7'
    }
    
    return false;
  }

}
