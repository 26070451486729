import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_Rider } from 'src/app/core/models/M_Rider';
import { apiService } from 'src/app/core/api/api-service';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { ProfileService } from 'src/app/views/profile/profileService';
import { ComentariosComponent, CommentManager, ConfirmDialogService, CustomDate, EmailInputComponent, ErrorDialogService, FileUploadComponent, FirmaComponent, FormService, ParameterStateComponent, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { ClientSearcherComponent } from './client-searcher/client-searcher.component';


/**
 * Classe que se usa para gestionar un usuario (NO cliente) a partir de una cita
 */
export class preRider {
  matricula: string;
  nombre: string;
  telef: string;
  mail: string;
  marca: string;
  modelo: string;
  constructor(matricula: string, nombre: string, telef: string, mail: string, marca: string, modelo: string) {
    this.matricula = matricula;
    this.nombre = nombre;
    this.telef = telef;
    this.mail = mail;
    this.marca = marca;
    this.modelo = modelo;
  }
}

@Component({
  selector: 'app-crear-recepcion',
  templateUrl: './crear-recepcion.component.html',
  styleUrls: ['./crear-recepcion.component.css', './addTemplate.css']
})

export class CrearRecepcionComponent extends ParameterStateComponent {

  cm = CommentManager;

  /**matriculaParam */
  matriculaParam!: string;

  /**nombreParam */
  nombreParam?: string;

  /**Tamaño de pantalla para modificar la firma */
  resizeSignatureSize = 530;
  /**Firma en base 64 para poder mostrarla por pantalla */
  firmaBase64: string | undefined;
  /**Firma en formato 'File' para poder enviarla posteriormente al endpoint */
  firmaFile: File | undefined;
  /**Formulario de creación de OR. No contiene las imágenes ni la firma, ya que se añaden posteriormente*/
  public createOrForm: UntypedFormGroup;

  /**Esta varaible se usa en el caso de que venga por parametro una cita.
   * Una vez se inicializa el componente, se ponene los compontarios necesarios.
   */
  public comentariosparam: string = "";

  loaded_ = false;

  @ViewChild('uploadPicture') uploadPicture!: FileUploadComponent;
  @ViewChild(ComentariosComponent) comentariosComponent!: ComentariosComponent;
  @ViewChild('divOtuline') divOtuline!: ElementRef<HTMLDivElement>;
  @ViewChild(EmailInputComponent) email?: EmailInputComponent;
  @ViewChild(ClientSearcherComponent, { static: true }) cs!: ClientSearcherComponent;

  constructor(profileService: ProfileService, private formBuilder: UntypedFormBuilder, public dialog: MatDialog,
    routerS: RouterService, route: ActivatedRoute, private apiService: apiService, private formService: FormService,
    private changeRef: ChangeDetectorRef, private errorS: ErrorDialogService, private confirmDialogS: ConfirmDialogService) {
    super(routerS, route, ["plate", "appointment"])

    profileService.subject.subscribe(v => {
      if (this.createOrForm) {
        this.createOrForm.patchValue({ receptionist: v.name })
      }
    })

    //Generación del formulario
    this.createOrForm = this.formBuilder.group({
      or_type: 'N',
      receptionist: [profileService.clientData?.name, [Validators.required]],
      actual_kms: ['', [Validators.required, Validators.maxLength(7)]],
      bike_keys: [''],
      fuel_tank_status: ['1', [Validators.required]],
      datetime_or_opening: ['', [Validators.required]],
      datetime_or_finished: [],
      comments: '',
      mail_to_rider: false,
      email: '',
      license_plate: ['', Validators.required]
    });

    this.createOrForm.patchValue({ datetime_or_opening: new CustomDate().value })

  }

  ngAfterViewInit() {
    if (!this.hasParameters && !this.hasState) {
      this.loaded_ = true;
    }
    this.fillComments();
  }

  /** State : M_Rider (clients page) or M_Cita (create RA by Cita) */
  override onState(state: any): void {
    /** Venimos de la pantalla de clienes */
    if (state instanceof M_Rider) {
      this.cs.setValue(state);
      this.loaded_ = true;
    }
    // Venimos de la pantalla de gestión de citas
    else if (state instanceof M_Cita) {
      this.fillScreenWithCita(state);
    }
  }

  override onParam(param: string, value: string): void {
    if (param == "plate") {
      this.cs.setValue(value);
      this.loaded_ = true
    }
    else if (param == "appointment") {
      this.apiService.showCitaCliente(value).then(res => {
        if (res instanceof M_Cita) {
          this.fillScreenWithCita(res);
        }
      })
    }
  }


  fillComments() {
    if (this.comentariosparam) {
      this.comentariosComponent?.initCommentArray(this.cm.addCommentsFromDatabase(this.comentariosparam), true);
      this.changeRef.detectChanges();
    }
  }

  fillScreenWithCita(cita: M_Cita) {
    //Si ya és cliente, mostramos la pantalla con los datos del usuario
    this.apiService.showRaider(cita.matricula, false).then(response => {
      if (response != undefined) {
        this.comentariosparam = cita.tareas ? cita.tareas : "";
        this.fillComments()
        this.cs.setValue(response);
        this.loaded_ = true;
      }
    });
  }

  onSendClient(e: MatCheckboxChange) {
    if (this.email != undefined) {
      this.email.required = e.checked;
    }
  }

  /**Abre el diálogo de firma 
   * Una vez se cierra el diálogo, guardamos la firma en las variables {@link firmaBase64} y {@link firmaFile}.
  */
  openSignatureDialog() {
    let config = new MatDialogConfig();
    config = {
      width: "100%",
      maxWidth: this.getMaxWidth(),
      panelClass: 'full-screen-modal',
    };
    const dialogRef = this.dialog.open(FirmaComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result[0] != undefined) {
          this.firmaBase64 = result[0];
        }
        if (result[1] != undefined) {
          this.firmaFile = result[1];
          this.validateSignature();
        }
      }
    });
  }

  getMaxWidth(): string {
    //En el telefono 100%, en ordenador 80% pantalla
    return screen.width < this.resizeSignatureSize ? "100%" : "500px";
  }

  validateSignature() {
    if (this.divOtuline) {
      if (!this.firmaFile) {
        this.divOtuline.nativeElement.classList.add("mat-form-field-invalid")
      }
      else {
        this.divOtuline.nativeElement.classList.remove("mat-form-field-invalid")
      }
    }
  }

  makeCall() {
    console.log(this.comentariosComponent.getCommentsForSiniwin())
    this.validateSignature();
    var rider = this.cs?.rider;
    if (this.formService.isOk(this.createOrForm) && this.firmaFile && rider) {
      /**Passar el valor de FormGroup a Formdata */
      console.log(this.createOrForm.value)
      let formData = this.toFormData(this.createOrForm.value);
      formData = this.patchComments(formData);
      formData = this.appendImages(formData);
      formData = this.appendSignature(formData);
      formData.append("matricula_id", rider.id.toString())
      console.log(formData);
      this.apiService.createOr(formData).then(_response => this.processResponse());
    }
    else {
    }
  }

  processResponse(): void {
    this.confirmDialogS.show({
      title: "RECEPCIÓN CREADA CON ÉXITO",
      body:"",
      confirmTxt: "Hecho",
      showCancel : false,
      disableClose:true
    }
    ).afterClosed().subscribe(res => {
      this.routerS.goTo(ViewPath.recepcion)
    })
  }

  /**Formatea los comentarios para que la API los pueda tratar correctamente */
  patchComments(formData: FormData) {
    formData.set('comments', this.comentariosComponent.getCommentsForSiniwin());
    return formData;
  }

  /**Añadir las imagenes en el FormData */
  appendImages(formData: FormData) {
    let files: File[] = this.uploadPicture.getUploadedFiles()!;
    for (let i = 0; i < files.length; i++) {
      formData.append('images' + i, files[i]);
    }
    formData.append('imageLength', files.length.toString());
    return formData;
  }

  /*Añadir la firma en el FormData*/
  appendSignature(formData: FormData) {
    if (this.firmaFile) {
      formData.append('signature', this.firmaFile);
    }
    return formData;
  }

  /**Necesario para enviar array de imagenes, passa el FormGorup a FormData */
  toFormData(formValue: any) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      let value = (<any>formValue)[key];
      if (key === "datetime_or_opening" || key == "datetime_or_finished") {
        /**Formatea la fecha a dd-MM-yyyy hh:mm:ss*/
        if (value) {

          value = new CustomDate(value).dataBaseFormat
        }
      }
      if (key == "mail_to_rider") {
        /**En el caso de los checkBox, enviamos una 'S' o una 'N' dependiendo del valor de éste */
        value = value == true ? "S" : "N";
      }

      if (key == "actual_kms") {
        if (typeof value == "string" && value != undefined) {
          value = value.replace(/\./g, '');
        }
      }

      formData.append(key, value);
    }
    return formData;
  }
}
