import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmData, ConfirmDialogService, RoleService, sumTimes } from '@sinigual/angular-lib';
import { time } from 'console';
import { apiService } from 'src/app/core/api/api-service';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { M_OR } from 'src/app/core/models/M_OR';
import { M_User } from 'src/app/core/models/M_User';
import { ProfileService } from 'src/app/views/profile/profileService';

@Component({
  selector: 'app-history-time-or-dialog',
  templateUrl: './history-time-or-dialog.component.html',
  styleUrls: ['./history-time-or-dialog.component.css']
})
export class HistoryTimeOrDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<HistoryTimeOrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { or: M_OR, workers: M_User[] },
    public profileS: ProfileService, private apiS: apiService, public roleS: RoleService, private confirmD: ConfirmDialogService) { }

  ngOnInit(): void { }

  /** @return true if User Role is 'gestor' */
  get canDelete() {
    var r = this.roleS.getRole();
    return r == UserRoleEnum.gestor;
  }

  deleteTimeLine(id: number) {
    var v: ConfirmData = {
      title: "Eliminar registro",
      body: "¿Seguro que quieres eliminar este registro del historial?",
      showCancel: true,
      type: "danger"
    }

    this.confirmD.show(v).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.deleteTimeLine(id).then(res => {
          this.data.or.removeTime(id);
        })
      }
    })
  }

  getTotalTime() {
    return sumTimes(this.data.or.timers.map(timer => timer.elapsed), { prefix: "short" })
  }
}
