<video playsinline
loop
autoplay
[muted]="true" id="loginVideo">
  <source src="../../../assets/video/login_back.mp4" type="video/mp4">
</video>

<div id="panelLoginVideo"></div>

<div *ngIf="loaded; else loading">
    <mat-card class="login-card" style="height: 514px !important;">
        <img class="mtb-20 sinigual-logo" src="../../assets/img/logo.png">
    <p class="large semi-bold">Modificar contraseña</p>
    <p class="medium">Introduce una contraseña y repítela para poder moficarla</p>
    <form (ngSubmit)="makeCall()" [formGroup]="form">
        <div class="divFormColumn">
            <app-password-input #pass [form]="form" formCName="pass1"></app-password-input>
            <app-repeat-passowrd-input #pass2 class_="mt-20" [otherPass]="pass" [form]="form" formCName="pass2"></app-repeat-passowrd-input>
            <button style="width: 250px;" [disabled]="pass2.getDisabled()" mat-raised-button [ngClass]="pass2.getDisabled()? 'mt-20':'scb mt-20'">
                MODIFICAR CONTRASEÑA
            </button>
        </div>

    </form>
    </mat-card>
</div>
<ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>  
</ng-template>