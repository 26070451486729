import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomTime, ErrorDialogService, M_BaseUser, SnackService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_OR } from 'src/app/core/models/M_OR';
import { M_User } from 'src/app/core/models/M_User';
import { ProfileService } from 'src/app/views/profile/profileService';

@Component({
  selector: 'app-add-time-or-dialog',
  templateUrl: './add-time-or-dialog.component.html',
  styleUrls: ['./add-time-or-dialog.component.css']
})
export class AddTimeOrDialogComponent implements OnInit {
  selected: M_User | undefined;
  fc: FormControl = new FormControl("");
  constructor(
    public dialogRef: MatDialogRef<AddTimeOrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { or: M_OR, workers: M_User[] },
    public profileS: ProfileService, private apiS: apiService, private errorS : ErrorDialogService, private snackS: SnackService) {
  }
  ngOnInit(): void {
    if (this.data.workers.length == 0) {
      this.fc.disable();
    }
  }

  get isOperario() {
    return this.profileS.clientData?.operario_id != null;
  }

  get isAddTimeEnabled() {
    return this.fc.value && (this.isOperario || (!this.isOperario && this.selected != undefined))
  }

  closeWithTime() {
    var id: number | undefined;

    if (this.selected) {
      id = this.selected.operario_id;
    }
    else {
      id = this.profileS.clientData?.operario_id;
    }

    var ct = new CustomTime(this.fc.value);

    if (id) {
      this.apiS.addManualTime(this.data.or.id, id, ct.getHours(), ct.getMinutes()).then(res => {
        this.data.or.addTime(res);
        this.dialogRef.close();
        this.snackS.show("Tiempo añadido correctamente", undefined, 2)
      })
    }
    else{
      this.errorS.showError("No se encuentra el ID del operario", "Contacte con nosotros si el problema persiste")
    }
  }

}
