import { CustomDate } from "@sinigual/angular-lib";

export class M_ORTime {
    id: number;
    start_time!: CustomDate;
    op_id: number;
    or_id: number;
    user_id: number;
    siniwin: string | null;
    download: boolean;
    created_at!: CustomDate;
    updated_at!: CustomDate;
    custom : boolean = false;
    end_time?: CustomDate;
    total_time?: string | null;
    cod_op_id?: number;

    constructor(d: any) {
        this.id = d.id;
        if (d.start_time) {
            this.start_time = new CustomDate(d.start_time);
        }

        if (d.end_time){
            this.end_time = new CustomDate(d.end_time);
        }

        this.total_time = d.total_time;
        this.op_id = d.op_id;
        this.or_id = d.or_id;
        this.cod_op_id = d.cod_op_id;
        this.user_id = d.user_id;
        this.siniwin = d.siniwin;
        this.download = d.download;
        if (d.created_at) {
            this.created_at = new CustomDate(d.created_at);
        }
        if (d.updated_at) {
            this.updated_at = new CustomDate(d.updated_at);
        }
        this.custom = d.custom;
    }

    get elapsed() {
        var e = this.start_time.differenceWith(this.end_time, {prefix : "short"}); 
        console.log(e)
        return e;
    }
}