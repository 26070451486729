import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row-three',
  templateUrl: './row-three.component.html',
  styleUrls: ['./row-three.component.css']
})
export class RowThreeComponent implements OnInit {
  @Input() firstWrap : number = 800;
  @Input() secondWrap : number = 350;
  constructor() { }
  ngOnInit(): void {
    if (this.firstWrap){
      document.querySelector('style')!.textContent +=
      "@media only screen and (max-width:"+ this.firstWrap +"px) {.r3 mat-form-field{width: calc(50% - 10px) !important} .r3 > ._customInput{width: calc(50% - 10px) !important;} .r3 > ._customInput:nth-child(3) { width: calc(100% - 10px) !important} .r3 mat-form-field:nth-child(3) {width: calc(100% - 10px) !important}}"
    }

    if (this.secondWrap){
      document.querySelector('style')!.textContent +=
      "@media only screen and (max-width:"+ this.secondWrap +"px) {.r3 > ._customInput:nth-child(2) { width: calc(100% - 10px) !important} .r3 mat-form-field:nth-child(2) { width: calc(100% - 10px) !important} .r3 > ._customInput:nth-child(1) { width: calc(100% - 10px) !important} .r3 mat-form-field:nth-child(1) { width: calc(100% - 10px) !important}}"
    }
  }
}