
import { generateImageURL, M_BaseUser, M_Parser, match, parseOBJ, SearchFiltrable } from "@sinigual/angular-lib";
import { environment } from "src/environments/environment";
import { UserRole } from "../custom-classes/UserRole";
import { ActivationStatus } from "../enums/ActivationStatus";
import { UserRoleEnum } from "../enums/UserRoleEnum";

export class M_User extends M_BaseUser implements M_Parser, SearchFiltrable {
    client_code?: string;
    company_code?: string;
    role: UserRole;
    operario_id : number | undefined;
    estado_activacion: null | ActivationStatus;
    needsToBeParsed: boolean = true;
    user_platform: "cloud" | "siniwin" = "cloud";

    //name: string, email: string, role: UserRoleEnum, image: string, client_code?: string, company_code?: string, id?: number;
    constructor(d: any) {
        super(d);
        if (d.lient_code) {
            this.client_code = d.client_code;
        }
        if (d.company_code) {
            this.company_code = d.company_code;
        }

        this.operario_id = d.operario_id;

        var act = d.estado_activacion;
        this.estado_activacion = act == 0 ? ActivationStatus.toActivate : act == 1 ? ActivationStatus.sended : act == 2 ? ActivationStatus.activated : null;

        this.user_platform = this.estado_activacion == null ? "cloud" : "siniwin";

        this.role = d.role ? new UserRole(d.role) : new UserRole(UserRoleEnum.visitante);
        this.img = generateImageURL(environment, d.image ? d.image : d.pict ? d.pict : "");
    }
    defaultSearchFilter(text: string): boolean {
        return match(text, this.name, this.email, this.client_code, this.company_code)
    }

    parse(): any {
        let obj: any = parseOBJ(this);
        obj['role'] = this.role.role;
        return obj;
    }

    get cloud() {
        return this.user_platform == "cloud";
    }

    getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }


}