import { ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService, M_BaseUser, ParameterStateComponent, ResponsiveService, RoleService, RouterService, or_status_close } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { SiniwinOrStatus } from 'src/app/core/enums/SiniwinOrStatus';
import { M_OR } from 'src/app/core/models/M_OR';
import { ProfileService } from '../profile/profileService';
import { HistoryTimeOrDialogComponent } from 'src/app/components/history-time-or-dialog/history-time-or-dialog.component';
import { AddTimeOrDialogComponent } from 'src/app/components/add-time-or-dialog/add-time-or-dialog.component';
import { forkJoin } from 'rxjs';
import { M_ORTime } from 'src/app/core/models/M_ORTime';
import { M_Article } from 'src/app/core/models/M_Article';
import { LineTypeSelectorComponent } from 'src/app/components/line-type-selector/line-type-selector.component';
import { M_OR_Group } from 'src/app/core/models/M_OR_Group';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { I_CanDeactivate } from 'src/app/interfaces/I_CanDeactivate';
import { OrLineComponent } from 'src/app/components/or-line/or-line.component';

@Component({
  selector: 'app-imputar-tiempo-or',
  templateUrl: './imputar-tiempo-or.component.html',
  styleUrls: ['./imputar-tiempo-or.component.css']
})
export class ImputarTiempoOrComponent extends ParameterStateComponent implements I_CanDeactivate {

  or: M_OR | undefined;
  articles: M_Article[] = [];
  workers: M_BaseUser[] = [];
  v = ViewPath;

  @ViewChildren(OrLineComponent) orLinesComponents: QueryList<OrLineComponent> | undefined;

  /** I_CanDeactivate interface */
  userClickedExit = false;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: apiService, private d: MatDialog, public profileS: ProfileService, private confirmDialogS: ConfirmDialogService, private chDref: ChangeDetectorRef, private roleS: RoleService, public responsiveS: ResponsiveService) {
    super(routerS, route, ["or"]);
    this.autoRefeshTime();
  }

  /** I_CanDeactivate interface */
  get changes(): boolean {
    if (!this.or) { return false }
    return this.or?.groups.some(g => g.changes);
  }

  override onParam(param: string, value: string): void {
    const a = this.apiS.orByid(value.getNumber());
    const b = this.apiS.usersWorkers();
    const c = this.apiS.showArticulos();
    forkJoin([a, b, c]).subscribe(res => {
      this.or = res[0];
      this.workers = res[1];
      this.articles = res[2];
    })
  }

  updateValidityOfAllInputs() {
    this.orLinesComponents?.forEach(g => {
      if (g.g.isArticle) {
        g.quantity.updateValueAndValidity();
      }
    })
  }

  startTimeOperator() {
    const c = this.apiS.startTimeOperator(this.or!.id);
  }

  closeOR() {
    if (this.or && this.or.canEdit) {

      if (this.unsavedChanges) {
        this.confirmDialogS.show({ title: "CAMBIOS SIN GUARDAR", body: "Debes guardar los cambios antes de finalizar la OR", showCancel: false, type: "danger", confirmTxt: "OK" })
      }
      else if (this.isOperatorWorking()) {
        this.confirmDialogS.show({ title: "ESTÁS TRABAJANDO EN LA OR", body: "Detén la imputación de tiempo antes de finalizar la OR", showCancel: false, type: "danger", confirmTxt: "OK" })
      }
      else {
        this.confirmDialogS.show({ title: "¿Quieres cerrar la OR?", body: "Una vez cerrada la OR, no se podrá abrir de nuevo" })
          .afterClosed().subscribe(res => {
            if (res == true) {
              this.apiS.closeOr(this.or!.id).then(res => {
                this.or!.siniwin_status = SiniwinOrStatus.FINALIZADA_CLOUD;
                this.or!.status = or_status_close;
              })
            }
          })
      }
    }
  }

  get canAddLine() {
    var r = this.roleS.getRole();
    return this.or?.canEdit && (r == UserRoleEnum.gestor || r == UserRoleEnum.asesor)
  }

  /** @return is the user worker ? (Operario) */
  get userIsWorker() {
    return this.profileS.clientData?.operario_id != undefined;
  }

  autoRefeshTime() {
    setTimeout(() => {
      this.chDref.detectChanges();
      this.autoRefeshTime();
    }, 1000)

  }

  ngAfterContentChecked() {
    this.chDref.detectChanges();
  }

  startStopTime() {
    if (this.or && this.or.canEdit && this.userIsWorker) {
      var isworking = this.isOperatorWorking();
      if (isworking) {
        this.apiS.endTimeOperator(isworking.id).then(res => {
          this.or?.closeTime(isworking!.id)
        })
      }
      else {
        this.apiS.startTimeOperator(this.or!.id).then(res => {
          if (res instanceof M_ORTime) {
            this.or!.addTime(res);
          }
        });
      }
    }
  }

  isOperatorWorking() {
    return this.or!.isOperatorWorking(this.profileS.clientData?.operario_id);
  }

  timeHistory() {
    var w = (this.responsiveS.w < 448 ? "95vw" : undefined);
    this.d.open(HistoryTimeOrDialogComponent, { data: { or: this.or, workers: this.workers }, minWidth: w, autoFocus: false }).afterClosed().subscribe(res => { })
  }

  addTime() {
    this.d.open(AddTimeOrDialogComponent, { data: { or: this.or, workers: this.workers }, autoFocus: false }).afterClosed().subscribe(res => { })
  }

  openLineTypeDialog() {
    this.d.open(LineTypeSelectorComponent, { data: { articles: this.articles, or: this.or } }).afterClosed().subscribe(res => {
      if (res == "M") {
        this.or!.addLine().Grupo = "M";
      }
      if (res == "X") {
        this.or!.addLine().Grupo = "X";
      }
      if (res == "C") {
        this.or!.addLine().Grupo = "C";
      }
      if (res instanceof M_Article) {
        var g = this.or!.addLine();
        g.Grupo = "A";
        g.addArticle(res)
        console.log("Added", g)
        console.log("With", res)
      }
    })
  }

  onDeleteLine(g: M_OR_Group) {
    this.apiS.removeLine(g.detalle_id).then(res => {
      this.or!.deleteLine(g);
      this.updateValidityOfAllInputs();
    })
  }


  /** Get the total stock of one article
   * @param article_id The ID of the article
   * @returns The total stock
   */
  getStockOf(article_id: number | undefined): number {

    var art = this.articles.find(art => art.id == article_id);
    var groupsWithArt = this.or?.groups.filter(g => g.article_id == article_id);

    if (!art) { return 0; }

    var total = art.stock;
    groupsWithArt?.forEach(g => {
      total -= g.Cantidad;
    })

    return total;
  }

  /** Get the minimum stock of a product */
  getMinimumStock(article_id: number | undefined) {
    var art = this.articles.find(art => art.id == article_id);
    if (!art) { return 0; }
    return art.StockMinimo;
  }

  getStockControl(article_id: number | undefined) {
    var art = this.articles.find(art => art.id == article_id);
    if (!art) { return false }
    return art.ControlStock;
  }

  get unsavedChanges() {
    return this.or?.groups.some(g => g.changes == true)
  }

}
