<app-view-title class="df jcc mt20 m20">SELECCIÓN DE PROVEEDORES</app-view-title>

<app-card class="df row jcc wrap" [contentLoaded]="loaded">
    <div class="df fdr providers-parent">
        <div class="df column c1">
            <div>
                <p class="x-large">Distribución y venta de tus motos <span class="semi-bold">un clic</span></p>
            </div>
            <div>
                <p class="lighter">Tienes a tu disposición diferentes proveedores. Puedes elegir el que más se adapte a
                    las
                    necesidades de tu compañía</p>
            </div>
            <div class="df jcc row">
                <img src="./assets/img/providers/providers.png">
            </div>
        </div>
        <div class="df column c2">
            <p *ngIf="!canActivate">Rellene la sección de <span class="fw500 c_p cp" (click)="goCompanyTab()">infromación general</span> para poder activar los proveedores</p>

            <app-card [noStyle]="canActivate" [locked]="!canActivate"  (click)="goCompanyTab(!canActivate)" [lockedText]="'Rellene la sección de infromación general para poder activar los proveedores'">
                <app-card-subtitle [first]="true">Proveedores</app-card-subtitle>
                <div *ngFor="let provider of providers" class="df column">
                    <div class="df row prov wrap jcc">
                        <div>
                            <img [src]="provider.image" width="100" height="100" class="fit">
                        </div>
                        <div class="desc">
                            <p class="semi-bold nmb">{{provider.name}}</p>
                            <p class="lighter smaller lowGray">{{provider.description}}</p>
                        </div>
                        <div class="df column jcc mb-20" [matTooltip]="canActivate ? '' : 'Rellene la sección de infromación general para poder activar los proveedores'">
                            <button (click)="openSubscribeDialog(provider)"
                                [disabled]="!canActivate"
                                [ngClass]="provider.onCompany ? 'c_w bc_r' : 'scb'" mat-raised-button>
                                {{provider.onCompany ? 'DESHABILITAR' : 'OBTENER'}}
                            </button>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </app-card>

        </div>
    </div>

</app-card>