/**
 * ENTORNO DE PRE-PRODUCCIÓN
 * 
 * Para arrancar en local :
 * ng serve --configuration=pre
 * 
 * Para generar versión en pre-producción
 * ng build --configuration=pre
 */
export const environment = {
    local : false,
    production: false,
    preproduction : true,
    dev : false,
    apiUrl : "https://preprodapi.sinigual.com",
    analyticsId : ""
  };
  