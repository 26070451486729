<div class="search-results-p" @fade>
    <div class="search-results df fdc jcc aic" #searchresults>
        <div>
            <p>Sin resultados para
                <span *ngIf="searchResult.isMatricula()">la matrícula</span>
                <span *ngIf="searchResult.isPhone()">la matrícula</span>
                <span *ngIf="searchResult.isEmail()">la matrícula</span>
                <span class="semi-bold"> '{{searchResult.searched}}'</span></p>
            <!--<p (click)="newRecepcion()" class="textButton">
                Crear un nuevo cliente para  
                <span *ngIf="searchResult.isMatricula()">ésta matrícula</span>
                <span *ngIf="searchResult.isPhone()">ésta matrícula</span>
                <span *ngIf="searchResult.isEmail()">ésta matrícula</span>
            </p> -->
        </div>
        <p class="nmb df aic"> <mat-icon class="c_b mr5">info</mat-icon> Para poder hacer una recepción debes dar de alta el cliente en Siniwin</p>
    </div>
</div>
<mat-divider></mat-divider>