import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from 'src/app/views/profile/profileService';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/app/core/custom-classes/UserRole';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { BurgerOption, M_TicketNotificacion, NotificationInterface, NotificationType, OnboardingComponent, RoleService, RouterService, SessionService } from '@sinigual/angular-lib';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { apiService } from 'src/app/core/api/api-service';
import { M_CitaNotificacion } from 'src/app/core/models/M_Notificacion';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  vp = ViewPath;
  env = environment;
  screenWidth: number = window.innerWidth;
  /**Usuarios */
  perfil: BurgerOption;
  configuracion: BurgerOption;
  cerrarSession: BurgerOption;
  role: UserRoleEnum;
  showNotifications: boolean;
  showOnboarding: boolean = true;
  @Input() onboarding!: OnboardingComponent;
  @Input() mc!: MenuComponent;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.screenWidth = window.innerWidth;
  }
  constructor(public routerS: RouterService, private sessionServie: SessionService, public profileService: ProfileService, r: RoleService, public apiS: apiService) {
    this.perfil = new BurgerOption("Ver mi perfil", "person", ViewPath.profile)
    this.configuracion = new BurgerOption("Configuración", "settings", ViewPath.configuration)
    this.cerrarSession = new BurgerOption("Cerrar sesión", "logout", ViewPath.login, undefined, () => this.logOut())
    this.role = r.getRole();
    this.showNotifications = this.role == UserRoleEnum.asesor || this.role == UserRoleEnum.gestor || this.role == UserRoleEnum.programming
    this.showOnboarding = this.role != UserRoleEnum.programming;
  }

  ngOnInit() { }
  logOut() { this.sessionServie.logOut(); }
  getRole(): string {
    return new UserRole(this.role).name;
  }

  notificationAction(not: NotificationInterface) {
    switch (not.type) {
      case (NotificationType.CITAMODIFICADA):
      case (NotificationType.CITACREADA):
        if (not instanceof M_CitaNotificacion) {
          this.routerS.goWithQueryParams(ViewPath.cargaTaller, { id: not.cita?.id, date: not.cita!.fecha_cita, section : "appointment" })
        }
        break;
      case (NotificationType.TICKETCOMMENT):
      case (NotificationType.TICKETSTATUS):
        if (not instanceof M_TicketNotificacion) {
          this.routerS.goWithQueryParams(ViewPath.incidencias, { ct_id: not.ct.id, ct_proj: not.ct.project });
        }
        break;
    }
  }
}
