<app-page-structure 
  pageTitle="HISTORIAL RECEPCIONES" 
  [autocompleteID]="'recepcion'" 
  searchLabel="Buscar recepción"
  searchOnboardingClass="onboarding-search-client"
  [noDataCreateNew]="'Crear una nueva recepción'"
  [circleIcon]="'or_add'"
  [data]="[]"
  [displayedHeader]=" ['Matrícula',     'Apertura',            'Cliente', 'Teléfono',       'Estado']"
  [displayedColumns]="['license_plate', 'datetime_or_opening', 'Nombre1', 'TelMovilPar',    'downloaded']"
  [specialText]="[undefined,            undefined,              undefined, undefined,       statusTxt]"
  [specialClass]="[undefined,           undefined,              undefined, undefined,       statusClass]" [card]="card"
  [lordIcon]="lordIcon"
  [addNewView]="v.crearRecepcion">
  <ng-template #lordIcon>
    <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
    <lord-icon src="https://cdn.lordicon.com/nwznpuvh.json" trigger="hover" style="width:50px;height:50px">
    </lord-icon>
  </ng-template>

  <ng-template #card>
    <app-card *ngIf="ps.showItem()" [cornerMatMenu]="deleteCorner" [contentLoaded]="true">
      <app-card-title>DETALLES</app-card-title>
      <p class="fw500">Matrícula :      <span nullable>{{ps.selected!.license_plate}}</span></p>
      <p class="fw500">Cliente :        <span nullable>{{ps.selected!.Nombre1}}</span></p>
      <p class="fw500">Teléfono :       <span nullable>{{ps.selected!.TelMovilPar}}</span></p>

      <p class="fw500">Reparaciones : 
        <span nullable *ngIf="!ps.selected!.comments.length"></span>
      </p>
      <div *ngIf="ps.selected!.comments.length">
        <p *ngFor="let r of ps.selected!.comments" class="df aic nmb">
          <mat-icon style="transform: scale(0.4);">circle</mat-icon>
          {{r}}
        </p>
      </div>

      
    </app-card>
  </ng-template>

  <ng-template #deleteCorner>
    <button mat-icon-button [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #deleteMenu="matMenu">
      <mat-option style="height: 64px;" (click)="openDeleteDialog(ps.selected!)">
        <p class="nmb">Eliminar</p>
      </mat-option>
    </mat-menu>
  </ng-template>

</app-page-structure>