import { apiService } from "../../core/api/api-service";

export interface M_ProfilePictureInterface{
    image : File;
}

/**
 * [POST]
 * Esta classe representa el body de la petición {@link apiService.changeProfilePicture()}
 */
export class M_ProfilePicture implements M_ProfilePictureInterface{
    image: File;
    constructor(image : File){
        this.image = image;
    }
}