import { Injectable } from '@angular/core';
import { WorkloadData, WorkloadInterface } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { apiService } from '../api/api-service';
import { endpoints } from '../api/Endpoints';
import { feature } from '../features-controller/FeaturesController';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  workloadData!: WorkloadData;

  constructor(private apiS: apiService) {

    var configuration : WorkloadInterface =
    {
      switch: feature.workloadOr,
      defaultView : feature.workloadOr ? "or" : "citas",
      general: {
        users: this.apiS.usersWorkers.bind(this.apiS),
        company: this.apiS.getCompanyInfo.bind(this.apiS),
      },
      or: {
        schedules: this.apiS.schedules.bind(this.apiS),
        updateSchedule: endpoints.updateSchedule,
        reorderAction: undefined,
        assignAction: endpoints.assignOR,
        views: {
          createOr: undefined,
          editOr: ViewPath.workOr,
        },
        draggable : {
          day : false,
          week : true,
          month : true,
        }
      },
      appointments: {
        appointments: this.apiS.appointments.bind(this.apiS),
        updateAppointment: endpoints.changeAppointmentDate,
        views: {
          createAppointment: ViewPath.crearCita,
          editAppointment: undefined,
        },
        draggable : {
          day : false,
          week : true,
          month : true,
        }
      },

    }

    this.workloadData = new WorkloadData(configuration);
  }
  get wd() {
      return this.workloadData;
    }
  }
