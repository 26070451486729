import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { M_BuscadorMatricula } from 'src/app/core/models/M_BuscarMatricula';

/**
 * [COMPONENT]
 * 
 * Componente que muestra un texto informativo indicando que no hay resultados de la búsqueda por matrícula del usuario.
 */

@Component({
  selector: 'app-matricula-results-empty',
  templateUrl: './matricula-results-empty.component.html',
  styleUrls: ['../matricula-results/matricula-results.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MatriculaResultsEmptyComponent implements OnInit {
  @Input() searchResult!: M_BuscadorMatricula;
  constructor() { }
  ngOnInit(): void { }
}
