import { CustomDate } from "@sinigual/angular-lib";
import { DateGranularity } from "../enums/DateGranulraity";

export interface DateByGranularity{
    splitDate  : CustomDate
    data :       CustomDate[]
}

export class CustomDateByGranularity{

    dates : CustomDate[]
    granularity : DateGranularity;

    result : DateByGranularity[] = [];

    constructor(dates : CustomDate[], granularity : DateGranularity) {
        this.dates = dates;
        this.granularity = granularity;
        this.result = this.splitBy(granularity);
    }

    private splitBy(dg: DateGranularity) : DateByGranularity[]{
        let allDays: DateByGranularity[] = [];
        for(let i =0; i < this.dates.length; i++){
            let index = this.containsDay(allDays, this.dates[i], dg);
            if (index == -1){
                allDays.push({splitDate:this.dates[i], data : [this.dates[i]]})
            }
            else{
                allDays[index].data.push(this.dates[i]);
            }
        }

        allDays.sort(function (a, b) {
            if (a.splitDate.value < b.splitDate.value) {return -1;}
            if (a.splitDate.value > b.splitDate.value) {return 1;}
            return 0;
        });
        
        return allDays;
    }

    private containsDay(day_recep : DateByGranularity[], day : CustomDate, dg : DateGranularity ) {
        switch(dg){
            case DateGranularity.DAY:
                for (let i=0; i < day_recep.length; i++){
                    if (day_recep[i].splitDate.isEquals(day)){
                        return i;
                    }
                }
                return -1;
            case DateGranularity.MONTH:
                for (let i=0; i < day_recep.length; i++){
                    if (day_recep[i].splitDate.isMonthEqual(day)){
                        return i;
                    }
                }
                return -1
            case DateGranularity.YEAR:
            default: return -1;
        }
    }

    getData() : DateByGranularity[]{
        return this.result;
    }
}