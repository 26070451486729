<div class="box">
    <div class="v-align">
        <div class="info1">
            <mat-icon class="iconBig mb-20 icolor{{class_}}">{{icon}}</mat-icon>
        </div>
        <div class="info2">
            <p class="medium semi-bold tcolor tcolor{{class_}}">{{text}}
                <span class="x-large white infoNum pcolor{{class_}}">
                    {{ num}}
                </span>
            </p>
        </div>
        <div class="separator scolor"></div>
        <div class="info3">
            <p class="x-large white pcolor{{class_}}">{{num}}</p>
        </div>
    </div>
</div>