<h1 mat-dialog-title>Modificar fecha</h1>
<div mat-dialog-content>
  <p>¿A qué fecha y hora quieres cambiar la cita?</p>
  <div style="display: flex; flex-wrap: wrap;">
    <p style="margin-right: 20px;"><span class="semi-bold">Nombre : </span> {{cita.nombre_cliente}}</p>
    <p><span class="semi-bold">Matrícula : </span> {{cita.matricula}}</p>
  </div>
  <form [formGroup]="form">
    <app-hour-picker #hourpciker [isEdit]="true" [form]="form"></app-hour-picker>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancelar</button>
  <button mat-button [mat-dialog-close]="hourpciker.getDate()">Modificar</button>
</div>