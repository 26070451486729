import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { apiService } from 'src/app/core/api/api-service';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { M_Company } from 'src/app/core/models/M_Company';
import { EditCitaContentComponent } from './contents/edit-cita-content.component';

/**
 * [Component]
 * Este componente, se utiliza con el fin de que los riders puedan editar sus citas.
 * Aprovecha el html del componente 'pedir-cita-cliente.component.html'
 * Para poder editar una cita, la url llegaría tal que así :  
 * http://localhost:4200/editarcita?hash=$2y$10$pFg.tTUnv.ettlN55aPOwuPrCc48KZX4Irt6ePqWWeJRiZAdx.8SOA
 * El paràmetro hash de la url, és un atributo de la cita en la base de datos sql. És un atributo que se genera
 * aleatoriamente y se usa para identificar la cita.
 */
@Component({
  selector: 'app-editar-cita-cliente',
  templateUrl: './editar-cita-cliente.component.html',
  styleUrls: ['./editar-cita-cliente.component.css']
})
export class EditarCitaClienteComponent implements OnInit {

  hash : string = "";
  hashParam = "hash";
  cita : M_Cita | undefined;

  /**El componente de pedir Cita. Se aprovecha su estructura. Sólamente se deshabilitan algunos campos que se deben editar */
  @ViewChild(EditCitaContentComponent) pedirCitaComponent! : EditCitaContentComponent;

  constructor(private route: ActivatedRoute, private apiService : apiService) {
    /**Se busca el hash de la url*/
    this.route.queryParams
    .subscribe(params => {
        this.hash = params[this.hashParam];
      }
    );  
  }

  ngOnInit(): void {}

  ngAfterViewInit(){
    this.makeCall();
  }

  /**Para obtener los datos de la cita, se consulta mediante el hash que le llega al usuario por correo */
  makeCall(): void {
    this.apiService.showCitaCliente(this.hash).then(response => this.processResponse(response))
  }

  processResponse(value: M_Cita | boolean): void {
    if (value instanceof M_Cita){
      this.cita = value;
      this.apiService.getCompanyInfoById(this.cita!.company_hash!).then(response => {
        if (response instanceof M_Company){
          this.pedirCitaComponent.initPage(this.cita!, response);
        }
        else{
          this.pedirCitaComponent.showError = true;
        }
      }); 
    }
    else{
      this.pedirCitaComponent.showError = true;
    }
  }
}
