import { CustomDate } from "@sinigual/angular-lib";
import { Cambio } from "../enums/Cambio";
import { Motor } from "../enums/Motor";
import { M_Add } from "./M_Add";

export class M_Stock {
    id: number;
    Matricula: string;
    Kms: number;
    DesMarca: string;
    DesModelo: string;
    DesColor: string;
    Cilindrada: number;
    FeMatriculacion: CustomDate | undefined;
    FeEntrada: CustomDate | undefined;
    Ubicacion: string;
    TiMotor: Motor;
    TiCambio: Cambio;
    Bastidor: string;
    MesesGtia: number;
    add: M_Add | undefined;
    ValorCompra: number | undefined;

    constructor(d: any) {
        this.id = d.id;
        /** Datos de siniwin */
        this.Matricula = d.Matricula;
        this.Kms = d.Kms;
        this.DesMarca = d.DesMarca;
        this.DesModelo = d.DesModelo;
        this.DesColor = d.DesColor;
        this.Cilindrada = d.Cilindrada;
        this.FeMatriculacion = d.FeMatriculacion ? new CustomDate(d.FeMatriculacion) : undefined;;
        this.FeEntrada = d.FeEntrada ? new CustomDate(d.FeEntrada) : undefined;
        this.Ubicacion = d.Ubicacion;
        this.MesesGtia = d.MesesGtia;
        this.Bastidor = d.Bastidor;
        this.ValorCompra = d.ValorCompra;
        this.TiCambio = d.TiCambio == "M" ? Cambio.manual : d.TiCambio == "A" ? Cambio.automatico : Cambio.sincambio; //'M' || 'A' or null 
        this.TiMotor = d.TiMotor == "E" ? Motor.electrico : Motor.gasolina; //G || D (diesel) || P(??) || E?;
        if (d.publication) {
            this.add = new M_Add(d.publication);
        }
    }

    get isPublished() {
        return this.add?.hasProviders;
    }

    get sold() {
        return this.add?.status == "V";
    }
}