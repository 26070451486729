<div (click)="parent.setSelected(this.index)"
     [ngClass]="selected ? 'up' : blackWitheFilter ? 'no-up bw' : 'no-up'" [style]="'cursor: '+(locked ? 'initial' : 'pointer')">
    <img *ngIf="dimensions == undefined" src="{{img}}" class="img-no-events w100">
    <img *ngIf="dimensions != undefined" src="{{img}}" class="img-no-events" [width]="dimensions" [height]="dimensions">
    <p [ngClass]="selected ? 'selected tac' : 'tac'">
        {{text}}
        <mat-icon class="c_g" *ngIf="locked">lock</mat-icon>
    </p>
    <div class="height4">
        <div [ngClass]="selected ? 'selected-underline' : 'no-selected-underline'"></div>
    </div>
</div>