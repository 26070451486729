<div>
    <mat-form-field appearance="outline" class="w100">
        <mat-label>Buscar cliente</mat-label>
        <div matPrefix *ngIf="!loaded">
            <mat-spinner style="margin-right: 5px;" [strokeWidth]="6" [diameter]="25"
                mode="indeterminate"></mat-spinner>
        </div>
        <input [placeholder]="'Buscar cliente'" [formControl]="fc" matInput
            [matAutocomplete]="auto" (focusout)="onFocusOut(auto.options)">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of riderOptions" (onSelectionChange)="setValue($event)" [value]="option">

                <div>
                    <p class="one-line" style="margin-bottom : 0px;">
                        <mat-icon style="margin-right: 10px;">two_wheeler</mat-icon>
                        {{option.Matricula}}
                        <span style="white-space: break-spaces;" class="c_t2 fss"> {{option.Nombre1}}</span>
                    </p>
                </div>
            </mat-option>
            <mat-option *ngIf="fc.value && fc.value.length >= searchOnChars && riderOptions.length == 0" [disabled]="true">
                {{loaded ? 'Sin coincidencias' : 'Cargado usuarios...'}}
            </mat-option>
        </mat-autocomplete>
        <div>
            
        </div>
        <button [matTooltip]="'Quitar selección'" mat-icon-button matSuffix *ngIf="rider">
            <mat-icon (click)="removeValue()">cancel</mat-icon>
        </button>
    </mat-form-field>
</div>

<app-search-result></app-search-result>

<div [formGroup]="form" class="dn" *ngIf="form">
    <mat-form-field appearance="outline">
        <input matInput formControlName="{{formCName}}">
    </mat-form-field>
</div>