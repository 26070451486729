<div *ngIf="bike; else noData">

    <div class="df fdc aifs mla mra wfcoa">
        <div class="df fdr min-padding">
            <lib-icon-text icon="arrow_back" class="cp" [iconColor]="'var(--b)'"  (click)="routerS.goTo(v.stock)">
                <span class="c_b cp">Volver a la gestión de anuncios</span>
            </lib-icon-text>
        </div>
        <app-view-title class="min-padding">Editar anuncio</app-view-title>
        <div class="df fdr jcsb wrap jccw500">
            <app-ficha-tecnica class="accordion-section min-padding"></app-ficha-tecnica>
            <app-publicar-anuncio class="accordion-section min-padding" (onSave)="createUpdateAdd()"></app-publicar-anuncio>
        </div>
    </div>
</div>

<!--Sin datos disponibles-->
<ng-template #noData>
    <mat-progress-bar *ngIf="!loaded" [mode]="'indeterminate'"></mat-progress-bar>
    <app-page-not-found *ngIf="error"></app-page-not-found>
</ng-template>