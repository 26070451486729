import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomDate } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { M_Company } from 'src/app/core/models/M_Company';

/** 5 minutes */
const REFRESH_APPOINTMENTS_MINUTES = 5;
/** 10 seconds */
const REFRESH_SECONDS = 10;

@Component({
  selector: 'app-lista-citas',
  templateUrl: './lista-citas.component.html',
  styleUrls: ['./lista-citas.component.css']
})
export class ListaCitasComponent {

  today_appointments: M_Cita[] = [];
  company?: M_Company;
  actual_hour = new CustomDate();
  loaded = false;
  hiddentoolbar = false;
  showActions = true;
  conutMouseMove = 0
  elem;

  @HostListener('mousemove', ['$event'])
  handleMousemove() {
    this.removeActionsDelay()
  }

  constructor(private apiS: apiService, @Inject(DOCUMENT) private document: any, private chdRef: ChangeDetectorRef, router: Router) {
    this.elem = document.documentElement;
    this.refreshCitas();
    this.refreshAfterMinutes(); /** Refresh appointments every 5 minutes */
    this.refreshHour();
    this.getCompany();
    this.removeActionsDelay();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        document.getElementsByTagName("mat-toolbar")[0].classList.remove("dn")
      }
    });
  }

  removeActionsDelay() {
    console.log("Moving")
    this.showActions = true;
    this.conutMouseMove += 1;
    setTimeout(() => {
      this.conutMouseMove -= 1;
      if (this.conutMouseMove == 0) {
        this.showActions = false;
      }
    }, 1000);
  }

  getCompany() {
    this.apiS.getCompanyInfo().then(res => this.company = res)
  }

  /** Refresh the current appointments */
  refreshCitas() {
    this.loaded = false;
    this.apiS.getCitaByDay(new CustomDate()).then(res => {
      this.today_appointments = this.removeUnecessaryAppointments(res);
      this.loaded = true;
    })
  }

  /** Refresh the appointments style after 10 seconds
   *  Refresh the current time */
  refreshHour() {
    setTimeout(() => {
      this.actual_hour = new CustomDate();
      this.refreshHour();
      this.chdRef.detectChanges();
      this.today_appointments = this.removeUnecessaryAppointments(this.today_appointments);
    }, 1000 * REFRESH_SECONDS);
  }

  /** Refresh the appointments (via endpoint) after 5 minutes */
  refreshAfterMinutes() {
    setTimeout(() => {
      this.refreshCitas();
      this.refreshAfterMinutes()
    }, 1000 * 60 * REFRESH_APPOINTMENTS_MINUTES);
  }

  hideToolbar() {
    document.getElementsByTagName("mat-toolbar")[0].classList.toggle("dn");
    this.hiddentoolbar = !this.hiddentoolbar;
  }

  toggleFullScreen() {
    this.isFullScreen ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  removeUnecessaryAppointments(c: M_Cita[]) {
    var remove = 0;
    var unecessary = 0;
    c.sort((a, b) => { return a.fecha_cita.value > b.fecha_cita.value ? 1 : -1 })
    c.forEach(c => {
      if (this.isAppontmentDone(c)) {
        unecessary++;
        if (unecessary > 2) {
          remove++;
        }
      }
    })

    for (let i = 0; i < remove; i++) {
      c.shift();
    }

    return c;
  }

  get isFullScreen() {
    return !window.screenTop && !window.screenY
  }

  get granularity() {
    return this.company ? this.company.granularity : 5; //5 minutes default
  }

  isAppontmentDone(c: M_Cita) {
    var maxTime = new CustomDate(new Date(c.fecha_cita.value.getTime() + this.granularity * 60000)).value;
    return (this.actual_hour.value > maxTime);

  }

  isAppointmentNow(c: M_Cita) {
    var citaTime = c.fecha_cita.value;
    var now = this.actual_hour.value;
    var maxTime = new CustomDate(new Date(c.fecha_cita.value.getTime() + this.granularity * 60000)).value;
    console.log("Is bigger than now : ", citaTime > now)
    console.log("Is smaller than max time:", citaTime < maxTime)
    console.log("Is now --> ", citaTime >= now && citaTime <= maxTime)
    return now >= citaTime && now <= maxTime;
  }

}

