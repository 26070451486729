<mat-card class="matCardLine dfr" [ngClass]="{'nostyle' : !cardAspect}">
    <div class="datosContainer ">
        <div [ngClass]=" letterSpacingTitle ? 'datosHeader large semi-bold' : 'titleNoSpacing large semi-bold'" [ngStyle]="mainColor ? {'color': titleColor?  titleColor : mainColor} : {'color': '#2c3342'}">
            <p>
                <mat-icon [ngClass]="iconBig? 'iconMedium' : ''">{{icon}}</mat-icon>
                {{title}}
            </p>
        </div>
        <div class="datosContent">
            <div style="display: flex;">
                <div [ngClass]="iconBig? 'separatorLineIconBig' : 'separatorLine'" [ngStyle]="mainColor ? {'background': mainColor} : {'background': '#2c3342'}"></div>
                <div class="cardLineContent">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</mat-card>