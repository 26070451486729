import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ErrorDialogService } from '@sinigual/angular-lib';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plate-scan',
  templateUrl: './plate-scan.component.html',
  styleUrls: ['./plate-scan.component.css']
})
export class PlateScanComponent implements OnInit {
  //worker: Tesseract.Worker = Tesseract.createWorker();
  env = environment;
  loaded : boolean = false;
  streaming = false;
  canStream = false;
  stream: MediaStream | undefined ;
  constraints = {
    audio: false,
    video: true,
  };

  @ViewChild("videoElement") video!: ElementRef;
  @ViewChild("scanContent") content! : ElementRef;

  /**Este sujeto emite la matrícula en caso de que se haya leído exitosamente */
  subject = new Subject<string>()
  
  constructor(private errorDialog : ErrorDialogService) {   
    this.initialize();
    navigator.mediaDevices.getUserMedia({video : true}).then((_res)=>{
      this.canStream = true;
    }).catch((_e)=>{
      this.canStream = false;
    })
  }

  ngOnInit(): void {}
  ngAfterViewInit() {}


  /**Inicializar el lector de imágenes */
  async initialize(): Promise<void> {
    //await this.worker.load();
    //await this.worker.loadLanguage("eng");
    //await this.worker.initialize("eng");
    this.loaded = true;
  }

  open(){
    this.content.nativeElement.classList.remove("hidden");
  }
  close (){
    this.content.nativeElement.classList.add("hidden");
    this.streaming = false;
    this.stream = undefined;
  }

  initVideo(event ? : any) {
    if (event){
      event.stopPropagation();
    }

    this.getMediaStream()
      .then((stream) => {
        this.open();
        this.stream = stream;
        this.streaming = true;
        this.read();
      })
      .catch((_err) => {
        this.open();
        this.streaming = false;
        this.errorDialog.showError("ERROR AL ABRIR LA CÁMARA", "No se ha encontrado ninguna cámara disponible")
      });
  }


  contrastImage(imageData : ImageData, contrast : number) {  // contrast as an integer percent  
    var data = imageData.data;  // original array modified, but canvas not updated
    contrast *= 2.55; // or *= 255 / 100; scale integer percent to full range
    var factor = (255 + contrast) / (255.01 - contrast);  //add .1 to avoid /0 error

    for(var i=0;i<data.length;i+=4)  //pixel values in 4-byte blocks (r,g,b,a)
    {
        data[i] = factor * (data[i] - 128) + 128;     //r value
        data[i+1] = factor * (data[i+1] - 128) + 128; //g value
        data[i+2] = factor * (data[i+2] - 128) + 128; //b value

    }
    return imageData;  //optional (e.g. for filter function chaining)
  }

  async read() {
    setTimeout( () => {
      if (this.streaming){
        this.read();
      }
      const canvas = document.createElement("canvas");
  
      let h =  this.video.nativeElement.videoHeight;
      let w = this.video.nativeElement.videoWidth;
      canvas.width = w;
      canvas.height = h * 0.66;
      //canvas!.getContext('2d')!.drawImage(this.video.nativeElement, 0 ,0, canvas.width, canvas.height,)
      canvas!.getContext('2d')!.drawImage(this.video.nativeElement, 0, 0, canvas.width, canvas.height);
      let contrastedImage : ImageData = this.contrastImage(canvas!.getContext("2d")?.getImageData(0,0 , canvas.width, canvas.height)!, 70)
      /**0,       //La coordenada X de la esquina superior izquierda del sub-rectangulo de la imagen origen a dibujar en el contexto de destino.
        h*0.25,  //La coordenada Y de la esquina superior izquierda del sub-rectangulo de la imagen origen a dibujar en el contexto de destino.
        
        w,       //El ancho del sub-rectangulo de la imagen origen a dibujar en el contexto de destino. Si no se especifica, se utiliza todo el rectangulo entero desde las coordenadas especificadas por sx y sy hasta la esquina inferior derecha de la imagen.
        h * 0.66, //La altura del sub-rectangulo de la imagen origen a dibujar en el contexto de destino.
        
        0,       //La coordenada X del canvas destino en la cual se coloca la esquina superior izquierda de la imagen origen.
        0,       //La coordenada Y del canvas destino en la cual se coloca la esquina superior izquierda de la imagen origen.
        
        w,       //El ancho para dibujar la imagen en el canvas destino.
        h);      // El alto para dibujar la imagen en el canvas destino. Esto permite escalar la imagen dibujada. Si no se especifica, el alto de  la imagen no se escala al dibujar.
      */
      canvas!.getContext('2d')!.putImageData(contrastedImage,0,0);
      const image = new Image()
      image.src = canvas.toDataURL();
      this.scan(image.src)
  
    }, 10);
  }

  private getMediaStream(): Promise<MediaStream> {
    const video_constraints = { video: { facingMode: "environment" } };
    const _video = this.video.nativeElement;
    return new Promise<MediaStream>((resolve, reject) => {
      // (get the stream)
      return navigator.mediaDevices.
        getUserMedia(video_constraints)
        .then(stream => {
          (<any>window).stream = stream; // make variable available to browser console
          _video.srcObject = stream;
          // _video.src = window.URL.createObjectURL(stream);
          _video.onloadedmetadata = function (_e: any) { };
          _video.play();
          return resolve(stream);
        })
        .catch(err => reject(err));
    });
  }

  async scan(_f : string) {
    if (this.loaded){
      //const data = await this.worker.recognize(f);
      //this.processText(data.data.text);
    }
    else{
      console.log("Not ready")
    }
  }

  processText(text : string){
    if(text != "") {
      text = text.replace(/[^a-zA-Z0-9]/g, '');
      if (text != ""){
        text = this.replaceLettersAtBeginning(text);
        text = this.resize(text);
        if (this.goodRead(text)) {
          this.close();
          this.subject.next(text);
        }
      }
    }
  }

  goodRead(text : string) : boolean{
    return text.length > 6 && text.length < 11 && this.containsnumbers(text);
  }

  containsnumbers(text : string) {
    let count = 0;
    for (let i  =0; i < text.length; i++){
      var num = parseFloat(text[i]);
      if(!isNaN(num)){
        count++;
      }
      else{
        count = 0;
      }
      if (count == 4){
        return true;
      }
    }
    return false;
  }

  resize(text : string){
    if (text.length <= 7){
      return text;
    }
    else {
      text = text.substring(7, text.length); //-> ELiminamos el final
      return text;
    }
  }

  replaceLettersAtBeginning(text  :string ) : string{
    if (text.length > 1){
      var numericRepr = parseFloat(text[0]);
      if (isNaN(numericRepr)) { //Si la primera letra no es un número
        text = text.substring(1);
        return this.replaceLettersAtBeginning(text)
      }
      else{
        return text;
      }
    }
    else{
      return text;
    }
  }
}