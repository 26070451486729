import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HourPickerComponent } from 'src/app/components/hour-picker/hour-picker.component';
import { apiService } from 'src/app/core/api/api-service';
import { M_Cita, M_CitaInterface } from 'src/app/core/models/M_Cita';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { AppointmentStatus, ComentariosComponent, ConfirmDialogService, CustomDate, ErrorDialogService, FormService, RouterService, SnackService } from '@sinigual/angular-lib';
import { M_Rider } from 'src/app/core/models/M_Rider';
import { MatTabGroup } from '@angular/material/tabs';
import { ClientSearcherComponent } from 'src/app/views/crear-recepcion/client-searcher/client-searcher.component';

@Component({
  selector: 'app-crear-cita',
  templateUrl: './crear-cita.component.html',
  styleUrls: ['./crear-cita.component.css']
})

export class CrearCitaComponent implements OnInit {


  /** Rider on init */
  rOnInit: M_Rider | undefined;

  /*Default date*/
  d?: CustomDate;

  /**Formulario de creación de CITA. por parte del usuario*/
  public form: UntypedFormGroup;
  public matriculaFound = false;
  @ViewChild(HourPickerComponent) hourpciker: HourPickerComponent | undefined;
  @ViewChild(ComentariosComponent) comantarios: ComentariosComponent | undefined;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild(ClientSearcherComponent, { static: true }) cs!: ClientSearcherComponent;


  constructor(private formBuilder: UntypedFormBuilder, private apiService: apiService,
    private snackService: SnackService, private routerS: RouterService,
    private formService: FormService, public dialog: MatDialog,
    private changeD: ChangeDetectorRef, private errorD: ErrorDialogService,
    private confirmDialogS: ConfirmDialogService) {
    this.form = this.formBuilder.group({
      matricula: '',
      name: ['', [Validators.required]],
      email: '',
      telefono: '',
      marca: '',
      modelo: '',
      dia: '',
      hora: '',
      comments: '',
    });


    if (this.routerS.hasState()) {
      let state = this.routerS.getState();
      if (state instanceof CustomDate) {
        //Nos guardamos la fecha que llega por parametro.
        //Luego, poenemos ésta fecha en el componente hijo 'hourpicker'
        this.d = state;
      }
      else if (state instanceof M_Rider) {
        this.rOnInit = state;
      }
    }
  }

  ngOnInit(): void { }

  /**
   * Si no ha llegado una fecha por parametro, se la ponemos al componente hijo 'hourpicker'
   * Lo hacemos en el afterViewInIT() ya que en el constructor aún no se ha actualizado la referencia
   */
  ngAfterViewInit() {
    if (this.d) {
      this.hourpciker?.setDay(this.d, undefined)
      this.changeD.detectChanges();
    }
    if (this.rOnInit != undefined) {
      this.cs.setValue(this.rOnInit);
    }
  }

  ngAfterViewChecked() {
    this.tabGroup.realignInkBar();
  }

  makeCall() {

    console.log("Makig call")
    let formValues = this.form.getRawValue(); //Para poder obtener los valores deshabilitados
    let cita = this.createCitaBy(this.cs.rider == undefined ? formValues : this.cs.rider);

    if (this.hourpciker?.form.get('hora')!.valid) {
      if (this.cs.rider == undefined) {
        if (this.formService.isOk(this.form)) {
          this.createCita(cita);
        }
        else {
          this.errorD.showError("FALTAN CAMPOS REQUERIDOS", "Parece ser que no has rellenado todos los campos obligatorios")
          this.tabGroup.selectedIndex = 1;
        }
      }
      else {
        this.createCita(cita);
      }
    }
    else {
      this.errorD.showError("¡OOPS!", "Parece ser que no has seleccionado una hora para la cita")
    }


  }

  createCita(c: M_Cita) {
    console.log(c.appointment_date)
    this.apiService.createCitaMecanico(c).then(response => {

      this.confirmDialogS.show(
        {
          title: "CITA CREADA CON ÉXITO!",
          body: "",
          confirmTxt: "Ver cita",
          showCancel: false,
          disableClose : true
        }
      ).afterClosed().subscribe(res => {
        this.routerS.goWithQueryParams(ViewPath.cargaTaller, { id: response, date: c.appointment_date.value, section : "appointment" }
        )
      })
    })
  }

  onFocusOutEvent(value: string) {
    value = value.trim();
    if (value) {
      this.apiService.showRaider(value, true).then(response => {
        if (response != undefined) {
          this.snackService.show("La matrícula introducida ya pertenece a un cliente de Siniwin")
          this.cs.setValue(response);
          this.tabGroup.selectedIndex = 0;
        }
      })
    }
  }

  createCitaBy(values: M_Rider | any) {
    let citaValues: M_CitaInterface = {

      matricula: values instanceof M_Rider ? values.Matricula : values.matricula,
      marca: values instanceof M_Rider ? values.Marca : values.marca,
      modelo: values instanceof M_Rider ? values.MdlTaller : values.modelo,
      nombre_cliente: values instanceof M_Rider ? values.Nombre1 : values.name,
      telf: values instanceof M_Rider ? values.hasPhone() : values.telefono,
      mail: values instanceof M_Rider ? values.hasMail() : values.email,

      tareas: this.comantarios!.getAllCommentsFormatted(),
      control_1: '',
      control_2: '',
      control_3: '--',
      fecha_cita: new CustomDate(this.hourpciker!.getDate()),
      company_id: undefined,
      hash: '',
      estado_cita: AppointmentStatus.pendiente,
      cliente: 0
    }

    return new M_Cita(citaValues);
  }
}