<div *ngIf="isShowing" #item [class]="'menuItem ' + dataCalssPhoneDesktop() + ' ' + (class_ ? class_ : '')" [ngClass]="{'selected' : bS.isSelected(data), 'lordTrigger' : lordIcon, 'closed' : !mc.opened}" (click)="bS.goToPage(data, mc)">
    <mat-icon [fontSet]="bS.isSelected(data) ? 'material-symbols-sharp' : 'material-symbols-outlined'" *ngIf="!lordIcon" >{{data.icon}}</mat-icon>
    <!--<mat-icon [fontSet]="bS.isSelected(data) ? 'material-icons' : 'material-icons-outlined'" *ngIf="!lordIcon" >{{data.icon}}</mat-icon>!-->
    <div *ngIf="lordIcon">
        <ng-content></ng-content>
    </div>
    <span *ngIf="mc.opened || matmenu" [ngClass]="{'df fdc' : data.view == v.comingsoon}">
        {{data.name}}
        <span class="coming-soon" *ngIf="data.view == v.comingsoon">PRÓXIMAMENTE</span>
    </span>
    <span class="span-icon" style="margin-right: 0px;">
        <mat-icon *ngIf="icon && mc.opened" [style]="'color : ' + icon.color + ';'" >{{icon.icon}}</mat-icon>
    </span>
    <div class="expansionPanel" [ngClass]="{'closed' : !mc.opened && !matmenu}"></div>
    <div *ngIf="badge" [ngClass]="mc.opened || matmenu ? 'badge end-badge' : 'badge-opened'"></div>
</div>
