<div>
    <app-view-title-lord-icon class="df jcc" [lordIcon]="li" [pageTitle]="'CREAR CITA'"></app-view-title-lord-icon>
    <ng-template #li>
        <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
        <lord-icon src="https://cdn.lordicon.com/mpfwwxyi.json" trigger="loop" delay="9999"
            colors="primary:#3a3347,secondary:#66a1ee,tertiary:#2fbd96,quaternary:#66a1ee,quinary:#e4e4e4,senary:#ebe6ef"
            style="width:60px;height:60px">
        </lord-icon>
    </ng-template>

    <app-card class="mw900 form">
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">

            <!-- PARENT DIV -->
            <div class="sections-container">
                <div class="section">
                    <app-card-title>CLIENTE</app-card-title>
                    <mat-tab-group mat-align-tabs="center">
                        <mat-tab label="Cliente de Siniwin" [labelClass]="'onboarding-create-appointment-siniwin'">
                            <app-card-subtitle>BUSCAR CLIENTE</app-card-subtitle>
                            <app-client-searcher></app-client-searcher>
                        </mat-tab>
                        <mat-tab label="Cliente de tránsito" [disabled]="cs.rider != undefined"
                            [labelClass]="'onboarding-create-appointment-transit'">
                            <app-card-subtitle> Datos obligatorios</app-card-subtitle>
                            <mat-form-field class="w100" appearance="outline">
                                <mat-label>Matrícula</mat-label>
                                <input #buscarMatricula (keyup.enter)="onFocusOutEvent(buscarMatricula.value)" matInput
                                    formControlName="matricula" required
                                    (focusout)="onFocusOutEvent(buscarMatricula.value)">
                                <span *ngIf="matriculaFound" class="inputOk">¡Matrícula encontrada!</span>
                            </mat-form-field>

                            <mat-form-field class="w100" appearance="outline">
                                <mat-label>Nombre de cliente</mat-label>
                                <input matInput placeholder="Introduce el nombre del cliente" formControlName="name"
                                    required>
                            </mat-form-field>

                            <app-email-input class="w100" [form]="form" formCName="email"></app-email-input>
                            <app-phone-input class="w100" [form]="form" formCName="telefono"></app-phone-input>

                            <app-card-subtitle>Datos opcionales</app-card-subtitle>
                            <mat-form-field class="w100" appearance="outline">
                                <mat-label>Marca</mat-label>
                                <input matInput placeholder="Introduce la marca" formControlName="marca">
                            </mat-form-field>

                            <mat-form-field class="w100" appearance="outline">
                                <mat-label>Modelo</mat-label>
                                <input matInput placeholder="Introduce el modelo" formControlName="modelo">
                            </mat-form-field>
                        </mat-tab>
                    </mat-tab-group>
                </div>


                <div class="section">
                    <app-card-title>FECHA Y HORA</app-card-title>
                    <div class="onboarding-create-appointment-hour">
                        <app-hour-picker [form]="form"></app-hour-picker>
                    </div>


                    <div class="mtb-20"></div>
                    <app-card-title>REPARACIONES</app-card-title>
                    <div class="onboarding-create-appointment-reparations">
                        <app-comentarios-moto #moto></app-comentarios-moto>
                        <app-comentarios [form]="form" [label]="'Comentarios / tareas'"
                            [placeHolder]="'Nuevo comentario / tarea'" [moto]="moto"></app-comentarios>
                    </div>
                    <div class="mtb-20"></div>
                    <div class="df jcc">
                        <button mat-raised-button type="submit"
                            class="scb mt10 mb-20 onboarding-create-appointment-finish" (click)="makeCall()"
                            style="width: 130px!important;margin: auto;">
                            CREAR CITA
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </app-card>
</div>