import { M_Cita } from "./M_Cita";
import { CustomDate, M_CloudTicket, M_TicketNotificacion, NotificationInterface, NotificationType } from "@sinigual/angular-lib";

export class NotificationCreator {
    createNotificationByResponse(d: any): NotificationInterface[] {
        var notifications: NotificationInterface[] = [];
        for (let i = 0; i < d.length; i++) {
            if (this.isCita(d[i])) {
                notifications.push(new M_CitaNotificacion(d[i]));
            }
            else {
                var ct = new M_CloudTicket(d[i]);
                ct.events.forEach(event => {
                    notifications.push(new M_TicketNotificacion(ct, event));
                });
            }
        }
        notifications.sort((a, b) => a.time.value > b.time.value ? -1 : 1);
        return notifications;
    }

    private isCita(d: any): boolean {
        return d.cita != undefined;
    }
}

export class M_CitaNotificacion implements NotificationInterface {
    /**Datos de la bbdd */
    id: number;
    user_id: number;
    type: number;
    readed: boolean;
    cita: M_Cita | undefined;
    time: CustomDate;
    time_ago: string;
    /**Datos creardos a partir de la notificataionType */
    title!: string;
    text!: string;
    icon!: string;
    constructor(data: any) {
        /**Datos de la bbdd */
        this.id = data.notificacion.id;
        this.user_id = data.notificacion.user_id;
        this.type = Number(data.notificacion.type);
        this.readed = data.notificacion.status == 0 ? false : true;
        this.time = new CustomDate(data.notificacion.created_at);
        this.time_ago = this.time.isToday ? this.time.hourFormat : this.time.shortFormat;

        /**Creación de datos a partir del tipo de notificación */
        this.generatrNotificationsByTipo(data);
    }

    generatrNotificationsByTipo(data: any) {
        switch (this.type) {
            case (NotificationType.CITAMODIFICADA): {
                this.cita = data.cita;
                this.icon = "calendar_today";
                this.title = this.cita?.estado_cita == 2 ? "Cita eliminada" : "Cita modificada";
                this.text = this.cita?.estado_cita == 2 ?
                    "Se ha eliminado la cita con la matrícula '" + this.cita?.matricula + "." + " Estaba programada para el " + this.cita?.fecha_cita :
                    "La cita con la matrícula '" + this.cita?.matricula + "' se ha modificado para el " + this.cita?.fecha_cita;
                break;
            }
            case (NotificationType.CITACREADA): {
                this.cita = data.cita;
                this.icon = "calendar_today";
                this.title = "Cita creada";
                this.text = "Se ha creado una cita con la matricula " + this.cita?.matricula + " para el dia " + this.cita?.fecha_cita;
            }
        }
    }
}