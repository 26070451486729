import { CustomDate, SearchFiltrable, match } from "@sinigual/angular-lib";

export class M_Article implements SearchFiltrable {
    id: number;
    company_id: number;
    Ref: string;
    Pvp: number;
    Stock: number;
    Denominacion1p: string;
    StockMinimo: number;
    StockMaximo: number;
    ControlStock : boolean;
    Coste: number;
    DtoVta : number;
    FeUltEntrada?: CustomDate;
    stock: number;

    constructor(d: any) {
        this.id = d.id;
        this.company_id = d.company_id;
        this.Ref = d.Ref;
        this.Pvp = this.getRealValue(d.Pvp);
        this.Stock = this.getRealValue(d.Stock);
        this.DtoVta = this.getRealValue(d.DtoVta);
        this.Denominacion1p = d.Denominacion1p;
        this.StockMinimo = this.getRealValue(d.StockMinimo);
        this.StockMaximo = this.getRealValue(d.StockMaximo);
        this.ControlStock = d.ControlStock == "S" ? true : d.ControlStock == "N" ? false : true;
        try { this.FeUltEntrada = d.FeUltEntrada ? new CustomDate(d.FeUltEntrada) : undefined; }
        catch (e: any) { }
        this.Coste = d.Coste;
        this.stock = this.calculateStock(d.stock_sinicloud);
    }

    getRealValue(v: any) {
        return typeof v == "string" ? v.getNumber() : v;
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.Ref, this.Denominacion1p);
    }

    calculateStock(d: any) {
        if (!d) { return 0; }
        try {
            var finalStock = d.stock_siniwin - d.stock_sinicloud;
            if (finalStock < 0) { finalStock = 0; }
            return finalStock;
        }
        catch (e: any) {
            return 0;
        }
    }
}