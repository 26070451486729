<script src="https://cdn.lordicon.com/qjzruarw.js"></script>
<div class="container-send-email">
    <p class="pedirCitaSubtitle" style="font-weight: bold;">¡CITA CREADA CON ÉXITO!</p>
    <lord-icon *ngIf="showEmailIcon; else ok" src="https://cdn.lordicon.com/iepbfivp.json" trigger="loop"
        delay="9999" style="width:200px;height:200px">
    </lord-icon>
    <ng-template #ok>
        <lord-icon src="https://cdn.lordicon.com/sxddhwcs.json" trigger="loop" delay="9999" style="width:200px;height:200px">
        </lord-icon>
    </ng-template>
    <p *ngIf="showEmailIcon" class="tac">Revise la bandeja de entrada de su correo para más detalles</p>
    <p></p>
</div>