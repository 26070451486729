import { Injectable } from '@angular/core';
import { RouterService } from '@sinigual/angular-lib';
import { M_Cita } from '../core/models/M_Cita';
import { M_Rider } from '../core/models/M_Rider';
import { ViewPath } from '../modules/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class GoCreateReceptionService {

  constructor(private routerS  : RouterService) { 
  }
  
  goWithRider(r : M_Rider){
    this.routerS.goWithStateAndQueryParams(ViewPath.crearRecepcion, r, {plate : r.Matricula});
  }

  goWithAppointment(c : M_Cita){
    this.routerS.goWithStateAndQueryParams(ViewPath.crearRecepcion, c, {appointment : c.hash});
  }
}
