<div class="result" [ngClass]="{'selected' : selected}" *ngIf="parent.rider as rider">
    <p class="client-name">{{rider.Nombre1}}</p>
    <div class="result-lines">
        <div class="result-line" style="align-items: flex-start">
            <mat-icon fontSet="material-icons-outlined">two_wheeler_outline</mat-icon>
            <div class="df fdc">
                <span class="df fdc">{{rider.Matricula}}</span>
                <span class="fss c_t2">
                    <span nullable>{{rider.Marca}}</span>,
                    <span nullable>{{rider.MdlTaller}}</span>
                </span>
            </div>
        </div>
        <div class="result-line" style="align-items: flex-start">
            <mat-icon fontSet="material-icons-outlined">badge_outline</mat-icon>
            <div class="df fdc">
                <span class="df fdc">{{rider.Matricula}}</span>
                <span class="fss c_t2" nullable>{{rider.Nombre1}}</span>
            </div>
        </div>
    </div>
    <p class="selected-tag" *ngIf="selected">SELECCIONADO</p>
    <p class="fsxs c_t2 nmb mt5 mla" style="text-align: end;">Última reparación: <span nullable>{{rider.FeUltRepa?.dayMonthYearFormat}}</span></p>

</div>