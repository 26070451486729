import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { apiService } from 'src/app/core/api/api-service';
import { M_User } from 'src/app/core/models/M_User';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { CreateEditUserComponent } from './create-edit-user/create-edit-user.component';
import { PageStructureComponent, SearchService } from '@sinigual/angular-lib';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { ActivationStatus } from 'src/app/core/enums/ActivationStatus';
import { calendarEnabled } from 'src/app/constants/constants';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css', '../../css/table.css']
})
export class UsersComponent {
  
  au = feature.activateUsers;
  wor = feature.workloadOr;

  ae = ActivationStatus;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_User>;
  originalUserData: M_User[] = [];
  enabledCalender = calendarEnabled;

  dh = ['Nombre', 'Correo', 'Rol']
  dc = ['name', 'email', 'role.name'];

  constructor(private chdref: ChangeDetectorRef, private matDialog: MatDialog, private apiS: apiService, private searchS: SearchService) {
    this.dh.push(...(this.au ? (['Plataforma', 'Estado activación']) : []));
    this.dc.push(...(this.au ? (['user_platform', 'estado_activacion']) : []))
  }

  ngAfterViewInit(): void {
    this.load();
  }

  getRole(user: M_User) {
    return user.role.name;
  }

  getStatus(user: M_User) {
    return user.estado_activacion == ActivationStatus.activated || user.estado_activacion == null ? "Activado" :
      user.estado_activacion == ActivationStatus.sended ? "Pendiente" :
        user.estado_activacion == ActivationStatus.toActivate ? "Por activar" : "??"
  }

  getStatusClass(user: M_User) {
    return user.estado_activacion == ActivationStatus.activated || user.estado_activacion == null ? "c_p" :
      user.estado_activacion == ActivationStatus.sended ? "c_o" :
        user.estado_activacion == ActivationStatus.toActivate ? "c_r" : "c_t2"
  }

  getPlatform(user: M_User) {
    return user.user_platform.charAt(0).toUpperCase() + user.user_platform.slice(1);
  }

  load() {
    this.apiS.showUsers().then(resp => {
      this.ps.initTable(resp);
      this.originalUserData = resp;
      console.log(this.originalUserData)
      this.chdref.detectChanges();
    })
  }

  allMails() {
    let allMails: string[] = []
    this.originalUserData.forEach(u => {
      allMails.push(u.email)
    })
    return allMails;
  }

  activateUser(user: M_User) {
    this.apiS.activateUser(user.id).then(res => {
      user.estado_activacion = this.ae.sended;
    })
  }


  openEditUserDialog(user: M_User) {
    const dref = this.matDialog.open(CreateEditUserComponent, { data: [user, this.allMails()] })
    dref.afterClosed().subscribe(ok => { if (ok) { this.load(); this.ps.item = undefined } });
  }

  openCreateUserDialog() {
    const dref = this.matDialog.open(CreateEditUserComponent, { data: [undefined, this.allMails()] })
    dref.afterClosed().subscribe(ok => { if (ok) { this.load(); } });
  }

  openDeleteUserDialog(user: M_User) {
    const dref = this.matDialog.open(DeleteUserComponent, { data: user, panelClass: "core-error-dialog" })
    dref.afterClosed().subscribe(ok => { if (ok) { this.load(); this.ps.item = undefined } });
  }
}
