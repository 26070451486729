import { apiService } from "../../core/api/api-service";

export interface M_EstadoTallerInterface{
    or_opening: number
    or_close: number, 
    or_invoice: number
    med_repair_or : number
    invoice_med :number
    total_mo: number;
}

/** [Respuesta a una petición GET]
 *  @description Classe para parsear la respuesta a la llamada http del estado del taller
 *  {@link apiService.getEstadoTaller()}
 */
export class M_EstadoTaller implements M_EstadoTallerInterface{
    or_opening: number;
    or_close: number;
    or_invoice: number;
    med_repair_or: number;
    invoice_med: number;
    total_mo: number;
    constructor(obj : any){
        this.or_opening =       obj.or_opening;
        this.or_close =         obj.or_close;
        this.or_invoice =       obj.or_invoice;
        this.med_repair_or =    obj.med_repair_or;
        this.invoice_med =      obj.invoice_med;
        this.total_mo =         obj.total_mo;
    }
}

/*Los dias solicitados (Pueden ser 7, 30, o el més actual (~30))*/
export enum M_EstadoTallerParam{
    sieteDieas = '7',
    treintaDias = '30',
    mesActual = '~30'
}