<app-view-title-lord-icon [lordIcon]="li" [pageTitle]="'INCIDENCIAS'" class="df jcc"></app-view-title-lord-icon>
<ng-template #li>
  <script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
  <lord-icon src="https://cdn.lordicon.com/ouskbwea.json" trigger="loop" delay="9999" style="width:60px;height:60px">
  </lord-icon>
</ng-template>

<div class="search-add-header">
  <div class="df">
    <mat-form-field appearance="outline" class="flex80">
      <mat-label>Buscar incidencia...</mat-label>
      <input (keyup)="searchTicket()" [formControl]="fc" matInput placeholder="Buscar incidencia...">
      <button (click)="searchTicket()" mat-icon-button matPrefix>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <div class="add-button circle-button">
      <button mat-fab class="scb onboarading-users-add" (click)="createTicket()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="tableParent">
  <div *ngIf="loaded; else loading">
    <app-cloud-ticket (onAddComment)="onAddComment($event)" *ngFor="let ticket of filteredTickets"
      [cloudTicket]="ticket"></app-cloud-ticket>

    <div class="card-table-tr" *ngIf="tickets.length==0 && loaded">
      <script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
      <lord-icon src="https://cdn.lordicon.com/quswqrad.json" trigger="loop" delay="2000"
        colors="primary:#2f4858,secondary:#f4c89c,tertiary:#2f4858,quaternary:#008bd0,quinary:#9ce5f4"
        style="width:150px;height:150px">
      </lord-icon>

      <div class="df fdc tac mb20">
        <p class="nmb fsl fw500" style="margin-top: 20px; margin-right: 10px;">¡OOOPS!</p>
        <p class="nmb fsm" style="margin-top: 20px;">Parece ser que aún no tienes incidencias creadas</p>
        <p (click)="createTicket()" class="nmb fsm cp c_p" style="margin-top: 20px;">
          Crear una nueva incidencia
        </p>
      </div>
    </div>

  </div>
  <ng-template #loading>
    <div class="loading-skeleton">
      <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
      <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
      <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
      <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
    </div>
  </ng-template>
</div>