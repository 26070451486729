import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Article } from 'src/app/core/models/M_Article';
import { M_OR } from 'src/app/core/models/M_OR';

@Component({
  selector: 'app-line-type-selector',
  templateUrl: './line-type-selector.component.html',
  styleUrls: ['./line-type-selector.component.css']
})
export class LineTypeSelectorComponent {
  MAX_SUGGESTIONS = 10;
  fc: FormControl = new FormControl("");
  matches: M_Article[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { articles: M_Article[], or: M_OR }) {
    this.fc.valueChanges.subscribe(v => {
      this.search(v);
    })
  }

  search(v: any) {
    this.matches = [];
    if (v) {
      this.data.articles.forEach(a => {
        if (a.defaultSearchFilter(v)) {
          if (this.matches.length < 15) {
            this.matches.push(a);
          }
        }
      })
    }

    this.matches.sort((a,b)=> a.stock > b.stock ? -1 : 1)
  }
}
