import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill
} from "ng-apexcharts";
import { apiService } from 'src/app/core/api/api-service';
import { WeekDays } from 'src/app/core/enums/WeekDays';
import { M_CargaTaller } from 'src/app/core/models/M_CargaTaller';



export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};



const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];

@Component({
  selector: 'app-carga-taller',
  templateUrl: './carga-taller.component.html',
  styleUrls: ['./carga-taller.component.css']
})
export class CargaTallerComponent implements OnInit {
  @ViewChild('cargaTallerChart') referenceRef: any;
  public chartOptions: Partial<ChartOptions>;

  constructor(private apiServie: apiService) {
    this.chartOptions = {
      series: [{
      name: 'HORAS OCUPADAS',
      data:  [],
      color: "var(--dangerColor)",
    }, {
      name: 'HORAS DISPONIBLES',
      data: [],
      color: "var(--sinigualColor)"
    }],
      chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: 'normal'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'top',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    xaxis: {
      categories: [],
    },

    fill: {
      opacity: 1,
      colors: ['var(--dangerColor)', 'var(--sinigualColor)']
    },
    legend: {
      position: 'top',
      offsetX: 0,
      offsetY: 0
    },
    };
  }

  makeCall(): void {
    this.apiServie.getCargaTaller().then( response => this.processResponse(response));
  }

  ngOnInit(): void {
    this.makeCall();
  }
  
  processResponse(response : M_CargaTaller[]){
    let horasDisponibles =  [];
    let horasOcupadas =     [];
    let weekDay = [];
    
    for (let i =0; i < response.length; i++) {
      let day_ = response[i];
      let dayNumber : number = this.getDayPositionByEnum(day_.day);
      if (dayNumber != 6) {
        let horasDisp = Number(day_.hours_available);
        let horasOcup = Number(day_.hours_busy);
        horasDisponibles.push(horasDisp);
        horasOcupadas.push(horasOcup);
        if (horasDisp != 0 && horasOcup != 0){
          weekDay.push(dias[dayNumber]);
        } 
      }
    }
    this.refreshGraphics(horasDisponibles, horasOcupadas, weekDay);
  }

  getDayPositionByEnum(dayName : string) : number{
    switch(dayName){
      case(WeekDays.lunes):     return 0;
      case(WeekDays.martes):    return 1;
      case(WeekDays.miercoles): return 2;
      case(WeekDays.jueves):    return 3;
      case(WeekDays.viernes):   return 4;
      case(WeekDays.sabado):    return 5;
      case(WeekDays.domingo):   return 6;
    }
    return 0;
  }

  refreshGraphics(horasDisponibles : number[], horasOcupadas : number[], dias : string[]){
    this.chartOptions.xaxis!.categories = dias;
    this.chartOptions.series![0].data = horasOcupadas;
    this.chartOptions.series![1].data = horasDisponibles;
    this.referenceRef.updateOptions(this.chartOptions);
  }
}
