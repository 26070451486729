import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { CustomFile, FileUploadComponent } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { EditAddComponent } from '../../edit-add.component';
import { AccordionInterface } from '../accordionInterface';

@Component({
  selector: 'app-subir-fotografia',
  templateUrl: './subir-fotografia.component.html',
  styleUrls: ['./subir-fotografia.component.css', '../accordion.css']
})
export class SubirFotografiaComponent implements OnInit, AccordionInterface {
  @ViewChild("accordion") accordion!: MatAccordion;
  @ViewChild('oldImages') old?: FileUploadComponent;
  @ViewChild('newImages') fuc?: FileUploadComponent;
  init = false;

  constructor(@Inject(EditAddComponent) public parent: EditAddComponent, private apiS: apiService) {
    parent.subject.subscribe(_v => this.setData())
  }

  setData(): void {
    this.initPictures();
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.initPictures();
  }

  hasImages() {
    var newFiles = this.fuc?.getUploadedFiles();
    var oldFiles = this.old?.getCustomFiles();
    return (newFiles != undefined && newFiles.length >= 1) || (oldFiles != undefined && oldFiles.length >= 1)
  }


  alereadyUploaded() {
    var uf = this.old?.uploadedFiles;
    if (Array.isArray(uf)) {
      return uf.length;
    }
    return 0;
  }

  initPictures() {
    if (this.fuc && this.parent.bike?.add && !this.init) {
      this.old?.setImagesFromDatabase(this.parent.bike.add.fotos)
      if (this.parent.bike?.add?.foto_portada && this.old) {
        this.old.setPrincipal(this.parent.bike.add.getIndexPortada())
      }
      this.init = true;
    }
  }

  onRemove(cf: CustomFile) {
    console.log("On remove image")
    console.log("Image id : ", )
    if (cf.database_id && this.parent.bike?.add?.id) {
      this.apiS.deleteStockImage(this.parent.bike.add.id, cf.database_id).then(_res => {
        this.parent.bike?.add?.fotos.forEach((f, index) => {
          if (f.database_id == cf.database_id){
            this.parent.bike?.add?.fotos.removeIndex(index);
          }
        })
      });
    }
  }

  onFav(cf: CustomFile) {
    if (cf.database_id && this.parent.bike?.add?.id && !this.isAlreadyPrincipal(cf)) {
      this.apiS.setMainPicture(this.parent.bike.add.id, cf.database_id).then(_res => {
        console.log(this.parent.bike)
        if (this.parent.bike?.add) {
          this.parent.bike.add.foto_portada = cf.database_id;
        }
      });
    }
  }

  isAlreadyPrincipal(cf: CustomFile) {
    if (this.parent?.bike?.add) {
      var indexOfPrincipal = this.parent.bike.add.getIndexPortada();
      if (cf.id == indexOfPrincipal) {
        return true;
      }
    }
    return false;
  }

}
