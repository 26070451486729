<app-card-title>ELIMINAR</app-card-title>
<p class="tac">Va a eliminar el usuario <span class="fw500">{{usuario.name}}</span></p>

<p>Introduce el código de seguridad <span class="fw500">{{fd.securityCode}}</span> para poder proceder</p>

<div class="df jcc">
    <app-fourdigits #fd></app-fourdigits>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button (click)="makeCall()" [ngClass]="fd.isOk() ? 'bc_r c_w' : 'bc_t2'" [disabled]="!fd.isOk()" mat-button
        cdkFocusInitial>Eliminar</button>
</div>