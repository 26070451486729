
<div [ngClass]="isSearchResult? 'searchResult' : 'datosCliente'" [class.mat-elevation-z8]="isSearchResult">
    <!-- Datos Cliente -->
    <div class="clientContent">
        <app-card-line title="CLIENTE" icon="sports_motorsports"  mainColor='var(--sinigualColor)' >
            <client-info-line name="Nombre"     [value]="datosCliente.nombre"></client-info-line>
            <client-info-line name="Teléfono"   [value]="datosCliente.numero"></client-info-line>
            <client-info-line name="Calle"      [value]="datosCliente.direccion"></client-info-line>
            <client-info-line name="Ciudad"     [value]="datosCliente.ciudad"></client-info-line>
            <client-info-line name="Email"      [value]="datosCliente.email"></client-info-line>
            <client-info-line name="DNI"        [value]="datosCliente.dni"></client-info-line>
        </app-card-line>

        <app-card-line class="sr2" title="VEHÍCULO" icon="two_wheeler" mainColor='var(--blue)'[isSearchResult]="isSearchResult">
            <client-info-line name="Matrícula"                  [value]="dv.maricula ? dv.maricula.toUpperCase() : ''"></client-info-line>
            <client-info-line name="Número de bastidor"         [value]="dv.bastidor"></client-info-line>
            <client-info-line name="Marca"                      [value]="dv.marca"></client-info-line>
            <client-info-line name="Modelo"                     [value]="dv.modelo"></client-info-line>
            <client-info-line name="Fecha última reparación"    [value]="dv.fechareparacion?.dayMonthYearFormat"></client-info-line>
            <client-info-line name="Km. Última reparación"      [value]="dv.kmultimareparacion"></client-info-line>
        </app-card-line>
    </div>
    <div *ngIf="isSearchResult" style="display: flex">
        <ng-content></ng-content>
    </div>
</div>