import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService, RouterService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_User } from 'src/app/core/models/M_User';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css', '../login/login.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public form!: UntypedFormGroup;
  user?: M_User;
  private hash?: string;
  loaded = false;
  constructor(private route: ActivatedRoute, private rs: RouterService, private formBuilder: UntypedFormBuilder, private apiS: apiService,
    private dialog: MatDialog, private chdRef: ChangeDetectorRef, private confirmDialogS: ConfirmDialogService) {
    this.form = this.formBuilder.group({
      pass1: [''],
      pass2: ['',]
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params["hash"]) {
        this.apiS.showUser(params["hash"]).then(resp => {
          if (resp instanceof M_User) {
            this.user = resp;
            this.hash = params["hash"];
            this.chdRef.detectChanges();
          }
          else {
            this.rs.goTo(ViewPath.pageNotFound)
          }
          this.loaded = true;
        })
      }
      else {
        this.rs.goTo(ViewPath.pageNotFound)
      }
    });
  }

  ngAfterViewChecked(): void {
    this.chdRef.detectChanges();
  }

  makeCall() {
    this.apiS.changePassword(this.hash!, this.form.get("pass1")?.value).then(
      _resp => {
        this.confirmDialogS.show(
          {
            title: "Contraseña modificada",
            confirmTxt: "Iniciar sesión",
            body: "Tu constraseña se ha modificado con éxito",
            showCancel : false,
            disableClose : true
          }
        ).afterClosed().subscribe(res => {
          this.rs.goWithQueryParams(ViewPath.login, { user_code: this.user?.client_code, company_code: this.user?.company_code });
        })
      }
    )
  }

}
