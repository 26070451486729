import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-cita-result-cliente-dialog',
  templateUrl: './cita-result-cliente-dialog.component.html'
})
export class CitaResultClienteDialogComponent implements OnInit {
  
  constructor(public router: Router, public dialogRef: MatDialogRef<CitaResultClienteDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: M_Cita) {}

  ngOnInit(): void {
  }

  getUrl() : string{
    return environment.apiUrl+"/"+ViewPath.editarCita.path+"?hash="+this.data.hash;
  }

  goToEdit(){
    this.dialogRef.close();
    this.router.navigate([ViewPath.editarCita.path], { queryParams: { hash: this.data.hash} });
  }

}
