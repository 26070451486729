import { apiService } from "../../core/api/api-service";

export interface M_DeleteOrInterface{
    or_id : number;
}

/**
 * [POST]
 * Esta classe representa el body de la petición {@link apiService.deleteOr()}
 */
export class M_DeleteOr implements M_DeleteOrInterface{
    or_id: number;
    constructor(or_id : number){
        this.or_id = or_id;
    }
}