import { Component, OnInit, ViewChild } from '@angular/core';
import { AppointmentStatus, CoreCargaTallerComponent, CustomDate, RouterService } from '@sinigual/angular-lib';
import { WorkloadService } from '../core/services/workload.service';
import { ViewPath } from '../modules/app-routing.module';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentInterface } from '@sinigual/angular-lib/lib/interface/AppointmentInterface';
import { M_Cita } from '../core/models/M_Cita';
import { apiService } from '../core/api/api-service';
import { GoCreateReceptionService } from '../services/go-create-reception.service';
import { MecancicoDescartarCitaComponent } from '../views/citas/Mecanico/mecancico-descartar-cita/mecancico-descartar-cita.component';
import { MecanicoCitaHechaComponent } from '../views/citas/Mecanico/mecanico-cita-hecha/mecanico-cita-hecha.component';
import { MecanicoModificarHoraComponent } from '../views/citas/Mecanico/mecanico-modificar-hora/mecanico-modificar-hora.component';
import { MecanicoMarcarPendienteCitaComponent } from '../views/citas/Mecanico/mecanico-marcar-pendiente-cita/mecanico-marcar-pendiente-cita.component';

@Component({
  selector: 'app-carga-taller-view',
  templateUrl: './carga-taller-view.component.html',
  styleUrls: ['./carga-taller-view.component.css']
})
export class CargaTallerViewComponent implements OnInit {

  @ViewChild(CoreCargaTallerComponent) public cargaTaller!: CoreCargaTallerComponent;
  v = ViewPath;
  constructor(public routerS: RouterService, public wS: WorkloadService, private dialog: MatDialog, private apiS: apiService, private goNewReception: GoCreateReceptionService) { }
  ngOnInit(): void { }

  onModifyAppointment(change: [AppointmentInterface, ("ra" | "eliminar" | "done" | "pending" | "hour")]): void {
    var appointment = change[0];
    var changeName = change[1];

    if (changeName == "ra") {
      this.goToNewReceptionWithData(appointment)
    }
    if (changeName == "eliminar") {
      this.descartarDialogo(appointment);
    }
    if (changeName == "pending") {
      this.pendingDialog(appointment);
    }
    if (changeName == "done") {
      this.marcarHechaDialogo(appointment);
    }
    if (changeName == "hour") {
      this.onChangeHour(appointment);
    }
  }

  /**Botón par marcar una cita como pendiente */
  pendingDialog(appointment: AppointmentInterface): void {
    var cita = new M_Cita(appointment);
    const dialogRef = this.dialog.open(MecanicoMarcarPendienteCitaComponent, {
      data: cita,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.pendiente;
        cita.setStatus = AppointmentStatus.pendiente;
        this.apiS.editarCitaCliente(cita!, cita!.hash)
        this.cargaTaller.regenerateCalendar();
      }
    });
  }

    /**Botón de 'cerrar' cita en la tarjeta */
    descartarDialogo(appointment: AppointmentInterface): void {
      var cita = new M_Cita(appointment);
      const dialogRef = this.dialog.open(MecancicoDescartarCitaComponent, {
        data: cita,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          appointment.setStatus = AppointmentStatus.eliminada;
          cita.setStatus = AppointmentStatus.eliminada;
          this.apiS.editarCitaCliente(cita!, cita!.hash)
          this.cargaTaller.regenerateCalendar();
        }
      });
    }

    /**Botón de 'hecha' cita en la tarjeta */
    marcarHechaDialogo(appointment: AppointmentInterface): void {
      var cita = new M_Cita(appointment);
      const dialogRef = this.dialog.open(MecanicoCitaHechaComponent, {
        data: cita,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          appointment.setStatus = AppointmentStatus.cerrada; //Cerrada = Hecha
          cita.setStatus = AppointmentStatus.cerrada; //Cerrada = Hecha
          this.apiS.editarCitaCliente(cita!, cita!.hash)
          this.cargaTaller.regenerateCalendar();
        }
      });
    }

    goToNewReceptionWithData(appointment: AppointmentInterface) {
      var cita = new M_Cita(appointment);
      this.goNewReception.goWithAppointment(cita);
    }

    onChangeHour(appointment: AppointmentInterface) {
      var cita = new M_Cita(appointment);
      const dialogRef = this.dialog.open(MecanicoModificarHoraComponent, {
        data: cita,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          //A partir del resultado del dialogo, modificamos la fecha de la cita
          cita.day = new CustomDate(result);
          appointment.day = new CustomDate(result);
          this.apiS.editarCitaCliente(cita!, cita!.hash).then(_res => {
            this.cargaTaller.regenerateCalendar();
          })
        }
      });
    }

  }
