<h1 mat-dialog-title>¡Vaya!</h1>
<div mat-dialog-content>
    <p>Parece ser que has encontrado algo inusual</p>
    <div class="df jcc">
        <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
        <lord-icon #li src="https://cdn.lordicon.com/juaoautu.json" trigger="hover" style="width:250px;height:250px">
        </lord-icon>
        <img style="display: none;" #img
            src="https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Trollface_non-free.png/220px-Trollface_non-free.png">
    </div>

    <p>¿Quieres ver de qué se trata?</p>

</div>
<div mat-dialog-actions #btn>
    <button mat-button cdkFocusInitial
        (click)="img.style.display='block'; li.style.display='none'; btn.style.display='none'; btn2.style.display='block'">
        <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" target="_blank" rel="noopener noreferrer">
            Sorpréndeme
        </a>
    </button>
</div>
<div mat-dialog-actions #btn2 style="display: none">
    <button mat-button #btn2 mat-dialog-close>Cerrar</button>
</div>