import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Cita } from 'src/app/core/models/M_Cita';

@Component({
  selector: 'app-mecanico-cita-hecha',
  templateUrl: './mecanico-cita-hecha.component.html',
  styleUrls: ['./mecanico-cita-hecha.component.css']
})

export class MecanicoCitaHechaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MecanicoCitaHechaComponent>, @Inject(MAT_DIALOG_DATA) public cita: M_Cita) {}
  ngOnInit(): void {}

}
