import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppointmentStatus, CalendarService, CustomColor, CustomDate, CustomFile, CustomTime, ErrorDialogService, FileUploadComponent, FormService, SnackService, toFormData } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { CustomDateByGranularity, DateByGranularity } from 'src/app/core/custom-classes/CustomDateByGranularity';
import { DateGranularity } from 'src/app/core/enums/DateGranulraity';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { M_Company } from 'src/app/core/models/M_Company';
import { M_ProfilePicture } from 'src/app/core/models/M_ProfilePicture';
import { TimeService } from 'src/app/core/services/time.service';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { environment } from 'src/environments/environment';
import { CompanyCalendarEeComponent } from './company-calendar-ee/company-calendar-ee.component';
import { ProfileService } from '../profile/profileService';
import { ConfigurationComponent } from '../configuration/configuration.component';
import { MatSlider } from '@angular/material/slider';
import { HolidaysCalendarComponent } from 'src/app/components/holidays-calendar/holidays-calendar.component';
import { calendarEnabled } from 'src/app/constants/constants';

@Component({
  selector: 'app-edit-company-info',
  templateUrl: './edit-company-info.component.html',
  styleUrls: ['./edit-company-info.component.css']
})
export class EditCompanyInfoComponent implements OnInit {
  env = environment;
  f = feature;
  granularity: number[] = [5, 15, 30]
  @ViewChild('select4') select_ca!: MatSelect;
  @ViewChild('select1') select_om!: MatSelect;
  @ViewChild('select2') select_cm!: MatSelect;
  @ViewChild('select3') select_oa!: MatSelect;
  @ViewChild(FileUploadComponent) imageUploadC!: FileUploadComponent;
  @ViewChild(HolidaysCalendarComponent) hCalendar!: HolidaysCalendarComponent;
  @Output() onProvidersInfo: EventEmitter<any> = new EventEmitter();
  needsHighlight = false;
  loaded = false;

  public companyForm: UntypedFormGroup;
  DEFAULT_HOURS_OPENING_MORNING = this.generateInputHours(60 * 5)
  DEFAULT_HOURS: [CustomTime[], CustomTime[], CustomTime[], CustomTime[]] = [this.DEFAULT_HOURS_OPENING_MORNING, [], [], []]
  selected: Date | null | undefined;
  todayDate: Date = new Date();
  selectsArray: MatSelect[] = []
  company: M_Company | undefined;
  enabledCalender = calendarEnabled;
  constructor(
    @Inject(ConfigurationComponent) public configComponent : ConfigurationComponent,
    private cdRef: ChangeDetectorRef, public profileS : ProfileService, public cs: CalendarService, private errorDialog: ErrorDialogService,
    private formBuilder: UntypedFormBuilder, private apiService: apiService, private timeService: TimeService, private d: MatDialog,
    private snackService: SnackService, private formService: FormService, private router: Router) {

    this.companyForm = this.formBuilder.group({

      //INFROMACIÓN GENERAL
      email: ['', Validators.required],
      nif: ['', Validators.required],
      direccion: ['', Validators.required],
      codigo_postal: ['', Validators.required],
      telefono: ['', Validators.required],
      telefono_alternativo: [''],

      company_name: ['', [Validators.required]],
      company_logo: [''],
      morning_work_schedule_start: ['', [Validators.required]],
      morning_work_schedule_finish: ['', [Validators.required]],
      afternoon_work_schedule_start: ['', [Validators.required]],
      afternoon_work_schedule_finish: ['', [Validators.required]],
      range_of_days: ['1', [Validators.required]],
      granularity: ['', [Validators.required]],
      day_holiday: [''],
      color1: [''],
      color2: ['']
    });
  }

  ngOnInit(): void { }

  emitClickProviders() {
    this.onProvidersInfo.emit();
  }

  get canActivateProviders() {
    return this.company != undefined &&
      this.company.nif != undefined &&
      this.company.email != undefined &&
      this.company.direccion != undefined &&
      this.company.codigo_postal != undefined &&
      this.company.telefono != undefined;
  }

  ngAfterViewInit() {
    this.apiService.getCompanyInfo().then(response => this.procesResponse(response))
    this.selectsArray = [this.select_om, this.select_cm, this.select_oa, this.select_ca];
    this.cdRef.detectChanges();
  }

  goToPedirCita() {
    console.log(this.company)
    this.router.navigate([ViewPath.pedirCita.path], { queryParams: { companyId: this.company?.hash } });
  }

  formatAndSetMultipleDates(data: M_Company) {
    var d : CustomDate[] = []
    for (let i = 0; i < data.day_holiday.length; i++) {
      d.push(new CustomDate(data.day_holiday[i].value!))
    }
    this.hCalendar.initComponent(d , []);
  }

  makeCall() {
    if (this.formService.isOk(this.companyForm)) {
      this.companyForm.patchValue({
        'company_logo': this.imageUploadC.hasFiles() ? this.imageUploadC.getCustomFile()!.src : "",
      });
      let editedCompany: M_Company = new M_Company(this.companyForm.value);
      editedCompany.company_code = this.company?.company_code;
      editedCompany.client_code = this.company?.client_code;
      editedCompany.hash = this.company!.hash;
      console.log(editedCompany)
      editedCompany.company_logo = this.imageUploadC.hasFiles() ? this.imageUploadC.getCustomFile()!.src : "";
      editedCompany.day_holiday = this.hCalendar.companyHolidays;
      editedCompany.colors = [new CustomColor(this.companyForm.get('color1')!.value), new CustomColor(this.companyForm.get('color2')!.value)]
      let config = new MatSnackBarConfig();
      config.duration = 1000;
      config.panelClass = "snack-dialog"
      this.apiService.setCompanyInfo(editedCompany).then(
        _res => {
          this.snackService.show("Datos de empresa guardados")
          this.hCalendar.holidayChanges = false;
          this.company = editedCompany;
        }
      );
    }
  }

  onUploadFile(f: CustomFile) {
    this.apiService.setCompanyImg(toFormData(new M_ProfilePicture(f.file!)));
  }

  procesResponse(response: M_Company): any {
    this.company = response;
    console.log(this.company)
    if (this.company.company_logo) {
      this.imageUploadC.setImageFromDatabase(this.company.company_logo);
    }

    if (this.company.hasSchedule()) {
      this.generateInput(this.company.morning_work_schedule_start, 0)
      this.generateInput(this.company.morning_work_schedule_finish, 1)
      this.generateInput(this.company.afternoon_work_schedule_start, 2)
      this.generateInput(this.company.afternoon_work_schedule_finish, 3)
    }

    this.formatAndSetMultipleDates(this.company);
    this.companyForm.patchValue({ 'range_of_days': this.company.range_of_days })

    this.companyForm.patchValue({
      'email': this.company.email,
      'nif': this.company.nif,
      'direccion': this.company.direccion,
      'codigo_postal': this.company.codigo_postal,
      'telefono': this.company.telefono,
      'telefono_alternativo': this.company.telefono_alternativo,
    })

    this.companyForm.patchValue({
      'company_name': this.company.company_name,
      'morning_work_schedule_start': this.company.morning_work_schedule_start,
      'morning_work_schedule_finish': this.company.morning_work_schedule_finish,
      'afternoon_work_schedule_start': this.company.afternoon_work_schedule_start,
      'afternoon_work_schedule_finish': this.company.afternoon_work_schedule_finish,
      'granularity': this.company.granularity ? this.company.granularity.toString() : 15,
      'color1': this.company.getColor1().value,
      'color2': this.company.getColor2().value,
    })
  }

  getTooltip(slider: MatSlider, apertura: MatSelect) {
    let str = "";
    let startTime = apertura.value;
    for (let i = 0; i <= 3; i++) {
      str += this.timeService.parseNumberToHour(startTime).getText() + (i == 3 ? '' : ", ");
      startTime += Number(slider.value);
    }
    return str;
  }

  get getURL() {
    return document.location.protocol + "//" + window.location.hostname + (this.env.local ? ':4200' : '') + '/pedircita?companyId=' + this.company?.hash
  }

  defaultColor() {
    return new CustomColor().value
  }

  /** ================== */
  /** INPUT VALUES */
  /**Genera el desplegable de las horas según el valor que llegue del servidor */
  generateInput(currentHour: number, i: number) {
    if (this.selectsArray[i]) {
      this.selectsArray[i].setDisabledState(false);
      this.DEFAULT_HOURS[i] = this.generateInputHours(currentHour);
      this.selectsArray[i].value = currentHour;
    }
  }

  /**A partir de la fecha que viene del servidor, se genera el input */
  generateInputHours(currentHour: number, start?: boolean) {
    let hoursavailable: CustomTime[] = [];
    let startHour;
    let endHour;
    let granularity = 30;
    if (!start) {
      startHour = currentHour - (60 * 3);
      endHour = currentHour + (60 * 3);
    }
    else {
      startHour = currentHour;
      endHour = currentHour + (60 * 6);
    }

    for (let i = startHour; i < endHour; i += granularity) {
      if (i < 60 * 24) {
        hoursavailable.push(this.timeService.parseNumberToHour(i));
      }
    }
    return hoursavailable;
  }


  /** If the company shcedule is correct like
   * OK =     (9:00 - 13:00 | 14:00 - 18:00) 
   * NOT OK = (19:00 - 18:00 | 11:00 - 9:00) */
  isProgressiveBigger(){
    var ok = true;
    for (let i = 0; i < this.selectsArray.length; i++) {
      if (i != 0){
        if (this.selectsArray[i-1].value > this.selectsArray[i].value){
          ok = false;
        }
      }
    }
    return ok;
  }

  /**Detecta los cambios en los inputs. Regenera los inputs necessarios */
  inputChanged(evnt: MatSelectChange, id: number) {
    
    if (!this.isProgressiveBigger()) {
      for (let i = id + 1; i < this.selectsArray.length; i++) {
        this.selectsArray[i].setDisabledState(true)
        this.selectsArray[i].value = ""
        this.DEFAULT_HOURS[i] = [];
        /**Necessario el patchValue para forzar el reseteo del input */
        switch (id + 1) {
          case (1):
            this.companyForm.patchValue({ "morning_work_schedule_finish": undefined });
            break;
          case (2):
            this.companyForm.patchValue({ "afternoon_work_schedule_start": undefined });
            break;
          case (3):
            this.companyForm.patchValue({ "afternoon_work_schedule_finish": undefined });
            break;
        }
      }
  
      if (id + 1 != this.selectsArray.length) {
        this.selectsArray[id + 1].setDisabledState(false);
        this.selectsArray[id + 1].value = ""
        this.DEFAULT_HOURS[id + 1] = this.generateInputHours(evnt.value, true);
      }
    }
  }

  copyToClippboard() {
    navigator.clipboard.writeText(this.getURL);
    this.snackService.show("URL copiada en el portapapeles")
  }

  clippboard(d: string | number | undefined, result: string) {
    if (d) {
      navigator.clipboard.writeText(d.toString());
      this.snackService.show(result)
    }
  }

  formatLabel(value: number): string {
      return value + 'm.';
  }
}