import { Component, Input, OnInit } from '@angular/core';
import { M_Cita, M_Cita_AlreadyClient } from 'src/app/core/models/M_Cita';

@Component({
  selector: 'app-create-cita-client-email-send',
  templateUrl: './create-cita-client-email-send.component.html',
  styleUrls: ['./create-cita-client-email-send.component.css', './../../cita-client.css']
})
export class CreateCitaClientEmailSendComponent implements OnInit {

  @Input() citaCreared! : M_Cita_AlreadyClient | M_Cita | undefined;
  @Input() showEmailIcon : boolean = true;
  constructor() { }
  ngOnInit(): void {}
}
