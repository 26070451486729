import { generateImageURL } from "@sinigual/angular-lib";
import { environment } from "src/environments/environment";

export class M_Provider {
    id: number;
    name: string;
    image?: string;
    description: string;
    onCompany: boolean;
    url_publication : string | undefined;

    constructor(d: any) {
        this.id = d.service_id ? d.service_id : d.id;
        this.name = d.nombre;
        this.image = generateImageURL(environment, d.logo);
        this.description = d.descripcion;
        this.url_publication =  d.url_publication;
        this.onCompany = d.onCompany;
    }
}