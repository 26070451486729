<p class="or-line-title">
    <mat-icon class="vam">{{g.icon}}</mat-icon>
    {{g.title}}
    <span *ngIf="g.isArticle" class="c_t2 fss">{{"(Ref : " + (g.CodRef ? g.CodRef : "??") + " )"}}</span>
    <button *ngIf="!g.siniwinLine && canEditOr" mat-button color="primary" [disabled]="!saveEnabled()"
        [ngClass]="saveEnabled() ? 'scale-up' : 'scale-down'" (click)="save()">{{g.created? 'Guardar' :
        'Crear'}}</button>
    <span class="c_r fw500 fss">{{saveExtraInfo()}}</span>
</p>

<div class="or-line-container" [ngClass]="{'comment': g.isComment}">

    <!-- REFERENCIA -->
    <div *ngIf="g.showCodeRef" class="ref">
        <div *ngIf="g.siniwinLine">Referencia : {{g.CodRef}}</div>
        <mat-form-field appearance="outline" *ngIf="!g.siniwinLine">
            <mat-label>Referencia</mat-label>
            <input [disabled]="!canChangeInput" matInput [(ngModel)]="g.CodRef" placeholder="Introduce la referencia"
                (input)="g.changes = true">
        </mat-form-field>
    </div>

    <!-- DENOMINACIÓN-->
    <div [ngClass]="{'df fw1' : g.isComment}" *ngIf="g.showDenomination" class="deno">
        <div *ngIf="g.siniwinLine" class="siniwin-line">
            <span>Deno. : </span>
            <span nullable [val]="g.getTitle()"></span>
        </div>
        <mat-form-field *ngIf="!g.siniwinLine" appearance="outline" [ngClass]="{'fw1 tas' : g.isComment}">
            <mat-label>{{g.isComment? 'Comentario' : 'Denominación'}}</mat-label>
            <input #denoInput [disabled]="!canChangeInput" matInput [(ngModel)]="g.DenoRef1p"
                [ngClass]="{'comment-input' : g.isComment}"
                [placeholder]="g.isComment? 'Introduce el comentario' : 'Introduce la denominación'"
                (input)="g.changes = true" [formControl]="deno" [maxlength]="maxDenoLength">
            <mat-hint>{{denoInput.value.length}}/{{maxDenoLength}}</mat-hint>
        </mat-form-field>
    </div>

    <!-- CANTIDAD -->
    <div *ngIf="g.showQuantity" class="smallfield cant">
        <div *ngIf="g.siniwinLine" class="siniwin-line">
            <span>Cantidad: </span>
            <span>{{g.Cantidad}}</span>
        </div>
        <mat-form-field *ngIf="!g.siniwinLine" appearance="outline">
            <mat-label>Cantidad</mat-label>
            <input #input [disabled]="!canChangeInput" [formControl]="quantity" type="number" matInput
                [(ngModel)]="g.Cantidad" placeholder="Introduce la cantidad"
                (input)="g.changes = true; showAlertIfNecessary(input); parent.updateValidityOfAllInputs();">

            <mat-hint *ngIf="g.isArticle">
                <span *ngIf="stockControl" [ngClass]="{'c_r' : !enoughStock}">
                    <span class="w-more-1095">Stock</span>
                    <span class="w-less-1095">S</span>
                    : {{articleStock}}
                </span>

                <span *ngIf="!stockControl">
                    <span class="fsxs">
                        <span class="w-more-1095">Sin control de Stock</span>
                        <span class="w-less-1095">No control S.</span>
                    </span>
                </span>

            </mat-hint>

        </mat-form-field>
    </div>

    <!-- PVP -->
    <div *ngIf="g.showQuantity" class="smallfield pvp">
        <div *ngIf="g.siniwinLine" class="siniwin-line">
            <span>PVP: </span>
            <span money [val]="g.Pvp"></span>
        </div>
        <mat-form-field appearance="outline" *ngIf="!g.siniwinLine">
            <mat-label>PVP</mat-label>
            <input [disabled]="!canChangeInput" [formControl]="pvp" type="number" matInput [(ngModel)]="g.Pvp"
                placeholder="Introduce el PVP" (input)="g.changes = true">
            <span matSuffix>€</span>
        </mat-form-field>
    </div>


    <!-- DTO -->
    <div *ngIf="g.showDto" class="smallfield dto">
        <div *ngIf="g.siniwinLine" class="siniwin-line">
            <span>Descuento: </span>
            <span>{{g.Dto}}%</span>
        </div>
        <mat-form-field *ngIf="!g.siniwinLine" appearance="outline">
            <mat-label>
                <span class="w-more-1095">Descuento</span>
                <span class="w-less-1095">DTO</span>
            </mat-label>
            <input [disabled]="!canChangeInput" [formControl]="discount" type="number" matInput [(ngModel)]="g.Dto"
                placeholder="Introduce el descuento" (input)="g.changes = true">
            <span matSuffix>%</span>
        </mat-form-field>
    </div>
    <!-- IMPORTE -->
    <div *ngIf="g.showImporte" class="imp">
        <div *ngIf="g.siniwinLine" class="siniwin-line">
            <span>Importe: </span>
            <span money [val]="g.total"></span>
        </div>
        <div *ngIf="!g.siniwinLine" class="import" money [val]="g.total"></div>
    </div>

    <app-or-type-label [shortM]="responsiveS.w < 990" [matMenuTriggerFor]=" canChangeGroup ? TiLiniaMenu : null" class="df jcc type"
        [ngClass]="{'cursor_pointer' : canChangeGroup}" [tipo]="g.TiLinea ? [g.TiLinea] : undefined"
        [wrapOn]="responsiveS.w < 500 ? 1 : undefined" [showText]="false"></app-or-type-label>

    <!-- DELETE -->
    <button mat-icon-button *ngIf="canDeleteLine" class="delete"><mat-icon class="c_r"
            (click)="ondelete.emit(g)">remove</mat-icon></button>

</div>



<mat-menu #TiLiniaMenu overlayPanelClass="or-line-type-selector">
    <button (click)="g.TiLinea = t_n; g.changes = true;" mat-menu-item *ngIf="g.isOrTypeLabelEnabled(t_n)">
        <app-or-type-label [tipo]="[t_n]" [showText]="false"></app-or-type-label>
    </button>
    <button (click)="g.TiLinea = t_ci; g.changes = true;" mat-menu-item *ngIf="g.isOrTypeLabelEnabled(t_ci)">
        <app-or-type-label [tipo]="[t_ci]" [showText]="false"></app-or-type-label>
    </button>
    <button (click)="g.TiLinea = t_g; g.changes = true;" mat-menu-item *ngIf="g.isOrTypeLabelEnabled(t_g)">
        <app-or-type-label [tipo]="[t_g]" [showText]="false"></app-or-type-label>
    </button>
    <button (click)="g.TiLinea = t_s; g.changes = true;" mat-menu-item *ngIf="g.isOrTypeLabelEnabled(t_s)">
        <app-or-type-label [tipo]="[t_s]" [showText]="false"></app-or-type-label>
    </button>
    <button (click)="g.TiLinea = t_m; g.changes = true;" mat-menu-item *ngIf="g.isOrTypeLabelEnabled(t_m)">
        <app-or-type-label [tipo]="[t_m]" [showText]="false"></app-or-type-label>
    </button>
</mat-menu>