<app-card-title>HISTORIAL</app-card-title>

<mat-dialog-content>
    <div *ngFor="let t of data.or.timers" class="timers-list">

        <ng-container *ngIf="t.custom">
            <div>Añadido manualmente</div>
        </ng-container>
    
        <ng-container *ngIf="t.end_time && t.start_time.isEquals(t.end_time) && !t.custom">
            <div>{{t.start_time.dayMonthYearFormat}} </div>
            <div  class="c_t2">{{t.start_time.clockFormat + " - " + t.end_time.clockFormat}}</div>
        </ng-container>
    
        <ng-container *ngIf="t.end_time && !t.start_time.isEquals(t.end_time) && !t.custom">
            <div class="c_r fsl">!</div>
            <div>{{t.start_time.shortFormat}}</div>
            <div>{{t.end_time.shortFormat}}</div>
        </ng-container>
    
        <ng-container *ngIf="!t.end_time && !t.custom">
            <div>{{t.start_time.dayMonthYearFormat}} </div>
            <div  class="c_t2">{{t.start_time.clockFormat + " - " + '??'}}</div>
        </ng-container>
    
    
    
        <div class="pr0 df aic">
            <div class="c_p mr10">{{t.elapsed.format}}</div>
            <button mat-icon-button (click)="deleteTimeLine(t.id)" *ngIf="canDelete">
                <mat-icon class="c_r">remove</mat-icon>
            </button>
        </div>
    </div>
    

    
    <p *ngIf="data.or.timers.length == 0" class="c_t2 fsm">Nada que mostrar</p>
</mat-dialog-content>

<mat-dialog-actions  *ngIf="data.or.timers.length != 0">
    <div class="timer-total">
         <p><span class="c_t fw500">Total: </span>{{getTotalTime().format}}</p>
    </div>
</mat-dialog-actions>