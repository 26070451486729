<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <mat-checkbox>
      <img #img style="margin-left: 10px;width: 20px; vertical-align: middle"
        src="./assets/img/comentarios/{{node.icon}}.png" (error)="img.className='hidden'">
      {{node.text}}</mat-checkbox>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node cp">
      <button #btn mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.text">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
        <span>{{node.text}}</span>
      </button>
    </div>
    <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>