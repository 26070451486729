/**
* 0 = LIBRE
* 1 = CERRADA
* 2 = OCUPADA
* 3 = PENDIENTE GENERAR ISPA (TOTAL)
* 4 = PENDIENTE GENERAR ISPA (PARCIAL)
* 5 = BLOQUEDA (REPUESTOS GALICIA)
* 6 = APROBADA PARA FACTURAR(REPUESTOS GALICIA)
* 7 = OR ABIERTA EN EL CLOUD
* 8 = OR FINALIZADA EN CLOUD
*/
export enum SiniwinOrStatus {
    SINESTADO = -1,
    LIBRE = 0,
    CERRADA = 1,
    OCUPADA = 2,
    PENDIENTE_ISPA_TOTAL = 3,
    PENDIENTE_GENERAR_ISPA_PARCIAL = 4,
    BLOQUEDA_RG = 5,
    APROBADA_PARA_FACTURAR_RG = 6,
    ABIERTA_CLOUD = 7,
    FINALIZADA_CLOUD = 8
}