import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Cita } from 'src/app/core/models/M_Cita';

@Component({
  selector: 'app-mecanico-marcar-pendiente-cita',
  templateUrl: './mecanico-marcar-pendiente-cita.component.html',
  styleUrls: ['./mecanico-marcar-pendiente-cita.component.css']
})
export class MecanicoMarcarPendienteCitaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MecanicoMarcarPendienteCitaComponent>, @Inject(MAT_DIALOG_DATA) public cita: M_Cita) {}

  ngOnInit(): void {
  }

}
