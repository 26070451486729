import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-estado-taller-card',
  templateUrl: './estado-taller-card.component.html',
  styleUrls: ['./estado-taller-card.component.css']
})
export class EstadoTallerCardComponent implements OnInit {
  @HostBinding('class') classes = 'estadoTaller';
  @Input() icon : string | undefined;
  @Input() text : string | undefined;
  @Input() num : number = 0;
  @Input() class_ : string | undefined
  constructor() { }

  ngOnInit(): void {
  }

}
