import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppointmentStatus, CommentManager, CustomColor, CustomDate, FormService } from '@sinigual/angular-lib';
import { HourPickerComponent } from 'src/app/components/hour-picker/hour-picker.component';
import { ImageToggleComponent } from 'src/app/components/image-toggle/image-toggle.component';
import { apiService } from 'src/app/core/api/api-service';
import { M_Cita, M_CitaInterface, M_Cita_AlreadyClient } from 'src/app/core/models/M_Cita';
import { M_Company } from 'src/app/core/models/M_Company';
import { CommentPedirCitaComponent } from '../editar-cita-cliente/Commentarios/comment-pedir-cita/comment-pedir-cita.component';

@Component({
  selector: 'app-create-cita-cliente',
  templateUrl: './create-cita-cliente.component.html',
  styleUrls: ['./create-cita-cliente.component.css', "./../cita-client.css"]
})
export class CreateCitaClienteComponent implements OnInit {
  alreadyClient: { client: boolean, email: boolean } = { client: false, email: false }
  clientSearched: boolean = false;
  cm = CommentManager;

  formAlreadyClient: UntypedFormGroup;
  formNoClient: UntypedFormGroup;
  diahoraForm: UntypedFormGroup;

  formContolClient: UntypedFormControl;

  citaCreared: M_Cita_AlreadyClient | M_Cita | undefined;
  userIp: string = "";
  showError: boolean = false;
  loaded: boolean = false;
  companyIdParam = 'companyId'
  company: M_Company | undefined;
  created: boolean = false;
  checkingClient: boolean = false;


  @ViewChild(HourPickerComponent) hourpciker: HourPickerComponent | undefined;
  @ViewChild(ImageToggleComponent) itoggle!: ImageToggleComponent;
  @ViewChildren(CommentPedirCitaComponent) comantarios!: QueryList<CommentPedirCitaComponent>;


  constructor(private apiS: apiService, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,
    private formService: FormService, private chdRef: ChangeDetectorRef) {
    this.formNoClient = this.formBuilder.group({
      name: ['', Validators.required],
      telf: ['', Validators.required],
      email: ['', Validators.required],
      matricula: ['', Validators.required],
      marca: ['', Validators.required],
      modelo: ['', Validators.required],
    });

    this.formAlreadyClient = this.formBuilder.group({
      plate: ['', Validators.required],
      dni: ['', Validators.required],
      email: [''],
    });

    this.diahoraForm = this.formBuilder.group({
      dia: ['', Validators.required],
      hora: ['', Validators.required],
    })

    this.formContolClient = new UntypedFormControl('', Validators.required);

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params[this.companyIdParam]) {
        this.searchCompany((params[this.companyIdParam]));
      }
      else {
        this.showError = true;
      }
    });
    this.loadIp();
  }


  /**Guarda la ip del usuario */
  loadIp() {
    fetch('https://api.ipify.org/?format=json')
      .then(res => {
        res.json().then(ipjson => {
          this.userIp = ipjson.ip;
        });
      })
      .catch(function (error) {
        console.log('error', error);
      });;
  }

  searchCompany(hash: string) {
    this.apiS.getCompanyInfoById(hash).then(res => {
      if (res instanceof M_Company) {
        this.company = res;
        this.apiS.getFirstDayRider(this.company.id!).then(res => {
          this.hourpciker!.setFirstDay(res, this.company!.id);
          this.defineCompanyColors()
          this.loaded = true;
        })
        this.hourpciker?.setUpHoliDays(this.company.id);
      }
      else {
        this.showError = true;
      }
    });
  }

  defineCompanyColors() {
    if (this.company) {
      document.documentElement.style.setProperty('--cc1', this.company!.getColor1().value);
      document.documentElement.style.setProperty('--cc11', this.company!.getColor1().contrastValue);
      document.documentElement.style.setProperty('--cc2', this.company!.getColor2().value);
      document.documentElement.style.setProperty('--cc22', this.company!.getColor2().contrastValue);
    }
  }

  makeCall() {
    this.citaCreared = this.getDataCitaCreate();
    console.log(this.citaCreared);
    this.apiS.createCitaCliente(this.citaCreared).then(response => {
      this.citaCreared!.hash = response;
      this.chdRef.detectChanges();
      this.created = true;
    });
  }

  /** Getters */
  getDataCitaCreate(): M_Cita | M_Cita_AlreadyClient {

    /** ALREADY CLIENT */
    if (this.itoggle.selected == 0) {
      let values = this.formAlreadyClient.getRawValue();
      console.log(values)
      let citaValues: any = {
        matricula: values.plate,
        dni: values.dni,
        mail: this.formAlreadyClient.get('email')?.valid ? values.email : '',
        tareas: this.cm.getCommentsCitaCliente(this.comantarios),
        control_1: this.userIp,
        control_2: window.navigator.userAgent,
        control_3: '--',
        fecha_cita: new CustomDate(this.hourpciker!.getDate()),
        company_id: this.company!.id!,
      }
      return new M_Cita_AlreadyClient(citaValues);
    }

    /** NO CLIENT */
    else {
      let values = this.formNoClient.value;
      let citaValues: M_CitaInterface = {
        matricula: values.matricula,
        marca: values.marca,
        modelo: values.modelo,
        nombre_cliente: values.name,
        telf: values.telf,
        mail: values.email,
        tareas: this.cm.getCommentsCitaCliente(this.comantarios),
        control_1: this.userIp,
        control_2: window.navigator.userAgent,
        control_3: '--',
        fecha_cita: new CustomDate(this.hourpciker!.getDate()),
        company_id: this.company!.id!,
        hash: '',
        estado_cita: AppointmentStatus.pendiente,
        cliente: 0
      }
      return new M_Cita(citaValues);
    }
  }


  onCompleateAlreadyClient() {

    var plate = this.formAlreadyClient.get('plate')!;
    var dni = this.formAlreadyClient.get('dni')!;

    console.log(plate.valid, dni.valid)

    if (this.formService.isOk(this.formAlreadyClient)) {
      this.clientSearched = false;
      this.checkingClient = true;
      this.apiS.searchDniAndPlate(plate.value, dni.value, this.company?.hash).then(res => {
        this.alreadyClient = res;
        if (this.alreadyClient.client) {
          plate.disable();
          dni.disable();
          this.formContolClient.patchValue({ isvalid: "true" })
        }
        if (res.email) {

        }
        this.clientSearched = true;
        this.checkingClient = false;
      })
    }
  }

  isAlreadyFormValid() {
  }

  get clientFound() {
    return this.clientSearched == true && this.alreadyClient.client == true;
  }

  get clientNotFound() {
    return this.clientSearched == true && this.alreadyClient.client == false;

  }
  getNewColor() {
    return new CustomColor();
  }

  getShowEmail() {
    if (this.itoggle){
      if (this.itoggle.selected == 0 && !this.alreadyClient.email && !this.formAlreadyClient.get('email')!.valid) {
        return false;
      }
    }
    return true;

  }
}
