<mat-form-field appearance="outline">
  <mat-label>Seleccionar lenguaje</mat-label>
  <mat-select #select value="{{translateS.currentLang}}" (selectionChange)="onChangeSelect($event.value)">
    <div *ngFor="let lang of translateS.getLangs()">
      <mat-option value="{{lang}}">
        <span style="margin-right: 5px;">{{ getTranslationName(lang) | translate }}</span>
        <span class="flag-icon flag-icon-{{ getFlagIcon(lang)}}"></span>
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>