<mat-accordion class="padding20" #accordion="matAccordion">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-icon class="topleft">content_paste</mat-icon>
            <mat-panel-title>
                Ficha tecnica
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="disabledFormRequired">
            <div class="df column section">
                <!--Datos obligatorios-->
                <app-vo-section-title [ok]="isDisabledFormOk()" [title]="'1 - Datos obligatorios de Siniwin'"
                    [text]="'Rellene los campos que faltan desde Siniwin'"></app-vo-section-title>
                <div class="expansion-panel-content">
                    <app-row-two>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('Matricula')">
                            <mat-label>Matrícula</mat-label>
                            <input matInput formControlName="Matricula">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('Kms')">
                            <mat-label>Km</mat-label>
                            <span class="suffix" matSuffix>Km</span>
                            <input matInput formControlName="Kms">
                        </mat-form-field>
                    </app-row-two>
                    <app-row-two>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('DesMarca')">
                            <mat-label>Marca</mat-label>
                            <input matInput formControlName="DesMarca">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('DesModelo')">
                            <mat-label>Modelo</mat-label>
                            <input matInput formControlName="DesModelo">
                        </mat-form-field>
                    </app-row-two>
                    <app-row-two>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('Bastidor')">
                            <mat-label>Chasis</mat-label>
                            <input matInput required formControlName="Bastidor">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('Cilindrada')">
                            <mat-label>Cilindrada</mat-label>
                            <input matInput formControlName="Cilindrada">
                        </mat-form-field>
                    </app-row-two>
                    <app-row-two>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('FeMatriculacion')">
                            <mat-label>Fecha matriculación</mat-label>
                            <input matInput formControlName="FeMatriculacion">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [class]="isSiniwinValid('ValorCompra')">
                            <mat-label>Precio de compra</mat-label>
                            <span class="suffix" matSuffix>€</span>
                            <input matInput formControlName="ValorCompra">
                        </mat-form-field>
                    </app-row-two>
                </div>
            </div>
        </form>



        <form [formGroup]="disabledFormOptional">
            <div class="df column section">
                <app-vo-section-title [ok]="true" [title]="'2 - Datos opcionales Siniwin'"
                    [text]="' '"></app-vo-section-title>
                <app-row-two>
                    <app-simple-input tipo="number" [requerido]="false" [form]="disabledFormOptional"
                        formCName="MesesGtia" label="Garantia"
                        [suffix]="this.form.get('grantia')?.value > 2 ? 'Meses' : 'Mes'">
                    </app-simple-input>
                    <mat-form-field appearance="outline">
                        <mat-label>Color</mat-label>
                        <input matInput formControlName="DesColor">
                    </mat-form-field>
                </app-row-two>
            </div>
        </form>


        <form [formGroup]="form">
            <div class="df column section">
                <!--Datos opcionales-->
                <app-vo-section-title [ok]="datosAdicionalesOk" [title]="'3 - Datos adicionales'"
                    [text]="'Faltan campos requeridos para poder publicar el anuncio'"></app-vo-section-title>
                <div class="expansion-panel-content">
                    <app-image-toggle [formAndField]="[form, 'moto_type']">
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m1.png"
                            text="Sport"></app-img-toggle-item>
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m2.png"
                            text="Touring"></app-img-toggle-item>
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m3.png"
                            text="Trail"></app-img-toggle-item>
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m4.png"
                            text="Scooter"></app-img-toggle-item>
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m5.png"
                            text="Offroad"></app-img-toggle-item>
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m6.png"
                            text="Custom"></app-img-toggle-item>
                        <app-img-toggle-item img="../../../../../assets/img/moto-types/m7.png"
                            text="Naked"></app-img-toggle-item>
                    </app-image-toggle>
                    <app-row-two>
                        <app-simple-input [maxLength]="4" [form]="form" formCName="moto_year" [hint]="'* Publicación'"
                            label="Año modelo" [requerido]="false">
                        </app-simple-input>

                        <mat-form-field appearance="outline">
                            <mat-label>Carnet</mat-label>
                            <mat-select formControlName="carnet_type">
                                <mat-option [value]="0">A</mat-option>
                                <mat-option [value]="1">A1</mat-option>
                                <mat-option [value]="2">A2</mat-option>
                                <mat-option [value]="3">B</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </app-row-two>
                    <app-row-two>
                        <app-simple-input [form]="form" formCName="potencia" label="Potencia" [suffix]="'KW'"
                            [requerido]="false"></app-simple-input>
                        <app-simple-input [form]="form" [requerido]="false" formCName="peso" label="Peso"
                            suffix="Kg"></app-simple-input>
                    </app-row-two>
                </div>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>