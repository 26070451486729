import { environment } from "src/environments/environment";
import { UserRoleEnum } from "../enums/UserRoleEnum";

/** Maintain control of which functionalities should be shown or hidden */
export const feature = {
    /** DEV environment or LOCAL environment */
    devOrLocal : environment.local || environment.dev,
    /** Tracking user actions */
    logRocket : environment.production,
    /** Activate Siniwin users */
    activateUsers : environment.local || environment.dev,
    /** Workload OR */
    workloadOr : environment.local || environment.dev,
    /** View all clients of a company */
    allClientAcces : environment.local || environment.dev,
    /** Coming soon fetures menu item */
    comingsoon : false
}

/** Most common array of roles for views */
export const viewRol = {
    gestorasesor : [UserRoleEnum.gestor, UserRoleEnum.asesor],
    NOTprogramming : [UserRoleEnum.gestor, UserRoleEnum.asesor, UserRoleEnum.mecanico]
}