<div [ngClass]="odd ? 'search-results-p odd':'search-results-p even'" @fade>
    <div class="search-results df" #searchresults>
        <div class="client-result">
            <app-datos-cliente (click)="goReception.goWithRider(rider)" [isSearchResult]="true" [clientData]="rider">
                <mat-card style="padding: 0px;">
                    <button (click)="goReception.goWithRider(rider)"  disableRipple mat-icon-button class="search-button">
                        <mat-icon style="color:#4a4a4a">arrow_forward_ios</mat-icon>
                    </button>
                </mat-card>
            </app-datos-cliente>
            <div class="mb-20"></div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>