import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'client-info-line',
  template: `<p>
              <span class="semi-bold">{{name}} : </span>
              <span [ngClass]="value? '' : 'sindatos'">{{value? value : 'Sin datos'}}</span>
            </p>`,
  styles:[".sindatos{font-style: italic;color: gray;}"]
})

export class DatosClienteLineComponent implements OnInit {
  @Input() name! : string;
  @Input() value! : string | undefined;
  @HostBinding('class') classes = 'clientInfoLine';
  constructor() {}
  ngOnInit(): void {}
}
