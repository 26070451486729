import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { apiService } from 'src/app/core/api/api-service';
import { M_User } from 'src/app/core/models/M_User';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {
  
  expectedValue = "Eliminar usuario"
  @ViewChild('inputRef') inputRef? : ElementRef ;
  constructor(public dialogRef: MatDialogRef<DeleteUserComponent>, @Inject(MAT_DIALOG_DATA) public usuario: M_User, private cdRef : ChangeDetectorRef, private apiS : apiService) {}

  ngOnInit(): void {}

  refresh(){
    this.cdRef.detectChanges();
  }

  makeCall(){
    this.apiS.deleteUser(this.usuario.id!).then(_resp=>{
      this.dialogRef.close(true);
    })
  }

  okDisabled() {
    return (this.inputRef?.nativeElement.value.toLocaleLowerCase() != this.expectedValue.toLocaleLowerCase());
  }

}
