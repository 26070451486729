import { Component} from '@angular/core';
import { RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-add-edited-dialog',
  templateUrl: './add-edited-dialog.component.html',
  styleUrls: ['./add-edited-dialog.component.css']
})
export class AddEditedDialogComponent {
  v = ViewPath;
  constructor(public routerS : RouterService) { }
}
