import { Component, Input, OnInit } from '@angular/core';
import { CustomColor } from '@sinigual/angular-lib';

@Component({
  selector: 'app-comment-pedir-cita',
  templateUrl: './comment-pedir-cita.component.html',
  styleUrls: ['./comment-pedir-cita.component.css']
})
export class CommentPedirCitaComponent implements OnInit {

  @Input() color! : CustomColor;
  @Input() image! : string;
  @Input() text! : string;
  checked = false;

  constructor() { }

  ngOnInit(): void {
  }

  check_(value : boolean){
    this.checked = value;
  }

  toggle(){
    this.checked = !this.checked;
  }

}
