import { Component, OnInit } from '@angular/core';
import { RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-taller',
  templateUrl: './taller.component.html',
  styleUrls: ['./taller.component.css']
})
export class TallerComponent implements OnInit {
  vp = ViewPath;
  showFiller = false;
  constructor(public routerS : RouterService) { }

  ngOnInit(): void {
  }
}
