<app-page-structure [masterClass]="'user'" pageTitle="USUARIOS" [autocompleteID]="'users'" searchLabel="Buscar usuario"
  searchOnboardingClass="onboarding-search-user" circleIcon="person_add" circleIconClass="onboarding-create-user"
  noDataCreateNew="Crear un nuevo usuario" (onNoDataAddNew)="openCreateUserDialog()" [data]="[]"
  [circleIcon]="'person_add'" [displayedHeader]="dh" [displayedColumns]="dc"
  [specialText]="     [undefined, undefined,  getRole,     getPlatform,         getStatus]"
  [specialClass]="    [undefined, undefined,  undefined,   undefined,           getStatusClass]" [card]="card"
  (onclickAddNew)="openCreateUserDialog()" [circularLetter]="true" [lordIcon]="lordIcon">
  <ng-template #lordIcon>
    <lord-icon src="https://cdn.lordicon.com/ajkxzzfb.json" trigger="loop" colors="primary:#faddd1,secondary:#2fbd96"
      state="hover-looking-around" delay="9999" style="width:50px;height:50px">
    </lord-icon>
  </ng-template>
  <ng-template #card>
    <app-card *ngIf="ps.showItem()" [cornerMatMenu]="deleteCorner" [contentLoaded]="true">
      <div class="df fdc jcc aic mb10i">

        <div *ngIf="au" class="absolute-left cp"
          [matTooltip]="ps.selected!.cloud ? 'Usuario de Sinicloud' : 'Usuario de Siniwin'">
          <mat-icon class="vam" [ngClass]="{'c_p' : ps.selected!.estado_activacion == null || ps.selected!.estado_activacion == ae.activated,
            'c_o' : ps.selected!.estado_activacion ==  ae.sended,
            'c_r' : ps.selected!.estado_activacion == ae.toActivate}">{{ps.selected!.cloud ? 'cloud' :
            'computer'}}</mat-icon>
        </div>

        <app-card-title style="margin-top: 30px;" [noMarginBottom]="true">{{ps.selected?.name}}</app-card-title>
        <app-circular-letter [showTooltip]="false" [big]="true" [showFullName]="false"
          [user]="ps.selected!"></app-circular-letter>
      </div>
      <button class="mb10i" mat-stroked-button (click)="openEditUserDialog(ps.selected!)">
        EDITAR
      </button>

      <app-card-subtitle *ngIf="wor" class="tac">DÍAS FESTIVOS</app-card-subtitle>
      <app-holidays-calendar *ngIf="wor" [userID]="ps.selected!.id" [selfSave]="true" [enabledFor]="enabledCalender"></app-holidays-calendar>
      <div *ngIf="au" [ngTemplateOutlet]="ps.selected!.cloud ? cloud : siniwin"></div>

    </app-card>
  </ng-template>
  <ng-template #deleteCorner>
    <button mat-icon-button [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #deleteMenu="matMenu">
      <mat-option style="height: 64px;" (click)="openDeleteUserDialog(ps.selected!)">
        <p class="nmb">Eliminar</p>
      </mat-option>
    </mat-menu>
  </ng-template>
</app-page-structure>

<ng-template #cloudtitle>
  <mat-icon></mat-icon>
</ng-template>

<ng-template #siniwintitle></ng-template>

<ng-template #cloud>
  <!-- IF IS CLOUD USER-->
  <div *ngIf="ps.selected!.user_platform == 'cloud'">

  </div>
</ng-template>

<ng-template #siniwin>
  <!-- IF IS SINIWIN USER-->
  <button class="mb10i" mat-flat-button color="primary" mat-stroked-button (click)="activateUser(ps.selected!)"
    *ngIf="ps.selected?.estado_activacion == ae.toActivate">
    <mat-icon class="mr5 c_w">send</mat-icon>
    ACTIVAR
  </button>
  <p *ngIf="ps.selected?.estado_activacion == ae.toActivate" class="tac c_t2">Se enviará un correo de activación al
    usuario</p>
  <p *ngIf="ps.selected?.estado_activacion == ae.sended" class="tac"><mat-icon class="c_o vam mr5">info</mat-icon>Se ha
    enviado el correo de activación al usuario</p>

</ng-template>