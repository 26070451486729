<div *ngIf="showError">
    <app-page-not-found [showReturn]="false"></app-page-not-found>
</div>

<app-card class="contents" [ngClass]="{'dn' : showError}">
    <div class="form">
        <div class="company-header">
            <img *ngIf="company!=undefined" class="test" src="{{company.company_logo}}">
            <p class="pedirCitaTitle">{{company?.company_name}}</p>
            <p class="pedirCitaSubtitle">PEDIR CITA PREVIA</p>
        </div>
        <div>
            <mat-stepper linear #stepper [ngClass]="{'hidden' : created}">
                <!-- DÍA Y HORA-->
                <mat-step [stepControl]="diahoraForm">
                    <ng-template matStepLabel>Día y hora</ng-template>
                    <p class="pedirCitaSubtitle">Seleccione día y hora</p>
                    <app-hour-picker [form]="diahoraForm" [isClientSide]="true"></app-hour-picker>
                    <div class="df jcfe">
                        <button [disabled]="!diahoraForm.valid" mat-button matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>¿Ya eres cliente?</ng-template>
                    <div class="step-contents">
                        <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
                        <p class="pedirCitaSubtitle tac">¿Ya eres cliente?</p>
                        <app-image-toggle #apit>
                            <app-img-toggle-item text="Si"></app-img-toggle-item>
                            <app-img-toggle-item text="No"></app-img-toggle-item>
                        </app-image-toggle>
                    </div>
                    <div class="df jcfe">
                        <button mat-button matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="apit.selected == 0 ? formContolClient: formNoClient"
                    errorMessage="Name is required.">
                    <ng-template matStepLabel>Tus datos</ng-template>


                    <!-- ES CLIENTE -->
                    <div *ngIf="apit.selected == 0">
                        <form [formGroup]="formAlreadyClient">
                            <p class="pedirCitaSubtitle">Tus datos</p>
                            <div class="df">
                                <mat-form-field class="w100" appearance="outline">
                                    <mat-label>Matrícula</mat-label>
                                    <input type="text" matInput placeholder="Introduce tu matrícula"
                                        formControlName="plate" required>
                                </mat-form-field>
                            </div>
                            <mat-form-field class="w100" appearance="outline">
                                <mat-label>DNI</mat-label>
                                <input type="text" matInput placeholder="Introduce tu DNI" formControlName="dni"
                                    required>
                            </mat-form-field>
                            <mat-progress-bar *ngIf="checkingClient" mode="indeterminate"></mat-progress-bar>
                            <button *ngIf="!alreadyClient.client" (click)="onCompleateAlreadyClient()"
                                class="mtb-20 mla mra" mat-raised-button type="button"
                                [ngStyle]="company ? company.getColor2().style : {'background-color': 'var(--gray)', 'color' : 'white'}"
                                style="display: flex;">
                                COMPROBAR DATOS
                            </button>
                            <div *ngIf="clientSearched">
                                <div *ngIf="clientFound" class="df jcc aic">
                                    <p class="nmb" style="text-align: center;"> Cliente encontrado</p>
                                    <mat-icon style="color : var(--p)">done</mat-icon>
                                </div>
                                <div *ngIf="clientNotFound" class="df jcc aic">
                                    <p class="nmb" style="text-align: center;"> No se ha encontrado ningún cliente con los datos introducidos</p>
                                    <mat-icon style="color : var(--r)">close</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="!alreadyClient.email && clientFound">
                                <p style="margin-top: 20px;">Introduzca su correo para poder gestionar la cita</p>
                                <app-email-input [form]="formAlreadyClient" formCName="email" class="w100">
                                </app-email-input>
                            </div>
                        </form>
                    </div>

                    <!-- NO ES CLIENTE-->
                    <div *ngIf="apit.selected == 1">
                        <form [formGroup]="formNoClient">
                            <div>
                                <p class="pedirCitaSubtitle">Tus datos</p>
                                <mat-form-field class="w100" appearance="outline">
                                    <mat-label>Nombre</mat-label>
                                    <input type="text" matInput placeholder="Introduce tu nombre" formControlName="name"
                                        required>
                                </mat-form-field>
                                <app-phone-input [form]="formNoClient" formCName="telf" class="w100"></app-phone-input>
                                <app-email-input [form]="formNoClient" formCName="email" class="w100">
                                </app-email-input>
                            </div>
                            <div class="mt20">
                                <p class="pedirCitaSubtitle">Datos del vehiculo</p>
                                <mat-form-field appearance="outline" class="w100">
                                    <mat-label>Matrícula</mat-label>
                                    <input matInput formControlName="matricula" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w100">
                                    <mat-label>Marca</mat-label>
                                    <input matInput formControlName="marca" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w100">
                                    <mat-label>Modelo</mat-label>
                                    <input matInput formControlName="modelo" required>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                    <div class="df jcfe">
                        <button [disabled]="apit.selected == 1 ? !formNoClient.valid : !formContolClient.valid"
                            mat-button matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Reparaciones</ng-template>
                    <div>
                        <p class="pedirCitaSubtitle">Reparaciones</p>
                        <span *ngFor="let comment of cm.getPedirCitaSection()">
                            <app-comment-pedir-cita [color]="company? company.getColor2() : getNewColor()"
                                text={{comment.text}} image={{comment.icon}}></app-comment-pedir-cita>
                        </span>

                        <p class="tac" style="margin-top: 20px">Si ya tienes todo listo, sólo tienes que hacer click en el botón de abajo para pedir tu cita</p>
                        <button (click)="makeCall()"
                            class="mtb-20 mla mra" mat-raised-button type="button"
                            [ngStyle]="company ? company.getColor2().style : {'background-color': 'var(--gray)', 'color' : 'white'}"
                            style="display: flex;">
                            PEDIR CITA
                        </button>
                    </div>
                </mat-step>
            </mat-stepper>
            <app-create-cita-client-email-send [citaCreared]="citaCreared" [showEmailIcon]="getShowEmail()" [ngClass]="{'hidden' : !created}">
            </app-create-cita-client-email-send>
        </div>
    </div>
</app-card>