import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { apiService } from 'src/app/core/api/api-service';
import { M_LoginResponse } from 'src/app/core/models/M_Login';
import { ProfileService } from 'src/app/views/profile/profileService';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService, ErrorDialogService, FormService, RoleService, RouterService, SessionService, StorageService } from '@sinigual/angular-lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild(MatStepper) stepper!: MatStepper;
  public loginForm: UntypedFormGroup;
  public forgotPassword: UntypedFormGroup;
  constructor(private sessionService: SessionService, private router: RouterService, private formBuilder: UntypedFormBuilder,
    private apiService: apiService, private errorDialog: ErrorDialogService, private profileService: ProfileService,
    private storageS: StorageService, private formService: FormService, private roleService: RoleService,
    private activeRoute: ActivatedRoute, private confirmDialogS: ConfirmDialogService) {
    this.loginForm = this.formBuilder.group({
      client_code: ['', [Validators.required]],
      company_code: ['', [Validators.required]],
      email: [''],
      password: ['', [Validators.required]],
    });

    this.forgotPassword = this.formBuilder.group({
      client_code: ['', [Validators.required]],
      company_code: ['', [Validators.required]],
      email: [''],
    });

  }

  ngOnInit(): void {
    this.activeRoute.queryParams
      .subscribe(params => {
        let user_code = params["user_code"];
        if (user_code) {
          this.loginForm.patchValue({ client_code: user_code })
        }
        let company_code = params["company_code"]
        if (company_code) {
          this.loginForm.patchValue({ company_code: company_code })
        }
      }
      );

  }

  processResponse(resp: M_LoginResponse) {
    if (resp != null) {
      if (resp.access_token && resp.role) {
        this.storageS.clear();
        this.sessionService.setToken(resp.access_token);
        this.roleService.setRole(resp.role);
        /**Petición get para obtener los datos de perfil y guardarlos en caché */
        this.profileService.refresh()
        this.router.goTo(ViewPath.dashboard);
      }
      else {
        this.errorDialog.showError("Error", "Algo salió mal, intentelo de nuevo mas tarde");
      }
    }
  }

  makeCall(): void {
    if (this.formService.isOk(this.loginForm)) {
      this.apiService.doLogin(this.loginForm.value).then(response => this.processResponse(response));
    }
    else {
      this.errorDialog.showError("Formulario incompleto", this.isEmailBadFormetted() ? "Introduce una dirección de correo válida" : "Completa todos los campos")
    }
  }

  sendRecoverPassword() {
    if (this.formService.isOk(this.forgotPassword)) {
      this.apiService.forgotPassword(this.forgotPassword.value).then(_resp => {
        this.confirmDialogS.show({
          title: "SOLICITUD PROCESADA CORRECTAMENTE",
          showCancel:false,
          confirmTxt: "OK",
          body: "Si los datos introducidos son correctos, recibirá un correo para poder establecer una nueva contraseña",
        }).afterClosed().subscribe(res => {
          this.goLogin()
        })
      })
    }
  }

    isEmailBadFormetted(){
      const emailInput = this.loginForm.get('email');
      return (emailInput?.errors != undefined && emailInput?.errors['email'] != undefined && emailInput.dirty && emailInput.touched);
    }

    goForgetPassword(){
      this.stepper.next();
    }

    goLogin(){
      this.stepper.previous();
    }

    /**Evitar que el stepper marque los formularios en rojo */
    stepChanged(stepper: any){
      stepper.selected.interacted = false;
    }
  }