import { ChangeDetectorRef, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ImgToggleItemComponent } from './img-toggle-item/img-toggle-item.component';

@Component({
  selector: 'app-image-toggle',
  templateUrl: './image-toggle.component.html',
  styleUrls: ['./image-toggle.component.css']
})
export class ImageToggleComponent implements OnInit {
  @ContentChildren(ImgToggleItemComponent, { descendants: true }) items!: QueryList<ImgToggleItemComponent>;
  @Input() dimensions?: number;
  @Input() multiple?: boolean = false;
  @Input() default: boolean = true;
  @Input() blackWitheFilter?: boolean = false;
  @Input() formAndField: [fg: FormGroup, formField: string] | undefined = undefined;
  height = 0;
  constructor(private chdRef: ChangeDetectorRef) {
    this.detectFormUpdates();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initComponenet();
  }

  ngAfterViewChecked() {
    this.chdRef.detectChanges();
  }

  private initComponenet() {
    this.setIndex();
    this.initDefault();
    this.initDimensions();
    this.initBlackWitheFilter();
  }

  private initDimensions() {
    if (this.dimensions != undefined) {
      this.items.forEach(i => {
        i.setDimensions(this.dimensions!);
      })
    }
  }

  private initBlackWitheFilter() {
    if (this.blackWitheFilter) {
      this.items.forEach(i => {
        i.blackWitheFilter = this.blackWitheFilter!;
      })
    }
  }

  private initDefault() {
    let found = false;
    this.items.forEach(i => {
      if (i.startSelected) {
        found = true;
      }
    })


    /** Check if the form already have a value */
    if (this.formAndField) {
      var value = this.formAndField[0].get(this.formAndField[1])!.value;
      if (typeof value == "number") {
        this.setSelected(value);
      }
      else {

      }
    }
    else {
      if (!found && this.default) {
        this.setSelected(0);
      }
    }
  }


  private detectFormUpdates() {
    if (this.formAndField) {
      var control = this.formAndField[0].get(this.formAndField[1]);
      control?.valueChanges.subscribe(v => {
        console.log("Somthing change")
        if (typeof v == "number") {
          this.setSelected(v)
        }
      })
    }
  }

  /** From this component to the form */
  private patchFormValue(v: number) {
    if (this.formAndField) {
      var keyvalye: any = {};
      keyvalye[this.formAndField[1]] = v;
      this.formAndField[0].patchValue(keyvalye, { emitEvent: false })
    }
  }

  private setIndex() {
    this.items.forEach((i, index) => {
      i.setIndex(index) // --> Set index
    })
  }

  setSelected(item: number) {
    if (!this.multiple) {
      this.items.forEach(i => {
        i.setSelected(false);
      })
    }
    if (this.items.get(item) != undefined) {
      if (this.multiple) {
        this.items.get(item)?.setSelected(!this.items.get(item)?.selected);
      }
      else {
        this.items.get(item)?.setSelected(true);
      }
    }
    this.patchFormValue(item);
    console.log(item);
  }

  get somethingSelected() {

    var selecteds = this.selected;

    if (selecteds == undefined) {
      return false;
    }

    if (typeof selecteds == "number") {
      return true;
    }
    else {
      if (selecteds != undefined) {
        return selecteds.length != 0;
      }
      else {
        return false;
      }
    }
  }

  get selected() {
    if (this.items) {
      if (this.multiple) {
        let selected = [];
        for (let i = 0; i < this.items.length; i++) {
          if (this.items.get(i)?.selected) {
            selected.push(this.items.get(i)?.index);
          }
        }
        return selected;
      }
      else {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items.get(i)?.selected) {
            return this.items.get(i)?.index;
          }
        }
      }
    }
    return 0;
  }
}
