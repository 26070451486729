<app-close-dialog-button></app-close-dialog-button>
<div class="mt10"></div>
<app-card-title>IMPUTAR TIEMPO MANUALMENTE</app-card-title>

<div>
    <app-card-subtitle>
        {{isOperario?
        'Añade el tiempo que desees imputar' :
        'Seleccione un usuario con el que imputar el tiempo'
        }}
    </app-card-subtitle>

    <p class="c_t2" *ngIf="!isOperario">Solo los usuarios de Siniwin pueden imputar tiempo</p>
</div>


<div *ngIf="!isOperario" class="df fww mt10 mb10">
    <p class="c_t2" *ngIf="data.workers.length == 0">Tu compañía no tiene operarios activados</p>
    <button mat-button *ngFor="let u of data.workers" [ngClass]="{'selected' : selected?.id == u.id}"
        (click)="selected = u">
        <app-circular-letter [user]="u" [showTooltip]="false"></app-circular-letter>
    </button>
</div>

<mat-form-field class="w100" appearance="outline">
    <mat-label>Tiempo</mat-label>
    <input type="time" matInput [formControl]="fc">
</mat-form-field>


<div class="df jcc">
    <button mat-button class="c_b" [disabled]="!isAddTimeEnabled" (click)="closeWithTime()">
        Añadir tiempo
    </button>
</div>