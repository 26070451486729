<!--ROW-->
<div [ngClass]="isColumn ? '': 'df jcc'" (click)="goToEdit()">
    <div matRipple class="mat-elevation-z8 cp"
        [ngClass]="{'cardColumnParent' : isColumn, 'cardRowParent' : !isColumn, 'sold' : bike.sold}">
        <div
            [ngClass]="{'arrowsParentColumn' : isColumn, 'arrowsParentRow' : !isColumn, 'arrowParentNoAdd': !bike.isPublished}">

            <div class="arrows left" (click)="goLeft($event)" [ngClass]="leftDisabled()" *ngIf="bike.isPublished">
                <mat-icon>arrow_back_ios</mat-icon>
            </div>

            <div class="arrows rigth" (click)="goRigth($event)" [ngClass]="rigthDisabled()" *ngIf="bike.isPublished">
                <mat-icon>arrow_forward_ios</mat-icon>
            </div>
            <div *ngIf="bike.add != undefined && bike.isPublished" style="height: 100%;">
                <div class="sold-banner" *ngIf="bike.sold">
                    <p *ngIf="bike.sold">VENDIDA</p>
                </div>
                <img *ngIf="bike.add.hasPictures()" class="pointer" [ngClass]="isColumn ? 'image' : 'imageColumn'"
                    src={{bike.add.fotos[index].src}}>
                <img *ngIf="!bike.add.hasPictures()" class="pointer" [ngClass]="isColumn ? 'image' : 'imageColumn'"
                    src="./assets/img/no-image.png">
                <p *ngIf="bike.add.hasPictures()" class="imglabel">
                    {{index + 1}}/{{bike.add.fotos.length}}
                </p>
            </div>

            <div class="delete">
                <button mat-icon-button [matMenuTriggerFor]="deleteMenu" prevent>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #deleteMenu="matMenu">
                    <button mat-menu-item (click)="openDeleteDialog()" style="min-height: 64px;">
                        Eliminar
                    </button>
                </mat-menu>
            </div>

            <div class="unpublished" *ngIf="!bike.isPublished">
                <p>No publicada</p>
                <mat-icon>unpublished</mat-icon>
            </div>
            <div class="published cp" *ngIf="bike.isPublished && !bike.sold">
                <mat-icon [matTooltip]="'Publicada'">check_circle</mat-icon>
            </div>

            <div class="published cp" *ngIf="bike.isPublished && bike.sold">
                <mat-icon [matTooltip]="'Vendida'">attach_money</mat-icon>
            </div>
        </div>
        <div [ngClass]="{'infoColumn' : isColumn,  'info' : !isColumn, 'mtnoadd' : !bike.isPublished}">
            <div class="columnCenter" [ngClass]="{'jcci': !bike.isPublished}">
                <div class="precio" *ngIf="bike.isPublished">
                    <p class="nmb lighter"></p>
                    <p class="sc x-large semi-bold">{{bike.add && bike.isPublished && bike.add.precio ?
                        bike.add.precio.toLocaleString('es') + "€" : "Sin precio"}} </p>
                </div>
                <div class="name">
                    <p class="large bold one-line">{{bike.add && bike.isPublished ? bike.add.titulo : bike.DesModelo}}</p>
                </div>
                <div class="chips">
                    <p class="lighter" [ngClass]="{'nmb' : !bike.isPublished}">{{bike.Matricula}}</p>
                    <p class="lighter" [ngClass]="{'nmb' : !bike.isPublished}">{{bike.Kms + "Km"}}</p>
                    <p class="lighter" [ngClass]="{'nmb' : !bike.isPublished}">{{bike.TiMotor == motor.gasolina ?
                        'Gasolina' : 'Eléctrico'}}</p>
                </div>
                <mat-divider *ngIf="bike.add && bike.isPublished"></mat-divider>
                <div class="otherinfo lighter" *ngIf="displayMode == dm.row && bike.add && bike.isPublished">
                    <p class="nmb">Publicada en :</p>
                </div>
                <div class="df" *ngIf="bike.add && bike.isPublished">
                    <img *ngFor="let p of bike.add.id_services" class="providersLogo" [src]="p.image">
                    <p *ngIf="bike.add.id_services.length == 0" class="c_t2 fw400 nmb mt10">Sin servicios</p>
                </div>
            </div>
        </div>
    </div>
</div>