<app-card>
    <app-card-title>DATOS DEL NUEVO CLIENTE</app-card-title>
    <form class="newClientForm" [formGroup]="form" >
        <div class="df jcc">
            <mat-form-field class="padding5 input-full-width" appearance="outline">
                <mat-label>Matrícula</mat-label>
                <input autocomplete="off" matInput formControlName="license_plate">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="padding5 input-full-width" appearance="outline">
                <mat-label>Nombre y apellidos</mat-label>
                <input autocomplete="off" matInput formControlName="complet_name">
            </mat-form-field>
            <app-phone-input [disableAutocomplete]="true" class_="padding5 flex_" [form]="form" formCName="telf"></app-phone-input>
        </div>
        <div>
            <app-dni-input #dni (onFocusOut)="checkDNI(dni.value)" [disableAutocomplete]="true"   class_="padding5 flex_" [form]="form" formCName="nif"></app-dni-input>
            <app-email-input [disableAutocomplete]="true" class_="padding5 flex_" [form]="form" formCName="email"></app-email-input>
        </div>
        <div>
            <mat-form-field class="padding5 input-full-width" appearance="outline">
                <mat-label>Marca</mat-label>
                <input autocomplete="off" matInput formControlName="marca">
            </mat-form-field>
            <mat-form-field class="padding5 input-full-width" appearance="outline">
                <mat-label>Modelo</mat-label>
                <input autocomplete="off" matInput formControlName="modelo">
            </mat-form-field>
        </div>
    </form>
</app-card>