import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-subtitle',
  templateUrl: './section-subtitle.component.html',
  styleUrls: ['./section-subtitle.component.css']
})
export class SectionSubtitleComponent implements OnInit {

  @Input() subtitle : string  ="";
  @Input() justify? : boolean;
  @Input() first? : boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
