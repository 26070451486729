import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogService, RouterService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { preRider } from '../crear-recepcion.component';


/**
 * [Component]
 * Éste componenete se muestra en el caso de intentar hacer una recpeción activa y que la matrícula introducita no exista.
 * El componente padre, se encarga de gestionar los valores que se introducen en este formulario.
 * Si nos llega el @Input de matrícula, la añadimos por defecto a la pantalla
 */

@Component({
  selector: 'app-crear-datos-cliente',
  templateUrl: './crear-datos-cliente.component.html',
  styleUrls: ['./crear-datos-cliente.component.css']
})
export class CrearDatosClienteComponent implements OnInit {
  @Input() preRider?: preRider;
  public form: UntypedFormGroup;
  v = ViewPath;
  constructor(private formBuilder: UntypedFormBuilder, private apiS: apiService, private d: MatDialog, private routerS: RouterService) {
    this.form = this.formBuilder.group({
      nif: ['', [Validators.required]],
      license_plate: ['', [Validators.required]],
      complet_name: ['', [Validators.required]],
      telf: [''],
      email: [''],
      marca: ['', [Validators.required]],
      modelo: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.preRider) {
      this.form.patchValue({ license_plate: this.preRider?.matricula });
      this.form.patchValue({ complet_name: this.preRider?.nombre });
      this.form.patchValue({ telf: this.preRider?.telef });
      this.form.patchValue({ email: this.preRider?.mail });
      this.form.patchValue({ marca: this.preRider?.marca });
      this.form.patchValue({ modelo: this.preRider?.modelo });
    }
  }

  checkDNI(val: string) {
    this.apiS.checkDni(val).then(res => {
      if (res == true) {
        this.d.open(ErrorDialogService, {
          disableClose: true,
          data: {
            title: "Usuario ya existente",
            message: ["En este caso, la recepción se debe efectuar desde Siniwin"],
            icon: "error_outline",
            buttonAction: () => this.goDashboard()
          },
        });
      }
    })
  }

  goDashboard() {
    this.routerS.goTo(this.v.dashboard)
  }
}
