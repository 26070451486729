<div class="df column mat-card pricing-card wrap" [ngClass]="{'best': data.destacado}">
    <div *ngIf="!data.destacado">
        <p class="large g nmb semi-bold">{{data.title}}</p>
    </div>
    <div class="df column mb-20">
        <div *ngIf="data.destacado" class="df" style="justify-content: space-between;">
            <div>
                <p class="large g nmb">{{data.title}}</p>
            </div>
            <div class="popular semi-bold">
                <p class="nmb">El más popular</p>
            </div>
        </div>
    </div>
    <div class="mb-20">
        <p class="lighter">{{data.desc}}</p>
    </div>
    <div class="mb-20">
        <p><span class="xx-large">{{data.price}}€</span> <span class="lighter g">/mes</span></p>
        <p class="lighter">{{data.desc2}}</p>
    </div>
    <button class="scb seleccionar" mat-raised-button>OBTENER</button>
    <div class="mb-20"></div>
    <mat-divider></mat-divider>
    <div class="mb-20"></div>
    <div>
        <p class="semi-bold">QUÉ INCLUYE</p>
        <div *ngFor="let pro of data.pros">
            <lib-icon-text icon="done" iconColor="var(--sinigualColor)">{{pro}}</lib-icon-text>
        </div>
    </div>
</div>