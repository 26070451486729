<!--Si la url introducida no existe-->
<div *ngIf="showError">
    <app-page-not-found [showReturn]="false"></app-page-not-found>
</div>

<!--Si la url introducida existe-->
<div [ngClass]="company == undefined? 'hidden' : ''" class="container">
    <div class="custom-shape-divider-bottom-1648120091">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path [attr.fill]="company? company.getColor1().value: 'var(--gray)'"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"></path>
        </svg>
    </div>
    <img *ngIf="company!=undefined" class="test" src="{{company.company_logo}}">
    <p class="pedirCitaTitle">{{company?.company_name}}</p>
    <p class="pedirCitaSubtitle" *ngIf="!succesEdit">DETALLES DE SU CITA</p>


    <!-- Si la cita ha sido editada -->
    <div class="succes-edit" *ngIf="succesEdit">
        <p class="pedirCitaSubtitle">SU CITA HA SIDO MODIFICADA</p>
        <div style="margin-bottom: 20px;"></div>
        <app-card-title>¡HASTA PRONTO!</app-card-title>
        <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
        <lord-icon src="https://cdn.lordicon.com/wsaypobn.json" trigger="loop" delay="3333"
            colors="outline:#121331,primary:#4bb3fd,secondary:#fae6d1" style="width:250px;height:250px">
        </lord-icon>
    </div>

    <!-- Si la cita aún no se ha editado -->
    <div [ngClass]="succesEdit? 'hidden' : ''">
        <!-- Si el estao de cita es diferente de 0-->
        <div *ngIf="cita?.estado_cita != 0" class="citaEditInfo">
            <mat-divider></mat-divider>
            <mat-card>
                <p>Hola de nuevo <span class="semi-bold">{{cita?.nombre_cliente}}</span></p>
                <p *ngIf="cita?.estado_cita == 1">Parece ser que ya has realizado la cita.</p>
                <p *ngIf="cita?.estado_cita == 2">Parece ser que tu cita ha sido cancelada.</p>
                <p>Puedes hacer <span class="textButton" (click)="goCrearCita()">click aqui</span> si quieres pedir otra
                    cita.</p>
            </mat-card>
        </div>
        <!--Solo se muestra si es creación de cita o si estamos editando la cita y su estado es 'pendiente'-->
        <form *ngIf="cita?.estado_cita == 0" [formGroup]="form" (ngSubmit)="makeCall()">
            <div class="pedirCitaForm">
                <div>
                    <p class="pedirCitaSubtitle">Fecha y hora</p>
                    <app-hour-picker [form]="form" [isEdit]="true" [isClientSide]="true"></app-hour-picker>
                    <!-- Already client -->
                </div>
                <div>
                    <p class="pedirCitaSubtitle">Tus datos</p>
                    <mat-form-field class="my-form-field" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input type="text" matInput placeholder="Introduce tu nombre" formControlName="name" required>
                    </mat-form-field>
                    <app-phone-input class_='input-full-width' [form]="form" formCName="telf"></app-phone-input>
                    <app-email-input class_='input-full-width' [form]="form" formCName="email"></app-email-input>
                </div>
                <div>
                    <p class="pedirCitaSubtitle">Datos del vehiculo</p>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Matrícula</mat-label>
                        <input matInput formControlName="matricula" required>
                    </mat-form-field>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Marca</mat-label>
                        <input matInput formControlName="marca" required>
                    </mat-form-field>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <mat-label>Modelo</mat-label>
                        <input matInput formControlName="modelo" required>
                    </mat-form-field>
                </div>
                <div>
                    <p class="pedirCitaSubtitle">Reparaciones</p>
                    <span *ngFor="let comment of cm.getPedirCitaSection()">
                        <app-comment-pedir-cita [color]="company? company.getColor2() : getNewColor()"
                            text={{comment.text}} image={{comment.icon}}></app-comment-pedir-cita>
                    </span>
                </div>
            </div>
            <button class="mtb-20 pedirCitaBtn" mat-raised-button type="submit"
                [ngStyle]="company ? company.getColor2().style : {'background-color': 'var(--gray)', 'color' : 'white'}">
                GUARDAR CITA
            </button>
        </form>
    </div>


</div>

<div *ngIf="!loaded && !showError">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>