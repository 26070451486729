import { AppointmentStatus, CommentManager, CustomDate, M_Parser, match, parseOBJ } from "@sinigual/angular-lib";
import { AppointmentInterface } from "@sinigual/angular-lib/lib/interface/AppointmentInterface";


export interface M_CitaInterface {
    matricula: string;
    marca: string;
    modelo: string;
    nombre_cliente: string;
    telf: string;
    mail: string;
    tareas: string | null;
    control_1: string;
    control_2: string;
    control_3: string;
    fecha_cita: CustomDate;
    company_id: number | undefined;
    hash: string;
    estado_cita: AppointmentStatus;
    cliente: number;
}

export class M_Cita_AlreadyClient implements M_Parser {
    matricula: string;
    dni: string;
    mail: string;
    tareas: string | null;
    control_1: string;
    control_2: string;
    control_3: string;
    fecha_cita: CustomDate;
    hash: string = '';
    company_id: number | undefined;
    estado_cita: AppointmentStatus = AppointmentStatus.pendiente;
    needsToBeParsed: boolean = true;

    constructor(data: any) {
        this.matricula = data.matricula;
        this.mail = data.mail;
        this.dni = data.dni;
        this.tareas = data.tareas;
        this.control_1 = data.control_1;
        this.control_2 = data.control_2;
        this.control_3 = data.control_3;
        this.fecha_cita = new CustomDate(data.fecha_cita);
        this.company_id = data.company_id;
    }

    parse(): any {
        let obj: any = parseOBJ(this, "hash");
        obj['fecha_cita'] = this.fecha_cita.dataBaseFormat;
        return obj;
    }
}

export class M_Cita implements M_CitaInterface, M_Parser, AppointmentInterface {
    cita = true;
    needsToBeParsed: boolean = true;
    id: number;
    matricula: string;
    marca: string;
    modelo: string;
    nombre_cliente: string;
    telf: string;
    mail: string;
    tareas: string | null;
    control_1: string;
    control_2: string;
    control_3: string;
    fecha_cita: CustomDate;
    company_id: number | undefined;
    company_hash: string | undefined;
    hash: string;
    estado_cita: AppointmentStatus;
    cliente: number;

    constructor(data: M_CitaInterface | any) {
        this.id = data.id
        this.matricula = data.matricula;
        this.marca = data.marca;
        this.modelo = data.modelo;
        this.nombre_cliente = data.nombre_cliente;
        this.telf = data.telf;
        this.mail = data.mail;
        this.tareas = data.tareas;
        this.control_1 = data.control_1;
        this.control_2 = data.control_2;
        this.control_3 = data.control_3;
        this.fecha_cita = new CustomDate(data.fecha_cita);
        this.company_id = data.company_id;
        this.hash = data.hash;
        this.estado_cita = data.estado_cita ? data.estado_cita : AppointmentStatus.pendiente;
        this.cliente = data.cliente;
        this.company_hash = data.company_hash;
    }
    get clientphone(): string | undefined {
        return this.telf;
    }
    get clientemail(): string | undefined {
        return this.mail;
    }
    set setStatus(status: AppointmentStatus) {
        this.estado_cita = status;
    }

    get status() {
        return this.estado_cita;
    }

    get isClient() {
        return this.cliente == 1;
    }

    get canRA() {
        return this.isClient;
    }

    get reparations() {
        if (this.tareas){
            var c = this.tareas.split("#,#");
            for (let i = 0; i < c.length; i++) {
                c[i] = c[i] .replace(/\#/gi, "")
            }
            return c;
        }
        return [];
    }

    clientNif(): string {
        return "";
    }
    vehicleLicense(): string {
        return this.matricula;
    }
    vehicleName(): string {
        var mm = this.getMarcaModelo();
        return mm ? mm : "";
    }

    get appointment_date(): CustomDate {
        return this.fecha_cita;
    }
    set day(v: CustomDate) {
        this.fecha_cita = v;
    }

    getMarcaModelo() {
        if (this.marca && this.modelo) {
            return this.marca + " " + this.modelo;
        }
        else {
            return undefined
        }
    }

    parse(): any {
        let obj: any = parseOBJ(this, "cliente");
        obj['fecha_cita'] = this.fecha_cita.dataBaseFormat;
        return obj;
    }

    get plate(): string {
        return this.matricula;
    }
    get brand(): string {
        return this.marca;
    }

    clientName(): string {
        return this.nombre_cliente;
    }

    defaultSearchFilter(val: string): boolean {
        return match(val, this.clientName(), this.brand, this.plate, this.fecha_cita.value.toString(), this.id.toString());
    }
}