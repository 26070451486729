<app-page-structure [masterClass]="'client'" pageTitle="CLIENTES" [autocompleteID]="'clients'"
    searchLabel="Buscar cliente" searchOnboardingClass="onboarading-clients-search" circleIcon="person_add"
    circleIconClass="onboarding-create-client" [addNewView]="v.recepcion" [data]="[]"
    [displayedHeader]=" ['Nombre',  'NIF', 'Matricula',  'Marca', 'Modelo', 'Email']"
    [displayedColumns]="['Nombre1', 'Nif', 'Matricula',  'Marca', 'MdlTaller', 'Mail1p']"
    [specialText]="[undefined, undefined, undefined, undefined, undefined]"
    [specialClass]="[undefined, undefined, undefined, undefined, undefined]" [card]="card" [canAddNew]="false"
    [lordIcon]="lordIcon">
    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/qcoaeqfn.json" trigger="loop"
            colors="outline:#121331,primary:#3a3347,secondary:#f4f19c,tertiary:#66a1ee,quaternary:#794628,quinary:#faddd1,senary:#ee6d66,septenary:#faddd1,octonary:#2fbd96,nonary:#faddd1,denary:#ebe6ef"
            delay="60000" style="width:50px;height:50px">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <app-card-title [noMarginBottom]="true">{{ps.selected?.Nombre1}}</app-card-title>

            <app-card-subtitle>VEHÍCULO</app-card-subtitle>

            <div *ngIf="ps.selected!.Matricula; else noData">
                <lib-icon-text [iconAlign]="'start'" icon="two_wheeler">
                    <span class="clicktext" style="font-size: 15px;">{{ps.selected!.getName()}}</span>
                    <span class="c_t2 ml10 mr10">|</span>
                    <span>{{ps.selected!.Matricula}}</span>
                </lib-icon-text>
            </div>
            <ng-template #noData>
                <p class="c_t2">Sin vehículo</p>
            </ng-template>

            <app-card-subtitle>ÚLTIMA RECEPCIÓN</app-card-subtitle>
            <div>
                <p *ngIf="ps.selected!.FeUltRepa">
                    Última recepcion efectuada el día
                    <span>{{ps.selected!.FeUltRepa.shortFormat}}</span>
                </p>

                <p *ngIf="!ps.selected!.FeUltRepa" class="c_t2">
                    Todavía no se han efectuado recepciones.
                </p>
            </div>
            <button mat-stroked-button [disabled]="!ps.selected?.Matricula"
                (click)="goNewReception.goWithRider(ps.selected!)" class="mb10i">
                <mat-icon class="material-symbols-outlined">assignment_add</mat-icon>
                NUEVA RECEPCIÓN
            </button>
            <button mat-stroked-button [disabled]="!ps.selected?.Matricula"
                (click)="goNewAppointment(ps.selected!)">
                <mat-icon class="material-symbols-outlined">calendar_add_on</mat-icon>
                NUEVA CITA
            </button>

            <app-card-footer>
                <app-whatsapp [phone]="ps.selected!.hasPhone()"></app-whatsapp>
                <app-phone-button [phone]="ps.selected!.hasPhone()"></app-phone-button>
                <app-email-button [email]="ps.selected!.hasMail()"></app-email-button>
            </app-card-footer>

        </app-card>
    </ng-template>
</app-page-structure>