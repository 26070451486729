import { Component, OnInit } from '@angular/core';
import { PricingCardData } from './pricing-card/pricing-card.component';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  readonly pricingCards : PricingCardData[] = [];
  private basic = new PricingCardData(
    "Básico", 
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    25, 
    false,
    "Lorem ipsum dolor",
    "Integer finibus fringilla justo quis venenatis.");

  private estandar = new PricingCardData(
    "Estándar", 
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    50, 
    true,
    "Lorem ipsum dolor",
    "Lorem ipsum dolor",
    "Integer finibus fringilla justo quis venenatis.");

  private avanzado = new PricingCardData(
    "Avanzado", 
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    75, 
    false,
    "Lorem ipsum dolor",
    "Lorem ipsum dolor",
    "Lorem ipsum dolor",
    "Integer finibus fringilla justo quis venenatis.");

  private premium = new PricingCardData(
    "Premium", 
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus fringilla justo quis venenatis.",
    100, 
    false,
    "Lorem ipsum dolor",
    "Lorem ipsum dolor",
    "Lorem ipsum dolor",
    "Lorem ipsum dolor",
    "Integer finibus fringilla justo quis venenatis.");

  constructor() {
    this.pricingCards.push(this.basic, this.estandar, this.avanzado, this.premium);
  }

  ngOnInit(): void {
  }

}
