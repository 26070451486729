import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuscdorMatriculaComponent } from '../views/buscdor-matricula/buscdor-matricula.component';
import { CrearRecepcionComponent } from '../views/crear-recepcion/crear-recepcion.component';
import { LoginComponent } from '../views/login/login.component';
import { CrearCitaComponent } from '../views/citas/Mecanico/crear-cita/crear-cita.component';
import { EditarCitaClienteComponent } from '../views/citas/Rider/editar-cita-cliente/editar-cita-cliente.component';
import { HistorialRecepcionesComponent } from '../views/hisotrial-recepciones/historial-recepciones.component';
import { ProfileComponent } from '../views/profile/profile.component';
import { DashboardComponent } from '../views/dashboard/dashboard.component';
import { UsersComponent } from '../views/users/users.component';
import { ChangePasswordComponent } from '../views/change-password/change-password.component';
import { UserRoleEnum } from '../core/enums/UserRoleEnum';
import { AuthGuardGuard, PageNotFoundComponent, Views } from '@sinigual/angular-lib';
import { LanzaderaComponent } from '../views/lanzadera/lanzadera.component';
import { EditAddComponent } from '../views/edit-add/edit-add.component';
import { ConfigurationComponent } from '../views/configuration/configuration.component';
import { PricingComponent } from '../views/pricing/pricing.component';
import { CreateCitaClienteComponent } from '../views/citas/Rider/create-cita-cliente/create-cita-cliente.component';
import { ListaCitasComponent } from '../views/lista-citas/lista-citas.component';
import { feature, viewRol } from '../core/features-controller/FeaturesController';
import { ImputarTiempoOrComponent } from '../views/imputar-tiempo-or/imputar-tiempo-or.component';
import { CargaTallerViewComponent } from '../carga-taller-view/carga-taller-view.component';
import { CloudTicketsComponent } from '../views/cloud-tickets/cloud-tickets.component';
import { ComingSoonComponent } from '../views/coming-soon/coming-soon.component';
import { ClientsComponent } from '../views/clients/clients.component';
import { DeactivateGuard } from '../guards/deactivate.guard';

/** Feactures */
var f = feature;
/** View-Rol */
var vr = viewRol;

export const ViewPath = {

  login :               new Views("login",              "Login",                      true), //Cliente
  pedirCita :           new Views("pedircita",          "Pedir Cita",                 true), //Cliente
  editarCita :          new Views("editarcita",         "Editar Cita",                true), //Cliente
  pageNotFound :        new Views("error",              "Página no encontrada",       true), //Cliente
  changePassword :      new Views("changepassword",     "Cambiar contraseña",         true), //Cliente

  dashboard :           new Views("",                   "Dashboard",                  false),
  profile :             new Views("profile",            "Perfil",                     false),
  clients :             new Views("clients",            "Clientes",                   false, vr.NOTprogramming),
  recepcion :           new Views("historialrecepcion", "Recepción",                  false, vr.NOTprogramming),

  crearRecepcion :      new Views("crearrecepcion",     "Crear recepción",            false, vr.NOTprogramming),
  crearCita :           new Views("crearcita",          "Crear cita",                 false, vr.NOTprogramming),
  listacitas :          new Views("listacitas",         "Próximas citas",             false, vr.NOTprogramming),
  cargaTaller :         new Views("workload",           "Gestión de citas",           false, vr.NOTprogramming),
  incidencias :         new Views("incidencias",        "Incidencias",                false, vr.gestorasesor),
  configuration :       new Views("configuration",      "Editar información empresa", false, [UserRoleEnum.gestor]),
  pricing :             new Views("pricing",            "Precios",                    false, [UserRoleEnum.gestor]),
  users :               new Views("users",              "Usuarios",                   false, [UserRoleEnum.gestor]),

  /** Future features */
  stock :               new Views("stock",              "Gestión de anuncios",        false, vr.gestorasesor),
  editstock :           new Views("editstock",          "Editar anuncio",             false, vr.gestorasesor),
  workOr :              new Views("workor",             "Trabajar en OR",             false, f.workloadOr ?    [] : [UserRoleEnum.noOne]),
  comingsoon :          new Views("comingsoon",         "Próximamente",               false, f.comingsoon?     [] : [UserRoleEnum.noOne]),

  //registrationSearch :  new Views("registrationsearch", "Buscador de Matrícula",    false, vr.gestorasesor),

}

const routes: Routes = [
  
  { path: ViewPath.login.path,              component: LoginComponent}, //Cliente
  { path: ViewPath.pedirCita.path,          component: CreateCitaClienteComponent}, //Cliente
  { path: ViewPath.editarCita.path,         component: EditarCitaClienteComponent}, //Cliente
  { path: ViewPath.changePassword.path,     component: ChangePasswordComponent}, //Cliente

  { path: ViewPath.dashboard.path,          component: DashboardComponent,            canActivate:[AuthGuardGuard]},
  { path: ViewPath.recepcion.path,          component: HistorialRecepcionesComponent, canActivate:[AuthGuardGuard]},
  { path: ViewPath.clients.path,            component: ClientsComponent,              canActivate:[AuthGuardGuard]},
  { path: ViewPath.profile.path,            component: ProfileComponent,              canActivate:[AuthGuardGuard]}, 
  //{ path: ViewPath.registrationSearch.path, component: BuscdorMatriculaComponent,     canActivate:[AuthGuardGuard]},
  { path: ViewPath.crearRecepcion.path,     component: CrearRecepcionComponent,       canActivate:[AuthGuardGuard]},
  { path: ViewPath.crearCita.path,          component: CrearCitaComponent,            canActivate:[AuthGuardGuard]},
  { path: ViewPath.configuration.path,      component: ConfigurationComponent,        canActivate:[AuthGuardGuard]},
  { path: ViewPath.pricing.path,            component: PricingComponent,              canActivate:[AuthGuardGuard]},
  { path: ViewPath.users.path,              component: UsersComponent,                canActivate:[AuthGuardGuard]},
  { path: ViewPath.stock.path,              component: LanzaderaComponent,            canActivate:[AuthGuardGuard]},
  { path: ViewPath.editstock.path,          component: EditAddComponent,              canActivate:[AuthGuardGuard]},
  { path: ViewPath.listacitas.path,         component: ListaCitasComponent,           canActivate:[AuthGuardGuard]},
  { path: ViewPath.cargaTaller.path,        component: CargaTallerViewComponent,      canActivate:[AuthGuardGuard]},
  { path: ViewPath.workOr.path,             component: ImputarTiempoOrComponent,      canActivate:[AuthGuardGuard], canDeactivate: [DeactivateGuard]},
  { path: ViewPath.incidencias.path,        component: CloudTicketsComponent,         canActivate:[AuthGuardGuard]},
  { path: ViewPath.comingsoon.path,         component: ComingSoonComponent,           canActivate:[AuthGuardGuard]},
  { path: ViewPath.pageNotFound.path,       component: PageNotFoundComponent}, //Error 404
  { path: '**', pathMatch: 'full',          component: PageNotFoundComponent}, //Error 404

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
