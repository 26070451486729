import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { apiService } from 'src/app/core/api/api-service';
import { M_Provider } from 'src/app/core/models/M_Provider';

@Component({
  selector: 'app-provider-assign-dialog',
  templateUrl: './provider-assign-dialog.component.html',
  styleUrls: ['./provider-assign-dialog.component.css']
})
export class ProviderAssignDialogComponent {
  constructor(public dialogRef: MatDialogRef<ProviderAssignDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) public provider: M_Provider, private apiS : apiService) { }

  updateProvider() {
    this.apiS.updateProvider(this.provider).then(_res => {
      this.dialogRef.close(true);
    })
  }
}
