import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ConfirmData, ConfirmDialogService, FormService, ResponsiveService, RoleService, or_types_cargo_interno, or_types_garantia, or_types_mantenimiento, or_types_normal, or_types_siniestro } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { M_OR_Group } from 'src/app/core/models/M_OR_Group';
import { ImputarTiempoOrComponent } from 'src/app/views/imputar-tiempo-or/imputar-tiempo-or.component';

@Component({
  selector: 'app-or-line',
  templateUrl: './or-line.component.html',
  styleUrls: ['./or-line.component.css']
})
export class OrLineComponent implements OnInit {


  maxDenoLength = 30;

  @Output() ondelete: EventEmitter<M_OR_Group> = new EventEmitter<M_OR_Group>();
  @Input() g!: M_OR_Group;
  @Input() canEditOr: boolean = false;

  t_n = or_types_normal;
  t_ci = or_types_cargo_interno;
  t_g = or_types_garantia;
  t_s = or_types_siniestro;
  t_m = or_types_mantenimiento;


  deno: FormControl<number> = new FormControl();
  quantity: FormControl<number> = new FormControl();
  pvp: FormControl<number> = new FormControl();
  discount: FormControl<number> = new FormControl();

  constructor(@Inject(ImputarTiempoOrComponent) public parent: ImputarTiempoOrComponent, private apiS: apiService,
    private formS: FormService, private confirmS: ConfirmDialogService, public responsiveS: ResponsiveService, private roleS: RoleService) {
    this.deno.setValidators([Validators.maxLength(this.maxDenoLength)])
    this.quantity.setValidators([Validators.max(99999), Validators.min(1)])
    this.pvp.setValidators([Validators.max(99999), Validators.min(1)])
    this.discount.setValidators([Validators.max(100), Validators.min(0)])
  }

  ngOnInit(): void {
    switch (this.g.Grupo) {
      case "A":
      case "M":
      case "X":
        this.quantity.addValidators(Validators.required);
        this.quantity.addValidators(this.stockValidator())
        this.quantity.markAllAsTouched();
        this.pvp.addValidators(Validators.required);
        this.pvp.markAllAsTouched();
        break;
      case "C":
        this.deno.addValidators(Validators.required);
        this.deno.markAllAsTouched();
        break;
    }

    /** Important on add an Articule, update the other inputs stock */
    this.parent.updateValidityOfAllInputs();
  }

  stockValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.g.isArticle) {
        console.log(this.articleStock)
        return !this.enoughStock? { stock: true } : null;
      }
      return null;
    }
  }


  save() {
    this.apiS.addLine(this.g).then(res => {
      this.g.detalle_id = res.id;
      this.g.changes = false;
    });
  }

  /** The article has enough stcok?*/
  get enoughStock(){
    if (!this.g.isArticle) { return true}
    if (this.g.isArticle && !this.stockControl) { return true};
    return this.g.isArticle && this.articleStock >= this.articleMinimum;
  }

  /** Only Cloud lines !! */
  saveExtraInfo() {
    switch (this.g.Grupo) {
      /** Article, manual, trabajo */
      case "A":
      case "M":
      case "X":
        if (!this.enoughStock) {
          return "*Se supera el stock minimo (" + this.articleMinimum + ")";
        }
        var validForm = this.quantity.valid && this.pvp.valid && this.discount.valid;
        if (!this.g.created && !validForm) {
          return "*Faltan campos obligatorios"
        }
        else if (!validForm) {
          return "Detalle con valores incorrectos"
        }
        return "";
      case "C":
        if (!this.g.created) {
          return this.deno.value ? "" : "*Rellene el comentario"
        }
        else {
          return this.deno.value ? "" : "*El comentario es obligatorio"
        }
      default:
        return "";
    }
  }

  /** Only Cloud lines !! */
  saveEnabled() {
    switch (this.g.Grupo) {
      /** Article, manual, trabajo */
      case "A":
      case "M":
      case "X":
        return this.quantity.valid && this.pvp.valid && this.discount.valid && this.g.changes;
      case "C":
        return this.deno.valid && this.g.changes;
      default:
        return this.g.changes;
    }
  }

  get isGestorOrAsesor() {
    var r = this.roleS.getRole();
    return r == UserRoleEnum.gestor || r == UserRoleEnum.asesor;
  }

  get canChangeInput() {
    return this.canEditOr && this.isGestorOrAsesor;
  }

  get canChangeGroup() {
    return this.isGestorOrAsesor && this.canEditOr;
  }

  get articleStock() {
    return this.parent.getStockOf(this.g.article_id)
  }

  get articleMinimum() {
    return this.parent.getMinimumStock(this.g.article_id);
  }

  get stockControl() {
    console.log(this.parent.getStockControl(this.g.article_id))
    return this.parent.getStockControl(this.g.article_id);
  }

  get canDeleteLine() {
    return this.canEditOr && this.isGestorOrAsesor && !this.g.isTempario
  }

  showAlertIfNecessary(input: HTMLInputElement) {
    if (!this.enoughStock) {
      input.blur();
      var data: ConfirmData = {
        title: "¡Atención!",
        body: "El stock introducido supera el stock mínimo",
        confirmTxt: "OK",
        showCancel: false,
        type: 'warn'
      }
      this.confirmS.show(data);
    }
  }

}
