<video playsinline loop autoplay [muted]="true" id="loginVideo">
  <source src="../../../assets/video/login_back.mp4" type="video/mp4">
</video>

<div id="panelLoginVideo"></div>

<div class="container">
  <mat-card class="login-card">
    <img class="mtb-20 sinigual-logo" src="../../assets/img/logo.png">
    <mat-stepper (selectionChange)="stepChanged(stepper)">
      <!--Login-->
      <mat-step>
        <div class="mb-20">
          <p class="large semi-bold">Iniciar sesión</p>
          <p class="medium">Introduce tus credenciales para iniciar sesión</p>
        </div>
        <mat-card-content>
          <form [formGroup]="loginForm" (ngSubmit)="makeCall()">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Código de cliente</mat-label>
              <input matInput placeholder="Código de cliente" formControlName="client_code">
              <mat-icon [mat-menu-trigger-for]="clientcode" class="cp" style="color: var(--blue)" matSuffix>info
              </mat-icon>
              <mat-menu #clientcode="matMenu">
                <div class="p10">
                  <p class="nmb">El código de cliente, lo puedes encontrar en la siguiente ubicación dentro de la aplicación: </p>
                </div>
                <img style="width: 100%" src="./assets/img/siniwin-screenshots/clientnumber.png">
              </mat-menu>
            </mat-form-field>
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Código empresa</mat-label>
              <input matInput placeholder="Código numerico" type="string" formControlName="company_code">
              <mat-icon [matMenuTriggerFor]="companycode" class="cp" style="color: var(--blue)" matSuffix>info
              </mat-icon>
              <mat-menu #companycode="matMenu">
                <div class="p10">
                  <p class="nmb">El código de empresa, lo puedes encontrar en la siguiente ubicación dentro de la aplicación: </p>
                </div>
                <img style="width: 100%" src="./assets/img/siniwin-screenshots/companynumber.png">
              </mat-menu>
            </mat-form-field>
            <app-email-input class_='input-full-width' [form]="loginForm" formCName="email"></app-email-input>
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input #passwRef type="password" matInput placeholder="Contraseña" formControlName="password">
              <button type="button" (click)="passwRef.type=='text'? passwRef.type='password' : passwRef.type='text'"
                mat-icon-button matSuffix>
                <mat-icon *ngIf="passwRef.type == 'password'">visibility</mat-icon>
                <mat-icon *ngIf="passwRef.type == 'text'">visibility_off</mat-icon>
              </button>
            </mat-form-field>
            <p (click)="goForgetPassword()" class="mt-20 textButton" style="margin-bottom: 0px;">¿Has olvidado la
              contraseña?</p>
            <button class=" login-button appButton">ENTRAR</button>
          </form>
        </mat-card-content>
      </mat-step>

      <!--Recuperacion de password-->
      <mat-step>
        <button style="display: flex;" mat-icon-button (click)="goLogin()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="mb-20">
          <p class="large semi-bold">Recuperar contraseña</p>
          <p class="medium">A continuación, introduce tu correo electrónico</p>
        </div>
        <mat-card-content>
          <form [formGroup]="forgotPassword" (ngSubmit)="sendRecoverPassword()">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Código de cliente</mat-label>
              <input matInput placeholder="Código de cliente" formControlName="client_code">
            </mat-form-field>
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Código empresa</mat-label>
              <input matInput placeholder="Código numerico" type="string" formControlName="company_code">
            </mat-form-field>
            <app-email-input class_='input-full-width' [form]="forgotPassword" formCName="email"></app-email-input>
            <button type="submit" class="login-button" mat-raised-button>RECUPERAR CONTRASEÑA</button>
          </form>
        </mat-card-content>
      </mat-step>
    </mat-stepper>
  </mat-card>
</div>