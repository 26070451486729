import { ORGroupInterface, or_types, or_types_cargo_interno, or_types_garantia, or_types_mantenimiento, or_types_normal, or_types_siniestro, or_types_todefine } from "@sinigual/angular-lib";
import { M_Article } from "./M_Article";

/** T, E, L, I, O = "TEMPARIOS" */
export type GROUP_TYPE = 'A' | 'C' | 'M' | 'X' | 'T' | 'E' | 'L' | 'I' | 'O'

export class M_OR_Group implements ORGroupInterface {

    detalle_id: number;
    article_id?: number;
    Grupo?: GROUP_TYPE;
    CodRef: string;
    DenoRef1p: string | undefined;
    Cantidad: number;
    Pvp: number;
    Dto: number;
    Importe: number;
    TiLinea: or_types | undefined; // ??
    deleted = false;
    siniwinLine = false;
    state = undefined;
    ActualizacionSga = 'N';
    SrdEfectivo2 = 'N';
    changes = false;

    or_id: number;
    or_type: or_types | undefined;

    constructor(d: any) {
        this.or_id = d.or_id;
        this.detalle_id = d.id ? d.id : d.detalle_id;
        this.Grupo = d.Grupo;
        this.CodRef = d.CodRef;
        this.DenoRef1p = d.DenoRef1p;
        this.Cantidad = d.Cantidad;
        this.Pvp = d.Pvp;
        this.Dto = d.Dto;
        this.Importe = d.Importe;
        this.TiLinea = this.getTiLinea(d.TiLinea);
        this.siniwinLine = d.GrupoConta != undefined;
        //this.siniwinLine = true;
        this.deleted = d.IndControl == "1" ? true : false;
        this.article_id = d.article_id;
    }

    getTiLinea(d: any) {
        if (typeof d == "string") {
            if (d == "N") {
                return or_types_normal;
            }
            if (d == "G") {
                return or_types_garantia;
            }
            if (d == "C") {
                return or_types_cargo_interno;
            }
            if (d == "S") {
                return or_types_siniestro;
            }
            if (d == "M") {
                return or_types_mantenimiento;
            }
        }

        return or_types_todefine;
    }

    /** For core */
    get type() {
        return this.TiLinea
    }

    get created (){
        return this.detalle_id != undefined;
    }

    getTitle(): string {
        return this.DenoRef1p ? this.DenoRef1p : "";
    }

    addArticle(a: M_Article) {
        this.CodRef = a.Ref;
        this.DenoRef1p = a.Denominacion1p;
        this.Cantidad = 1;
        this.Pvp = a.Pvp;
        this.Dto = a.DtoVta ? a.DtoVta : 0;
        this.article_id = a.id;
    }

    get icon() {
        switch (this.Grupo) {
            case "A":
                return "inventory_2"
            case "X":
                return "home_repair_service"
            case "M":
                return "widgets"
            case "C":
                return "chat"
            case "T":
            case "E":
            case "L":
            case "I":
            case "O":
                return "timer"
            default:
                return "inventory_2"
        }
    }

    get total() {
        if (this.Cantidad == undefined || this.Pvp == undefined) {
            return 0;
        }
        let t1 = this.Cantidad * this.Pvp;
        if (this.Dto) {
            let disc = t1 * (this.Dto / 100);
            t1 = t1 - disc;
        }
        return t1;
    }

    get title() {
        switch (this.Grupo) {
            case "A":
                return "Artículo";
            case "X":
                return "Trabajos"
            case "M":
                return "Manual"
            case "C":
                return "Comentario"
            case "T":
            case "E":
            case "L":
            case "I":
            case "O":
                return "Tempario"
            default:
                return "Artículo"
        }
    }

    isOrTypeLabelEnabled(t: or_types) {
        if (this.or_type == undefined || this.or_type.normal || this.or_type.todefine) {
            return true;
        }
        else if (this.or_type.siniestro) {
            return t.siniestro;
        }
        else if (this.or_type.cargo) {
            return t.cargo;
        }
        else if (this.or_type.garantia) {
            return t.garantia;
        }
        else if (this.or_type.mantenimiento) {
            return t.mantenimiento;
        }
        return false;
    }

    get isArticle() {
        return this.Grupo && this.Grupo == "A";
    }

    get isComment() {
        return this.Grupo && this.Grupo == "C";
    }

    get isManual() {
        return this.Grupo && this.Grupo == "M";
    }

    get isTrabajo() {
        return this.Grupo && this.Grupo == "X";
    }

    get isTempario() {
        return this.Grupo && this.Grupo == "T";
    }

    /** Show the input value ? */
    get showable() {
        return this.Grupo != undefined && !this.siniwinLine;
    }

    get showCodeRef() {
        return false;
    }

    get showDenomination() {
        return (this.isArticle || this.isComment || this.isManual || this.isTrabajo || this.siniwinLine);
    }

    get showQuantity() {
        return !this.isComment;
    }

    get showPvp() {
        return !this.isComment;
    }

    get showDto() {
        return !this.isComment;
    }

    get showImport() {
        return !this.isComment;
    }

    get showImporte() {
        return this.Grupo == "A" || this.Grupo == "X" || this.Grupo == "M" || this.siniwinLine;
    }
}