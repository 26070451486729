import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentStatus, CustomDate, ErrorDialogService, RoleService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { CustomDateByGranularity, DateByGranularity } from 'src/app/core/custom-classes/CustomDateByGranularity';
import { DateGranularity } from 'src/app/core/enums/DateGranulraity';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { CompanyCalendarEeComponent } from 'src/app/views/edit-company-info/company-calendar-ee/company-calendar-ee.component';

@Component({
  selector: 'app-holidays-calendar',
  templateUrl: './holidays-calendar.component.html',
  styleUrls: ['./holidays-calendar.component.css']
})
export class HolidaysCalendarComponent implements OnInit {

  @ViewChild('datePicker') caledar!: MatCalendar<Date>;
  /** Array of role numbers that can edit the calendar */
  @Input() enabledFor: number[] = [];
  /** Show a save button on the bottom of the calendar. By default false */
  @Input() selfSave: boolean = false;
  /** Prespective of the calendar */
  @Input() prespective: "company" | "user" = "company";
  /** Show legend */
  @Input() showLegend: boolean = true;

  /** ---------- MAIN ARRAYS ---------- */
  /** Holidays to show on the calendar */
  companyHolidays: CustomDate[] = [];
  /** User holidays */
  userHolidays: CustomDate[] = [];

  /** The id of the user on which the holidays will be set */
  userID: number | undefined = undefined;
  /** Is the calendar enbled for the current user? */
  enabled: boolean = false;
  /** Bottom contents of the caledar. Shows all months that have holidays with the respective number of days */
  holidayBreakdown: DateByGranularity[] = [];
  /** If ther is some changes on calendar, show unsaved changes */
  holidayChanges = false;
  /** Current user role */
  userRole: number;
  /** Is calendar loading ? */
  loading = true;

  constructor(private d: MatDialog, private apiService: apiService, private errorD: ErrorDialogService, roleS: RoleService) {
    this.userRole = roleS.getRole();
  }

  ngOnInit(): void {
    this.enabled = this.enabledFor.some(r => r == this.userRole);
  }

  get prespectiveArrray() {
    return this.prespective == "company" ? this.companyHolidays : this.userHolidays;
  }

  get isCompanyPrespective() {
    return this.prespective == "company";
  }

  get isUserPrespective() {
    return this.prespective == "user";
  }

  /** Set the holiday dates to the caledar. */
  initComponent(companyH: CustomDate[], userH: CustomDate[], userID?: number) {
    this.companyHolidays = companyH;
    this.userHolidays = userH;
    this.userID = userID;
    this.caledar.updateTodaysDate()
    this.holidayBreakdown = new CustomDateByGranularity(this.prespectiveArrray, DateGranularity.MONTH).getData();
    this.loading = false;
    console.log(this.companyHolidays)
    console.log(this.userHolidays)
  }

  /** Determines if a day is selected or not. */
  dateClass = (d: Date) => {
    let date_ = new CustomDate(d);

    let finalClass = "";
    var company = "companyDay ";
    var miniCompany = "mini-companyDay ";
    var user = "userDay ";

    if (this.isCompanyPrespective) {
      for (let i = 0; i < this.companyHolidays.length; i++) {
        if (this.companyHolidays[i].isEquals(date_)) {
          finalClass += company
          break;
        }
      }
    }
    else {
      for (let i = 0; i < this.userHolidays.length; i++) {
        if (this.userHolidays[i].isEquals(date_)) {
          finalClass += user;
          break;
        }
      }
      for (let i = 0; i < this.companyHolidays.length; i++) {
        if (this.companyHolidays[i].isEquals(date_)) {
          finalClass += miniCompany;
          break;
        }
      }
    }
    console.log(finalClass)
    return finalClass;
  }

  /** It is used to go to a specific day on the calendar. 
   * It is useful to go to the months that have been established as holidays 
  **/
  goToMonth(d: CustomDate) {
    this.caledar._goToDateInView(d.value, 'month')
  }

  /** Refresh the caledar to re-paint the holiday dates with its respective color */
  refreshCalendar() {
    this.caledar.updateTodaysDate()
    this.holidayBreakdown = new CustomDateByGranularity(this.companyHolidays, DateGranularity.MONTH).getData();
  }

  /** On click a day on the caledar */
  public dateChanged(event: any) {
    if (!this.enabled || this.loading) { return }
    if (event) {

      let dayClicked = new CustomDate(event);
      if (dayClicked.getYear(true) == "1989") {
        this.d.open(CompanyCalendarEeComponent);
      }

      else {

        /** Set a new holiday */
        if (!this.prespectiveArrray.find(d => d.isEquals(dayClicked))) { //Si el dia no esta ya en la array
          this.apiService.getCitaByDay(dayClicked).then(res => {
            if (this.dayWithAppointment(res)) {
              this.errorD.showError("No se pudo establecer el día festivo", "Hay una cita pendiente el día " + dayClicked.humanFormat)
            }
            else {
              this.prespectiveArrray.push(dayClicked);
              this.refreshCalendar();
              this.holidayChanges = true;
            }
          })
        }

        /** Remove existing holiday */
        else {
          const index = this.prespectiveArrray.indexOf(this.prespectiveArrray.find(d => d.isEquals(new CustomDate(event)))!, 0);
          if (index > -1) {
            this.prespectiveArrray.splice(index, 1);
            this.holidayChanges = true;
          }
        }
        this.refreshCalendar()
      }
    }
  }

  /** Check if some appointment of array is on "pending" status*/
  dayWithAppointment(citas: M_Cita[]): boolean {
    for (let i = 0; i < citas.length; i++) {
      if (citas[i].estado_cita == AppointmentStatus.pendiente) {
        return true;
      }
    }
    return false;
  }

  /** Triggered when click the self save button. The 'userID'is needed to save. */
  save() {
    if (this.userID) {
      this.apiService.saveHolidaysByUserId(this.userID)
      this.holidayChanges = false;
    }
  }

}
