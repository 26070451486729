import { apiService } from "../../core/api/api-service";

export interface M_LoginResponseInterface{
    access_token : string;
}

/** [Respuesta a una petición GET]
 *  @description Respuesta al hacer la petición de login
 *  {@link apiService.doLogin()}
 */
export class M_LoginResponse implements M_LoginResponseInterface{
    access_token: string;
    role : number;
    constructor(obj : any){
        this.access_token = obj.access_token;
        this.role = obj.role;
    }
}