import { ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService, CustomFile, FileUploadComponent, RoleService, RouterService, toFormData } from '@sinigual/angular-lib';
import { HolidaysCalendarComponent } from 'src/app/components/holidays-calendar/holidays-calendar.component';
import { calendarEnabled } from 'src/app/constants/constants';
import { apiService } from 'src/app/core/api/api-service';
import { UserRole } from 'src/app/core/custom-classes/UserRole';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_ProfilePicture } from 'src/app/core/models/M_ProfilePicture';
import { ProfileService } from 'src/app/views/profile/profileService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  role = UserRoleEnum;
  @ViewChild(FileUploadComponent) imageUploadC!: FileUploadComponent;
  @ViewChild(HolidaysCalendarComponent) holidayC!: HolidaysCalendarComponent;
  env = environment;
  public form: UntypedFormGroup;
  enabledCalender = calendarEnabled;
  f = feature;

  constructor(public routerS: RouterService, private apiService: apiService, public profileService: ProfileService,
    private chdRef: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, private dialog: MatDialog,
    public roleS: RoleService, private confirmDialogS: ConfirmDialogService) {
    this.form = this.formBuilder.group({
      pass1: [''],
      pass2: ['',]
    });

    this.profileService.subject.subscribe(user => {
      if (user.img && this.imageUploadC) {
        this.imageUploadC.setImageFromDatabase(user.img);
      }

      if (this.holidayC){
        this.holidayC.initComponent(user.company_holidays, user.user_holidays, user.id);
      }
    })
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
  }

  ngAfterContentInit() {
    if (this.profileService.clientData && this.imageUploadC) {
      if (this.profileService.clientData.img) {
        this.imageUploadC.setImageFromDatabase(this.profileService.clientData.img)
      }
    }
    if (this.profileService.clientData && this.holidayC) {
      this.holidayC.initComponent(this.profileService.clientData.company_holidays, this.profileService.clientData.user_holidays, this.profileService.clientData.id);
    }
  }

  onUploadImage() {
    this.profileService.refresh();
  }

  getFormData(f: CustomFile) {
    console.log(f)
    return toFormData(new M_ProfilePicture(f.file!))
  }

  onStateChange(state: CustomFile) {
    console.log(this.getFormData(state))
    this.apiService.changeProfilePicture(this.getFormData(state)).then(_response => this.onUploadImage());
  }

  changePassword() {
    this.apiService.changePassword(this.profileService.clientData!.hash, this.form.get('pass1')?.value).then(resp => {
      if (resp) {
        this.confirmDialogS.show({
          title: "CONTRASEÑA MODIFICADA CON ÉXITO",
          confirmTxt: "OK",
          showCancel: false,
          body: "Su contraseña ha sido modificada",
        }).afterClosed().subscribe(res => {
          this.routerS.refresh()
        })
      }
    })
  }

  getRole(): string {
    return new UserRole(this.roleS.getRole()).name
  }

}
