import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BurgerMultipleOption, BurgerOption, BurgerService } from '@sinigual/angular-lib';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { MenuComponent } from '../menu.component';

@Component({
  selector: 'app-menu-item-multiple',
  templateUrl: './menu-item-multiple.component.html',
  styleUrls: ['./menu-item-multiple.component.css']
})
export class MenuItemMultipleComponent implements OnInit {

  @Input() data! : BurgerMultipleOption;
  @Input() class_? : string;
  @Input() parent! : MenuComponent;
  @ViewChildren(MenuItemComponent) children! : QueryList<MenuItemComponent>;
  allChilds : BurgerOption[] = []
  show : boolean = false;
  showBurgerMultiple  = true;
  constructor(private chdRef : ChangeDetectorRef, public bS : BurgerService) { }

  ngOnInit(): void {}

  /**Una vez hemos obtenido los hijos, miramos sus permisos
   * En el caso de que ninguno de los hijos tenga una view a la que el usuario pueda ir, este componente no se muestra
   */
  ngAfterViewInit() {
   for(let i =0; i < this.children.length; i++){
     this.allChilds.push(this.children.get(i)!.data!)
   }
   //this.showBurgerMultiple = this.bS.childsHasPermission(this.allChilds);
   this.chdRef.detectChanges();
  }

  /**Desplegar el dropdown */
  changeShow() {
    this.show = !this.show;
  }

  getClass(){
    return this.bS.isSelectedMultiple(this.allChilds) ? 'burgerSelected' : '';
  }
}
