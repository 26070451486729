<mat-accordion class="padding20" #accordion="matAccordion">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-icon class="topleft" style="transform : scale(4) rotate(0deg)">publish</mat-icon>
            <mat-panel-title>
                Anuncio
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="form">
            <div class="df column wrap section">
                <!--Datos obligatorios-->
                <app-vo-section-title [ok]="addContentOk" [title]="'4 - Contenido del anuncio'" [text]="'Faltan campos requeridos para poder publicar el anuncio'"></app-vo-section-title>
                <div class="expansion-panel-content w100onphone">
                    <!--<div class="test"></div> !-->
                    <!--<div class="df wrap w100">
                        <mat-form-field appearance="outline">
                            <mat-label>Título</mat-label>
                            <input matInput required formControlName="titulo">
                        </mat-form-field>
                    </div> !-->
                    <div class="df wrap w100">
                        <app-simple-input #descinput [requerido]="false" [form]="form" formCName="descripcion" [textArea]="true"
                            [maxLength]="500" label="Descripción" [hint]="'* Publicación'">
                        </app-simple-input>
                    </div>
                    <app-row-two [firstWrap]="490">
                        <app-simple-input [form]="form" [requerido]="false" formCName="pvp" label="PVP Financiación" suffix="€/mes"
                            [dotSeparation]="true"></app-simple-input>
                        <mat-form-field appearance="outline">
                            <mat-label>Precio (€)</mat-label>
                            <input matInput formControlName="precio" class="separation">
                            <mat-hint>* Publicación</mat-hint>
                            <mat-slide-toggle matSuffix color="primary" #check>Oferta</mat-slide-toggle>
                        </mat-form-field>
                        <div [ngClass]="check.checked ? '' : 'oculto'">
                            <mat-form-field appearance="fill" class="oferta">
                                <mat-label>Oferta (€)</mat-label>
                                <input matInput formControlName="oferta" class="separation">
                            </mat-form-field>
                        </div>

                    </app-row-two>
                </div>
                <app-subir-fotografia></app-subir-fotografia>
                <app-vo-section-title [removeSpace]="true" [ok]="parent.publicationErrors.length == 0" [title]="'Publicación'" 
                [text]="'Falta rellenar las siguientes secciones para poder publicar el anuncio: '"></app-vo-section-title>
                <div *ngIf="parent.publicationErrors as errors">
                    <div *ngIf="errors.length != 0; else canPublicate">
                        <p class="info-text"></p>
                        <p *ngFor="let error of errors" class="section_error">● {{error}}</p>
                    </div>
                    <ng-template #canPublicate>
                        <p class="section_ok">¡Parece ser que todo está correcto!</p>
                    </ng-template>
                </div>

                <mat-slide-toggle #sd color="primary" formControlName="publicar" [disabled]="checkDisabledAndChecked()">
                    Publicar el anuncio  {{  parent.publicationErrors.length != 0? "(Faltan datos)" : "" }}
                </mat-slide-toggle>
                <span *ngIf="!hasProviders() && sd.checked" class="error_visible">Seleccione algún proveedor para poder publicar el anuncio</span>
                <div class="providers" [ngClass]="sd.checked ? '' : 'hidden'">
                    <app-image-toggle [dimensions]="100" [multiple]="true" [default]="false" [blackWitheFilter]="true">
                        <div *ngFor="let prov of parent.providers; let last=last">
                            <div class="tac" *ngIf="prov.url_publication">
                                <a [href]="prov.url_publication" target="_blank" class="link_u fss">Ver anuncio</a>
                            </div>
                            <app-img-toggle-item
                                [startSelected]="parent.bike!.add != undefined? parent.bike!.add.isProviderPreSelected(prov) : false"
                                [img]="prov.image ? prov.image : ''" [text]="prov.name"
                                [locked]="!prov.onCompany"></app-img-toggle-item>
                        </div>
                    </app-image-toggle>
                </div>
            </div>
        </form>
        <button mat-raised-button class="scb saveButton" (click)="onSave.emit()">GUARDAR</button>

    </mat-expansion-panel>
</mat-accordion>