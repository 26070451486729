import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { RouterService } from '@sinigual/angular-lib';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { EditCompanyInfoComponent } from '../edit-company-info/edit-company-info.component';
import { ProfileService } from '../profile/profileService';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html'
})
export class ConfigurationComponent implements OnInit {
  @ViewChild('tabs') tabs!: MatTabGroup;
  @ViewChild(EditCompanyInfoComponent) editCompanyComponent!: EditCompanyInfoComponent;
  f = feature;
  target = -1;
  constructor(private routerS: RouterService, private profileS : ProfileService) {
    if (this.routerS.hasState()) {
      let state = this.routerS.getState();
      this.target = state;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.changeTab();
  }


  goProvidersTab() {
    this.target = 1;
    this.changeTab();
  }

  goCompanyTab(highlightGeneralInfo: boolean) {
    if (highlightGeneralInfo) {
      this.editCompanyComponent.needsHighlight = true;
    }
    this.target = 0;
    this.changeTab();
  }

  changeTab() {
    if (this.target != -1) {
      this.tabs.selectedIndex = this.target;
    }
  }

  detectChange(tab: MatTabChangeEvent) {
    switch (tab.index) {
      case 1:
    }
  }


}
