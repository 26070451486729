<div class="df aic fdc">
    <mat-progress-bar *ngIf="loading" class="all-c-width" [mode]="'indeterminate'"></mat-progress-bar>

    <!-- MAIN CALENDAR-->
    <mat-calendar class="all-c-width" [ngClass]="{'calendar-disabled' : !enabled || loading}" #datePicker [dateClass]="dateClass"
        (selectedChange)="dateChanged($event)"></mat-calendar>

    <!-- BOTTOM CALENDAR CONTENTS-->
    <div class="bottom-contents all-c-width">
        <div class="df fdc aic w100">

            <!-- LEGEND -->
            <div class="legend" *ngIf="showLegend">
                <p><mat-icon class="c_p">fiber_manual_record</mat-icon> Tus festivos </p>
                <p><mat-icon class="c_r">fiber_manual_record</mat-icon> Festivo empresa </p>
            </div>

            <!-- UNSAVED CHANGES-->
            <span *ngIf="holidayChanges" class="fss c_r fw500 mt5">Cambios sin guardar</span>

            <div class="df">
                <!-- HOLIDAYS BREAKDOWN-->
                <div *ngFor="let dayByGranularity of holidayBreakdown">
                    <div class="p10 tac">
                        <!-- Ex : Enero (2023)-->
                        <div (click)="goToMonth(dayByGranularity.splitDate)" class="bold textButton">
                            {{dayByGranularity.splitDate.getMonthName(true)}}
                            <span class="breakdown-month">{{"("+dayByGranularity.splitDate.getYear(true) + ")"}}</span>
                        </div>
                        <!-- Ex : 2 días festivos-->
                        <div>
                            <span class="semi-bold sc">{{dayByGranularity.data.length}}</span>
                            <span>{{dayByGranularity.data.length >= 2? ' días festivos' : ' día festivo'}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SELF SAVE BUTTON -->
            <button *ngIf="selfSave && enabled" mat-flat-button [disabled]="!holidayChanges" class="save-button" color="primary" (click)="save()">
                GUARDAR
            </button>

        </div>
    </div>
</div>