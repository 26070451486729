
<app-close-dialog-button></app-close-dialog-button>

<div>
    <app-card-title class="tac">
        {{ (!provider.onCompany ? 'Obtener ' : 'Deshabilitar ') + provider.name }}
    </app-card-title>
    <app-card-subtitle>
        {{!provider.onCompany ?
        '¿Esta seguro de que quiere obtener ' :
        '¿Esta seguro de que desea dejar de utilzar '
        }}
        <span class="c_p fw500">{{provider.name}}</span>?
    </app-card-subtitle>
    <div class="df jcc">
        <img [src]="provider.image" width="100" height="100" class="fit">
    </div>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Cancelar</button>
        <button mat-button (click)="updateProvider()">{{!provider.onCompany ? '¡Obtener!' : 'Deshabilitar'}}</button>
    </div>
</div>