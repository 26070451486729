import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BurgerOption, BurgerService, ResponsiveService } from '@sinigual/angular-lib';
import { MenuComponent } from '../menu.component';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  v = ViewPath;
  @ViewChild('item') item!: ElementRef<HTMLDivElement>;
  @Input() data!: BurgerOption;
  @Input() mc!: MenuComponent;
  @Input() matmenu: boolean = false;
  @Input() lordIcon: boolean = false;
  @Input() class_: string | undefined;
  @Input() badge: string | undefined;
  @Input() icon: { icon: string, color: string } | undefined = undefined;
  constructor(public bS: BurgerService, private responsive: ResponsiveService) { }
  ngOnInit(): void { }
  animation() {
    this.item.nativeElement.classList.toggle('selected')
  }

  get isShowing() {
    if (this.data){
      return this.bS.havePermisisons(this.data)
    }
    return false;
  }

  dataCalssPhoneDesktop() {
    var isPhone = this.responsive.isPhone();
    if ((this.mc.isDesktop && isPhone) || (!this.mc.isDesktop && !isPhone)) {
      return "";
    }

    return this.data.class_;
  }
}
