import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { TimeService } from 'src/app/core/services/time.service';
import { HourPickerComponent } from 'src/app/components/hour-picker/hour-picker.component';

/**
 * [Component]
 * Diálogo para modificar fecha y hora de una cita.
 * Le llega por parámetro la cita que se quiere modificar.
 * Utiliza el componente 'hour-picker' para el formateo de la fecha
 */
@Component({
  selector: 'app-mecanico-modificar-hora',
  templateUrl: './mecanico-modificar-hora.component.html'
})

export class MecanicoModificarHoraComponent implements OnInit {
  @ViewChild(HourPickerComponent) hourpciker : HourPickerComponent | undefined;
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<MecanicoModificarHoraComponent>, @Inject(MAT_DIALOG_DATA) public cita: M_Cita,
              public timeService : TimeService, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      hora :  ['', [Validators.required]], //La hora y el dia, se juntan en una string al pasarla al servidor
      dia: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  /**Establece la fecha y la hora del componente 'hour-picker' */
  ngAfterViewInit(){
    this.hourpciker?.setDay(this.cita.fecha_cita, undefined); // No hace falta passar el company_id
    this.hourpciker?.setHour(this.cita.fecha_cita);
  }

}
