<!--  ***********  -->
<div [ngClass]="!responsive.isPhone() ? 'entry-point': 'entry-point-phone'">
    <div *ngIf="displayToolbar; else router">
        <app-onboarding #ob [userOnboarding]="onboarding" [showProgress]="false">
            <mat-drawer-container [hasBackdrop]="true" (backdropClick)="menu.close()">
            <mat-drawer #drawer [mode]="'over'">
                <div class="df jcc mt20 mb10">
                    <img class="logoMenu" src="./assets/img/sinigual.png">
                </div>
                <app-menu #phoneMenu [drawer]="drawer" [isDesktop]="false" [ngClass]="{'dn' : !responsive.isPhone(),
                                          'menu-shadow' : menu.opened && !responsive.isPhone()}">
                </app-menu>
            </mat-drawer>
            <mat-drawer-content [style]="'overflow: unset'">
                <app-toolbar [onboarding]="ob" [mc]="menu" (onclick)="toggleMenu(menu, phoneMenu)"></app-toolbar>
                <div [ngClass]="{'entry-point-contents' : !responsive.isPhone()}">
                    <app-menu #menu [ngClass]="{'menuDesktop' : !responsive.isPhone(),
                                              'dn' : responsive.isPhone(),
                                              'menu-shadow' : menu.opened && !menu.lockmenu,
                                              'fixed' : menu.lockmenu && !responsive.isPhone()}"
                        [isDesktop]="true">
                    </app-menu>
                    <app-app-contents
                        [ngStyle]="{'margin-left' : menu.lockmenu && !responsive.isPhone() ? '10px' : !responsive.isPhone() ? '60px' : '0px'}">
                        <div [ngTemplateOutlet]="router"></div>
                    </app-app-contents>
                </div>
            </mat-drawer-content>
            </mat-drawer-container>
        </app-onboarding>
    </div>
</div>

<ng-template #router>
    <router-outlet></router-outlet>
</ng-template>

<app-developer-helper #dh>
    <mat-tab-group>
        <mat-tab label="ROLE">
            <ng-template matTabContent>
                <button (click)="setRole(ure.gestor)">Gestor</button>
                <button (click)="setRole(ure.asesor)">Asesor</button>
                <button (click)="setRole(ure.mecanico)">Mecánico</button>
                <button (click)="setRole(ure.programming)">Programación</button>
            </ng-template>
        </mat-tab>
        <mat-tab label="LOCAL STORAGE">
            <ng-template matTabContent>
                <div *ngFor="let kv of items">
                    <p class="fw600 nmb">{{kv[0]}}</p>
                    <p>{{kv[1]}}</p>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="ENDPOINTS">
            <lib-endpoint-viewer [er]="dh.endpointResponses"></lib-endpoint-viewer>
        </mat-tab>
    </mat-tab-group>
</app-developer-helper>

<app-entry-point-listener></app-entry-point-listener>